import React from "react";

import generic from "../../../model/generic";

import SubscriptionSelector from "./SubscriptionSelector";
import SubscriptionMediaSelector from "./SubscriptionMediaSelector";


export default class extends React.Component {

    state = {selected: null};

    componentDidMount = () => {
        generic.addEntityListener('subscription', this.refresh);
    };

    onSelect = (id) => {
        this.setState({selected: id});
    };

    refresh = () => {
        this.forceUpdate();
    };

    render() {
        return <div id="manager-subscription-view" className={"row" + (this.props.show ? '' : ' hide')} >
            <div className="col s8" style={{ borderRight: '2px solid white' }}>
                <SubscriptionMediaSelector selected={this.state.selected} />
            </div>
            <div className="col s4" style={{ borderLeft: '2px solid white', marginLeft: '-2px' }}>
                <SubscriptionSelector selected={this.state.selected} onSelect={this.onSelect} />
            </div>
        </div>
    }

}
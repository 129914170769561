import React from "react";
import moment from "moment";
import _ from 'lodash';

import config from "../../config";

import utils from "../../lib/utils";
import User from "../../model/user";
import generic from "../../model/generic";

import InputField from "../global/InputField";
import DataTable from "../global/DataTable";
import Button from "../global/Button";
import Title from "../global/Title";

import ReturnIconButton from "../common/ReturnIconButton";

import ProfilePicture from "./ProfilePicture";
import WalletBalanceDisplay from "./WalletBalanceDisplay";
import SubscriptionDisplay from "./SubscriptionDisplay";
import SubscriptionTokenDisplay from "./SubscriptionTokenDisplay";

export default class extends React.Component {

    state = {};

    refresh = () => {
        this.forceUpdate();
    }

    renderReturnButton = () => {
        return this.props.onReturn && <ReturnIconButton onClick={this.props.onReturn} />
    }

    render() {
        if (!User.isAuthenticated() || !this.props.show) {
            return null;
        }
        return <div className={"profile-panel white-text row"} style={{
            paddingBottom: config.MOBILE_DISPLAY ? '70px' : undefined
        }}>
            {this.renderReturnButton()}
            <div className="col l6 s12" style={{ height: "100%", borderRight: '1px solid white' }}>
                <AccountDisplay />
            </div>
            {this.props.showSubscriptions && <div className="col l6 s12">
                {User.hasAddress() && <WalletBalanceDisplay />}
                {User.isContentCreator() && <SubscriptionDisplay />}
                <SubscriptionTokenDisplay />
            </div>}
        </div>

    }

}

class AccountDisplay extends React.Component {

    state = {

    };

    refresh = () => {
        this.forceUpdate();
    }

    onUsernameEditClick = () => {
        let user = User.getUser();
        window.openGenericForm({
            title: 'Edit Username',
            inputs: [{
                name: 'username',
                value: user.username,
                control: {
                    type: 'string',
                    options: { min_length: 3, max_length: 20 }
                }
            }],
            on_submit: (data) => {
                User.updateUser(data, (err) => {
                    if (err) {
                        let msg = 'An error has occurred while updating your username.';
                        if (err.message && err.message.includes('username-already-used')) {
                            msg = 'This username is already used.';
                        }
                        window.alertPopup('Error', msg);
                    } else {
                        this.refresh();
                        window.toast('Account updated successfully.');
                    }
                });
            }
        });
    };

    onDescriptionEditClick = () => {
        let user = User.getUser();
        window.openGenericForm({
            title: 'Edit Bio',
            inputs: [{
                name: 'description',
                label: "bio",
                value: user.description,
                control: {
                    type: 'string',
                    options: { min_length: 0, max_length: 50 }
                }
            }],
            on_submit: (data) => {
                User.updateUser(data, (err) => {
                    if (err) {
                        let msg = 'An error has occurred while updating your bio.';
                        window.alertPopup('Error', msg);
                    } else {
                        this.refresh();
                        window.toast('Account updated successfully.');
                    }
                });
            }
        });
    };

    onEmailEditClick = () => {
        let user = User.getUser();
        window.openGenericForm({
            title: 'Edit Email',
            inputs: [{
                name: 'email',
                value: user.email,
                control: {
                    type: 'string',
                    options: { email: true, min_length: 3, max_length: 50 }
                }
            }],
            on_submit: (data) => {
                User.updateUser(data, (err) => {
                    if (err) {
                        let msg = 'An error has occurred while updating your email.';
                        if (err.message && err.message.includes('email-already-used')) {
                            msg = 'This e-mail is already used.';
                        }
                        window.alertPopup('Error', msg);
                    } else {
                        this.refresh();
                        window.toast('Account updated successfully.');
                    }
                });
            }
        });
    }

    onPasswordEditClick = () => {
        window.openUserPasswordForm({}, this.refresh);
    }

    onWalletAssociate = () => {
        window.openWalletAssociateModal({
            on_associate: () => {
                this.refresh();
                window.toast('Wallet associated successfully.');
            }
        });
    }

    onSubscriptionSetup = () => {
        window.openGenericForm({
            title: 'Subscription Price',
            inputs: [{
                name: 'price',
                label: "Price ($ / Month)",
                type: 'number',
                value: 5,
                control: {
                    type: 'number',
                    options: { min: 0, max: 10000, step: 0.01 }
                }
            }],
            on_submit: (data) => {
                generic.createEntity('subscription', {name: "Base", price: data.price, unit_time: 2592000}, (err) => {
                    if (err) {
                        window.alertPopup('Error', 'An error has occurred while creating your subscription.');
                    } else {
                        this.refresh();
                        window.toast('Subscription created successfully.');
                    }
                }, this.refresh);
            }
        });
    }

    onToggleContentCreator = () => {
        let action = User.isContentCreator() ? 'disable' : 'enable';
        window.openConfirmActionModal({
            action_text: "Do you wish to " + action + " content creator mode ?",
            confirm_cb: () => {
                let update = {
                    content_creator: !User.isContentCreator()
                };
                User.updateUser(update, (err) => {
                    if (err) {
                        window.alertPopup('Error', 'An error has occurred while updating your account.');
                    } else if (window.refreshApp) {
                        window.refreshApp();
                    } else {
                        this.refresh();
                    }
                    if (!err) {
                        window.toast('Account updated successfully.');
                        if(action === 'enable' && !User.getUserSubscription()){
                            window.alertPopup("Setup subscription", <div>
                                <Title text="Do you wish to set up your subscription plan now ?" size="5" />
                                <div className="center-align">
                                    <Button className="t18-btn" text="Setup Subscription" style={{fontSize: "24px"}} onClick={() => {
                                        window.closeAlertPopup();
                                        this.onSubscriptionSetup();
                                    }} />
                                </div>
                                <Title text="You can always set it up later in your profile options." size="5" />
                            </div>);
                        }
                    }
                });
            }
        });
    }

    onSignOut = () => {
        window.openConfirmActionModal({
            action_text: "Do you wish to sign out ?",
            confirm_cb: () => {
                User.signOut();
            }
        });
    }

    onCurrencyEditClick = () => {
        window.openUserCurrenciesModal({ submit_cb: this.refresh });
    };

    renderProfilePictureSection = (user) => {
        let mobile_display = config.MOBILE_DISPLAY;
        return <div className="center-align">
            <Title text={<span style={ User.isContentCreator() ? { position: 'relative', top: mobile_display ? "20px" : undefined } : {padding: '20px', display: 'inline-block'}}>
                {User.isContentCreator() && <ProfilePicture user_id={user.id} style={{ height: "100px", marginRight: "20px" }} selfEditAction={true} />}
                <span style={User.isContentCreator() ? { position: 'relative', bottom: mobile_display ? "60px" : '40px' } : undefined}>Account</span>
                <Button className="t18-btn lm" text="Sign Out" onClick={this.onSignOut} style={User.isContentCreator() && {
                    position: 'relative',
                    bottom: mobile_display ? '50px' : '40px',
                    left: mobile_display ? "60px" : undefined
                }} />
            </span>} size="4" style={mobile_display ? { margin: 0 } : {}} />
        </div>
    }

    render() {
        let user = User.getUser(),
            mobile_display = config.MOBILE_DISPLAY;
        return <div style={{
            padding: mobile_display ? "10px" : "40px 20px",
            paddingTop: mobile_display ? undefined : "10px",
            margin: mobile_display ? '10px 5px' : '20px',
            borderRadius: "10px",
            color: 'white',
            backgroundColor: 'rgb(35, 40, 57)'
        }}>
            {this.renderProfilePictureSection(user)}
            <div className="profile-section" style={{ borderTop: "1px solid #e0e0e0" }} onClick={(e) => {e.stopPropagation()}}>
                <h5>
                    <i className="material-icons theme-color profile-icon" >person</i>
                    {"Account type : " + (User.isContentCreator() ? "Creator" : "User")}
                    {!mobile_display && <Button className="t18-btn lm"
                        text={User.isContentCreator() ? "Disable Content Creator" : "Enable Content Creator"}
                        disabled={!User.hasAddress()}
                        tooltip={!User.hasAddress() ? "You need to associate a wallet to enable content creator mode." : undefined}
                        onClick={this.onToggleContentCreator} />}
                </h5>
                {mobile_display && <div style={{ marginBottom: '5px' }}>
                    <Button className="t18-btn"
                        text={User.isContentCreator() ? "Disable Content Creator" : "Enable Content Creator"}
                        disabled={!User.hasAddress()}
                        tooltip={!User.hasAddress() ? "You need to associate a wallet to enable content creator mode." : undefined}
                        onClick={this.onToggleContentCreator} />
                </div>}
            </div>
            <div className="profile-section clickable" onClick={this.onUsernameEditClick}>
                <h5>
                    <span className="profile-item">
                        <i className="material-icons theme-color profile-icon" >badge</i>
                        {"Username : " + (user.username || "None")}
                    </span>
                    <i className="material-icons theme-color profile-edit-icon" >chevron_right</i>
                </h5>
            </div>
            {User.isContentCreator() && <div className="profile-section clickable" onClick={this.onDescriptionEditClick}>
                <h5 style={{ wordBreak: 'break-all' }}>
                    <span className="profile-item">
                        <i className="material-icons theme-color profile-icon" >description</i>
                        {"Bio : " + (user.description || "None")}
                    </span>
                    <i className="material-icons theme-color profile-edit-icon">chevron_right</i>
                </h5>
            </div>}
            <div className="profile-section" onClick={(e) => {e.stopPropagation()}}>
                <h5 style={{ wordBreak: 'break-word' }}>
                    <span className="profile-item">
                        <i className="material-icons theme-color profile-icon" >account_balance_wallet</i>
                        <span>Wallet Address: </span>
                        {user.address && <span>{user.address}</span>}
                        {!mobile_display && !user.address && <Button className="t18-btn lm"
                            text="Associate Wallet"
                            // disabled={mobile_display}
                            // tooltip={mobile_display ? "Log in on a desktop to associate a wallet." : undefined}
                            onClick={this.onWalletAssociate} />}
                    </span>
                    {mobile_display && !user.address && <Button className="t18-btn"
                            style={{marginTop: '5px'}}
                            text="Associate Wallet"
                            // disabled={mobile_display}
                            // tooltip={mobile_display ? "Log in on a desktop to associate a wallet." : undefined}
                            onClick={this.onWalletAssociate} />}
                </h5>
            </div>
            <div className="profile-section clickable" onClick={this.onEmailEditClick}>
                <h5>
                    <span className="profile-item">
                        <i className="material-icons theme-color profile-icon" >alternate_email</i>
                        {"Email : " + (user.email || "None")}
                    </span>
                    <i className="material-icons theme-color profile-edit-icon" >chevron_right</i>
                </h5>
            </div>
            <div className="profile-section clickable" onClick={this.onPasswordEditClick}>
                <h5>
                    <span className="profile-item">
                        <i className="material-icons theme-color profile-icon" >key</i>
                        {"Password : " + (user.has_password ? "*************" : "None")}
                    </span>
                    <i className="material-icons theme-color profile-edit-icon">chevron_right</i>
                </h5>
            </div>
            {User.isContentCreator() && <div className="profile-section clickable" style={{ borderBottom: "none" }} onClick={this.onCurrencyEditClick}>
                <CurrencyInfo user={user} />
            </div>}
            {/* {!user.email || !user.has_password ? <div className="tm">
                You need to set an email and a password to be able to connect to the Metaverse.
            </div> : null} */}
        </div>
    }

}

class CurrencyInfo extends React.Component {

    onCurrencyEditClick = () => {
        window.openUserCurrenciesModal({ submit_cb: this.refresh });
    };

    refresh = () => {
        this.forceUpdate();
    };

    renderNetworkCurrencies = (network, currencies) => {
        let mobile_display = config.MOBILE_DISPLAY;
        return <div key={network}>
            <div className="tm" style={{
                fontSize: mobile_display ? "16px" : "20px",
                marginLeft: mobile_display ? "20px" : "40px"
            }}>
                <span className="bold" style={{ display: mobile_display ? "block" : "inline-block" }}>{utils.getNetworkDisplayName(network) + " : "}</span>
                {_.map(currencies, (currency) => {
                    return <span className="currency-badge inline-block">{currency}</span>
                })}
            </div>
        </div>
    }

    render() {
        let user = this.props.user,
            currency_count = 0,
            user_currencies = user.accepted_currencies || {},
            currency_display = {};
        for (let network in user_currencies) {
            for (let chain_id of user_currencies[network]) {
                let currency = generic.getMatchingEntity('currency', { network, chain_id });
                if (currency) {
                    currency_display[network] = currency_display[network] || [];
                    currency_display[network].push(currency.symbol);
                    currency_count++;
                }
            }
        }
        return (
            <div className="tm">
                <h5>
                    <span className="profile-item">
                        <i className="material-icons theme-color profile-icon" >attach_money</i>
                        {"Accepted currencies : " + (currency_count === 0 ? "None" : "")}
                    </span>
                    <i className="material-icons theme-color profile-edit-icon">chevron_right</i>
                </h5>
                {currency_count > 0 ? <div>
                    {Object.keys(currency_display).map((network) => {
                        return this.renderNetworkCurrencies(network, currency_display[network]);
                    })}
                </div> : undefined}
            </div>
        )
    }

}

import { getAddress } from 'ethers';
import _ from "lodash";
import moment from "moment";
import config from "../config";

const negative_bit = 0x1000,
  factor = 0x10000,
  clearLow =  0xffff0000,
  clearHigh = 0xffff;

const entry_refs = [{x: 0, y: -5}, {x: 5, y: 0}, {x: 0, y: 5}, {x: -5, y: 0}];

const utils = {

    getNetworkDisplayName: (network) => {
        if(network === 'homestead'){
            return 'Ethereum (Mainnet)';
        }else if(network === 'sepolia'){
            return 'Ethereum (Sepolia)';
        }else if(network === 'mumbai'){
            return 'Polygon (Mumbai)';
        }else if(network === 'matic'){
            return 'Polygon (Mainnet)';
        }else{
            return network;
        }
    },

    /**
     * Check if a variable match a type and optional other conditions
     * @param {*} value - value to check
     * @param {string} type - variable type to match
     * @param {{}} options - other conditions
     * @returns {boolean} true if match
     */
    checkVar: (value, type, options = {}) => {
        if (value === undefined || value === null)
            return false;
        else if (type === "integer" && !Number.isInteger(value))
            return false;
        else if ((type === "number" || type === "string" || type === "function" || type === "boolean") && typeof value != type)
            return false;
        else if(type === 'number' && isNaN(value))
            return false;
        else if (type === "array" && (typeof value != "object" || value.constructor !== Array))
            return false;
        else if (type === "object" && (typeof value != "object" || value.constructor !== Object))
            return false;
        else if (type === "hex-string" && (typeof value != "string" || !utils.isHexString(value)))
            return false;
        else if (type === "db_id" && (typeof value != "string" || value.length !== 24 || !utils.isHexString(value)))
            return false;

        if (options.db_id && (value.length !== 24 || !utils.isHexString(value)))
            return false;
        if ((options.min || options.min === 0) && value < options.min)
            return false;
        if ((options.max || options.max === 0) && value > options.max)
            return false;
        if (options.length && value.length !== options.length)
            return false;
        if (options.lengths && options.lengths.indexOf(value.length) === -1)
            return false;
        if (options.min_length && value.length < options.min_length)
            return false;
        if (options.max_length && value.length > options.max_length)
            return false;
        if (options.starts_with && !_.startsWith(value, options.starts_with))
            return false;
        if (options.regexp && !options.regexp.test(value))
            return false;
        if (options.attr && !utils.exist(value[options.attr]))
            return false;
        if (options.attrs) {
            for (var i in options.attrs) {
                if (!utils.exist(value[options.attrs[i]]))
                    return false;
            }
        }
        if (options.authorized_values && options.authorized_values.indexOf(value) === -1)
            return false;
        if (options.forbidden_content) {
            for (let forbid of options.forbidden_content) {
                if (value.indexOf(forbid) > -1)
                    return false;
            }
        }
        if (options.array_content_type) {
            for (let i in value) {
                if (!utils.checkVar(value[i], options.array_content_type))
                    return false;
            }
        }
        if (options.email === true && !utils.isEmail(value))
            return false;
        if (options.phone === true && !utils.isPhoneNumber(value))
            return false;
        if (options.time === true && !utils.isTime(value))
            return false;
        if (options.date === true && !utils.isDate(value))
            return false;
        if (options.iso_date === true && !utils.isIsoDate(value))
            return false;
        return true;
    },

    /**
     * Checks if the provided variable is not null or undefined.
     *
     * @param {*} variable - The variable to check for existence.
     * @returns {boolean} - Returns true if the variable is neither null nor undefined, otherwise false.
     */
    exist: (variable) => {
        return variable != null;
    },

    /**
     * Check if a variable is a hexadecimal string
     * @param {string} value - value to check
     * @returns {boolean} true if hexadecimal string
     */
    isHexString: (value) => {
        return /^[0-9a-fA-F]+$/.test(value);
    },

    /**
     * Check if a variable is an integer string
     * @param {string} value - value to check
     * @returns {boolean} true if integer string
     */
    isIntegerString: (value) => {
        return /^[0-9]+$/.test(value) || /^-[0-9]+$/.test(value);
    },

    /**
     * Check if a variable is a valid time string (HH:mm)
     * @param {string} value - value to check
     * @returns {boolean} true if valid time string
     */
    isTime: (value) => {
        if (value.length > 5 || value.length < 4)
            return false;
        var split = value.split(":");
        if (split.length !== 2)
            return false;
        if (!utils.isIntegerString(split[0]) || !utils.isIntegerString(split[1]))
            return false;
        var h = parseInt(split[0]), m = parseInt(split[1]);
        if (h < 0 || h > 23 || m < 0 || m > 59)
            return false;
        return true;
    },

    /**
     * Check if a variable is a valid date string (DD/MM/YYYY)
     * @param {string} value - value to check
     * @returns {boolean} true if valid date string
     */
    isDate: (value) => {
        if (value.length !== 10)
            return false;
        var split = value.split("/");
        if (split.length !== 3 || split[0].length !== 2 || split[1].length !== 2 || split[2].length !== 4)
            return false;
        if (!utils.isIntegerString(split[0]) || !utils.isIntegerString(split[1]) || !utils.isIntegerString(split[2]))
            return false;
        var d = parseInt(split[0]), m = parseInt(split[1]), y = parseInt(split[2]);
        if (d < 0 || d > 31 || m < 0 || m > 12 || y < 0)
            return false;
        return true;
    },

    /**
     * Checks if a given value is a valid ISO 8601 date string.
     * @param {string} value - The string to be checked for ISO 8601 date format compliance.
     * @returns {boolean} Returns true if the value is a valid ISO 8601 date string, false otherwise.
     */
    isIsoDate: (value) => {
        return moment(value, moment.ISO_8601).isValid();
    },

    /**
     * Check if a variable is a valid email address
     * @param {string} value - value to check
     * @returns {boolean} true if valid email address
     */
    isEmail: (value) => {
        var splitted = value.split("@");
        if (splitted.length !== 2 || splitted[0].length === 0 || splitted[1].length === 0)
            return false;
        if (splitted[1].indexOf(".") === -1)
            return false;
        if (splitted[1].split(".")[1].length === 0)
            return false;
        return true;
    },

    /**
     * Check if a variable is a valid phone number
     * @param {string} value - value to check
     * @returns {boolean} true if valid phone number
     */
    isPhoneNumber: (value) => {
        if (!/^[0-9+]+$/.test(value))
            return false;
        if (value.indexOf("+") > 0)
            return false;
        return true;
    },

    getFileExtension: function(file) {
        return file.name.split('.').pop().toLowerCase();
    },

    encodeTokenId: (x, y) => {
        return (utils.encodeCoordinate(x) * factor) | utils.encodeCoordinate(y);
    },

    encodeCoordinate: (value) => {
        if (value >= 0) {
            return value;
        } else {
            return Math.abs(value) | negative_bit;
        }
    },

    decodeTokenId: (tokenId) => {
        let x = utils.decodeCoordinate((tokenId & clearLow) / factor);
        let y = utils.decodeCoordinate(tokenId & clearHigh);
        return {x, y};
    },

    decodeCoordinate: (value) => {
        if ((value & negative_bit) === negative_bit){
            return value - negative_bit;
        } else {
            return value;
        }
    },

    parseLandTransferEvent: (event) => {
        let owner = event.args.to;
        let tokenId = parseInt(event.args.tokenId._hex, 16);
        return {
            tokenId: tokenId,
            owner: owner
        }
    },

    isValidAddress: (address) => {
        try{
            getAddress(address);
            return true;
        }catch(e){
            return false;
        }
    },

    getLandEntriesFromLinks: (land) => {
        return utils.getSidesFromObjects(_.get(land, 'data.links', []));
    },

    getSidesFromObjects: (targets) => {
        let sides = [0, 0, 0, 0];
        for(let target of targets){
            let x = target.position.x, y = target.position.z;
            let distance_list = [];
            for(let entry of entry_refs){
                distance_list.push(Math.sqrt(Math.pow(x - entry.x, 2) + Math.pow(y - entry.y, 2)));
            }
            let closest_index, closest_distance;
            for(let i = 0; i < 4; i++){
                let d = distance_list[i];
                if(closest_index === undefined || d < closest_distance){
                    closest_index = i;
                    closest_distance = d;
                }
            }
            if(closest_index !== undefined){
                sides[closest_index] = 1;
            }
        }
        return sides;
    },

    formatBytes: (bytes, decimals = 2) => {
        if (bytes === 0) return '0 Bytes';
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    },

    getDifferenceBetweenTwoDates: (date1, date2) => {
        let d1 = moment(date1), d2 = moment(date2), days = 0, hours = 0, minutes = 0, seconds = 0;
        if(d1.isBefore(d2)){
            let diff = d2.diff(d1);
            days = Math.floor(diff / (1000 * 60 * 60 * 24));
            diff -= days * (1000 * 60 * 60 * 24);
            hours = Math.floor(diff / (1000 * 60 * 60));
            diff -= hours * (1000 * 60 * 60);
            minutes = Math.floor(diff / (1000 * 60));
            diff -= minutes * (1000 * 60);
            seconds = Math.floor(diff / (1000));
        }
        return {days, hours, minutes, seconds};
    },

    getHexTokenId: (tokenId) => {
        return '0x' + tokenId.toString(16);
    },

    getUnitTimeText: (unit_time) => {
        if(unit_time === 604800){
            return 'week';
        }else if(unit_time === 2592000){
            return 'month';
        }else if(unit_time === 31536000){
            return 'year';
        }
    },

    toContractPrice: (price) => {
        let decimals = config.contract.usd_decimals;
        return Math.round(price * Math.pow(10, decimals));
    },

    toUsdPrice: (price, result_decimals = 2) => {
        let decimals = config.contract.usd_decimals;
        return _.round(price / Math.pow(10, decimals), result_decimals);
    },

    getPriceForCurrency: (currency, price, decimals = config.contract.usd_decimals) => {
        let usd_rate = currency.usd_rate;
        if(!usd_rate){
            return 0;
        }
        // return _.round(price * usd_rate * Math.pow(10, decimals), decimals);
        return _.round(price / usd_rate, decimals);
    },

    asyncMap: (list, func, callback, options = {}) => {
        if(list.length === 0){
            return callback();
        }
        if(options.keep_order){
            let index = 0, process_next = () => {
                let item = list[index];
                func(item, (err) => {
                    index++;
                    if(options.throw_error && err){
                        return callback(err);
                    }
                    if(index >= list.length){
                        return callback();
                    }else{
                        return process_next();
                    }
                });
            };
            process_next();
        }else if(options.max_concurrency) {
            let index = -1, running = 0, error = false, process_next = () => {
                if(running >= options.max_concurrency || (options.throw_error && error)){
                    return;
                }
                index++;
                if(index >= list.length){
                    return;
                }
                let item = list[index];
                running++;
                func(item, (err) => {
                    if(options.throw_error && err){
                        error = true;
                        return callback(err);
                    }
                    running--;
                    if(index >= list.length){
                        if(running === 0){
                            return callback();
                        }
                    }else{
                        return process_next();
                    }
                });
                process_next();
            };
            process_next();
        }else{
            let processed_count = 0, process_cb = () => {
                processed_count++;
                if(processed_count === list.length){
                    return callback();
                }
            };
            for(let item of list){
                func(item, process_cb);
            }
        }
    },

    computeExistingTags: (targets) => {
        let tags = [];
        for(let target of targets){
            let target_tags = target.tags || [];
            for(let tag of target_tags){
                if(tags.indexOf(tag) === -1){
                    tags.push(tag);
                }
            }
        }
        return tags;
    },

    getFilteredTargets: (targets, filters = {}, options = {}) => {
        let type = filters.type || 'all',
            access = filters.access || 'all',
            tags = filters.tags || [],
            subscriptions = filters.subscriptions || [],
            search = filters.search || '',
            visibility = filters.visibility || 'all',
            content_creator = filters.content_creator || 'all',
            search_target = options.search_target || 'name',
            search_function = options.search_function;
        let filtered =  _.filter(targets, (target) => {
            if (type !== 'all' && target.type !== type) {
                return false;
            } else if (visibility !== 'all' && visibility === "public" && target.visibility === "private") {
                return false;
            } else if (content_creator !== 'all' && target.content_creator !== content_creator) {
                return false;
            } else if(access === 'free' && target.paid_access) {
                return false;
            } else if(access === 'subscription' && !target.paid_access) {
                return false;
            } else if(access === 'paid' && (!target.paid_access || target.paid_access_denied)) {
                return false;
            } else if (search !== '') {
                if(search_function && !search_function(target, search)){
                    return false;
                }else if(!search_function && (target[search_target] || "").toLowerCase().indexOf(search.toLowerCase()) === -1){
                    return false;
                }
            } 
            if(tags.length > 0) {
                let target_tags = target.tags || [], 
                    match_all = true;
                for(let tag of tags) {
                    if(!target_tags.includes(tag)) {
                        match_all = false;
                        break;
                    }
                }
                if(!match_all){
                    return false;
                }
            }
            if(subscriptions.length > 0) {
                let target_subscriptions = target.subscription_id_list || [], 
                    match_one = false;
                for(let sub_id of subscriptions) {
                    if(target_subscriptions.includes(sub_id)) {
                        match_one = true;
                        break;
                    }
                }
                if(!match_one){
                    return false;
                }
            }
            return true;
        });
        utils.sortByCreationDate(filtered);
        return filtered;
    },

    sortByCreationDate: (list) => {
        list.sort((a, b) => {
            return b.creation_date - a.creation_date;
        });
    },

    formatMediaName: (name) => {
        if(!name || !name.split){
            return name;
        }
        let split = name.split('.');
        if(split.length === 2 && split[1].length <= 4){
            return split[0];
        }
        return name;
    }

};

String.prototype.upperFirstLetter = function() {
    let target = this, delimiter;
    if(target.indexOf('-') > -1){
        delimiter = '-';
    }else if(target.indexOf('_') > -1){
        delimiter = '_';
    }
    if(!delimiter){
        return target.replace(target[0], target[0].toUpperCase());
    }else{
        let split = target.split(delimiter);
        for(let i = 0; i < split.length; i++){
            split[i] = split[i].upperFirstLetter();
        }
        return split.join('');
    }
};

String.prototype.replaceAll = function(search, replacement) {
    let target = this;
    return target.replace(new RegExp(search, 'g'), replacement);
}

if (typeof(Number.prototype.toRad) === "undefined") {
    Number.prototype.toRad = function () {
        return this * Math.PI / 180;
    }
}

export default utils;

window.utils = utils;

window._ = _;
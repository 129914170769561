import React from "react";

import config from "../../../config";

import ReturnIconButton from "../../common/ReturnIconButton";

import MediaDisplay from "../../media/MediaDisplay";

export default class extends React.Component {

    render() {
        let media_id = this.props.mediaId,
            style = {
                backgroundColor: 'white',
                position: 'relative',
                padding: '40px',
                margin: '20px',
                height: 'calc(100% - 40px)',
                borderRadius: '15px'
            };
        if(config.MOBILE_DISPLAY){
            style.padding = '15px 5px';
            style.margin = '3px';
            style.height = 'auto';
        }
        return <div className="selected-media-display h-100 col s12 m9" >
            <MediaDisplay key={media_id} media_id={media_id} media_height={"100%"}
                mediaClassName="z-depth-2" wrapperStyle={{ height: '100%', overflow: 'hidden' }} />
        </div>
    }

}
import React from 'react';
import User from "../../model/user";

import config from "../../config";

import Modal from "../global/Modal";
import Button from "../global/Button";
import InputField from "../global/InputField";

import WalletConnectPanel from "../profile/WalletConnectPanel";

export default class LoginModal extends React.Component {

    state = { opened: false };

    componentDidMount = () => {
        window.openLoginModal = this.openModal;
    };

    getOpen = (open) => {
        this.setState({ openModal: open });
    };

    openModal = (options = {}, undismissible = false, close_cb = () => { }) => {
        this.setState({
            type: null,
            opened: true,
            undismissible: undismissible,
            on_signin_cb: options.on_signin_cb || (() => { }),
            close_cb: close_cb
        }, this.state.openModal);
    };

    getClose = (close) => {
        this.setState({ closeModal: close });
    };

    afterOpen = () => {
        //$(".lean-overlay").last().addClass("alert-popup-overlay");
    };

    afterClose = () => {
        this.setState({ opened: false }, this.state.close_cb);
    };

    onCredentialsLogin = () => {
        this.setState({ type: 'credentials' })
    }

    onWalletLogin = () => {
        this.setState({ type: 'wallet' })
    };

    onSignUp = () => {
        window.openSignUpModal({ on_signup_cb: this.state.on_signin_cb });
        this.state.closeModal();
    };

    render = () => {
        let type = this.state.type,
            mobile_display = config.MOBILE_DISPLAY,
            btn_style = {
                fontSize: mobile_display ? "24px" : "30px",
                height: "58px",
                padding: "10px 20px",
                borderRadius: "15px",
                marginTop: mobile_display ? "0" : "15px",
                minWidth: mobile_display ? undefined : '280px',
                width: mobile_display ? "100%" : undefined
            };
        let content = this.state.opened ? <div className="reglisse">
            <h2 className="center-align" style={{marginBottom: mobile_display ? "15px" : undefined}}>Log In</h2>
            {!type ? <div>
                <div className="center-align">
                    <Button className="t18-btn" style={btn_style}
                            text={"Credentials"} onClick={this.onCredentialsLogin} />
                </div>
                {(!mobile_display || true) && <div className="center-align tm">
                    <Button className="t18-btn" style={btn_style}
                        text={"Use Wallet"} onClick={this.onWalletLogin} />
                </div>}
                <div className="center-align tm">
                    <Button className="" style={Object.assign({}, btn_style, { backgroundColor: "#ea80fc" })}
                        text={"Sign Up"} onClick={this.onSignUp} />
                </div>
            </div> : undefined}
            {type === 'wallet' && <WalletSignIn close={this.state.closeModal} onSignin={this.state.on_signin_cb} />}
            {type === 'credentials' && <CredentialSignIn onSignin={this.state.on_signin_cb} close={this.state.closeModal} />}
            {type !== 'credentials' && <div className="modal-footer tm">
                <Button className='t18-btn lm black' text="Cancel" style={{ fontSize: "20px" }}
                    onClick={this.state.closeModal} large={true} />
            </div>}
        </div> : undefined;

        return (
            <Modal id="login-modal"
                content={content}
                getOpen={this.getOpen}
                getClose={this.getClose}
                afterOpen={this.afterOpen}
                afterClose={this.state.close_cb}
                undismissible={this.state.undismissible}
            />
        )
    }

}

class CredentialSignIn extends React.Component {

    state = { email: "", password: "" };

    onEmailChange = (value) => {
        this.setState({ email: value });
    }

    onPasswordChange = (value) => {
        this.setState({ password: value });
    }

    onSubmit = () => {
        let email = this.state.email, password = this.state.password;
        User.signInWithCredentials(email, password, (err) => {
            if (err) {
                console.log('Signin Error : ' + JSON.stringify(err));
                let msg = 'An error has occurred while signing in with wallet.';
                if (err.message === 'Not authenticated : invalid-credentials') {
                    msg = 'Invalid credentials.';
                }
                window.alertPopup('Signin error', msg)
            } else {
                this.props.close();
                this.props.onSignin();
            }
        })
    }

    render() {
        return <div>
            <div className="center-align tm">
                <div className="center-align tm">
                    <h4 style={{ display: 'inline-block' }}>Credentials sign in</h4>
                </div>
                <div className="center-align tm">
                    <InputField style={{ display: 'inline-block', width: '300px' }} label="Email" type="email"
                        onChange={this.onEmailChange} />
                </div>
                <div className="center-align tm">
                    <InputField style={{ display: 'inline-block', width: '300px' }} label="Password" type="password"
                        onChange={this.onPasswordChange} />
                </div>
                <div className="modal-footer tm">
                    <Button className="t18-btn tm" text="Sign In" onClick={this.onSubmit} style={{ fontSize: "20px" }} />
                    <Button className='t18-btn lm black' text="Cancel" style={{ fontSize: "20px" }}
                        onClick={this.props.close} large={true} />
                </div>
            </div>
        </div>
    }

}

class WalletSignIn extends React.Component {

    onWalletConnected = () => {
        User.signInWithWallet((err) => {
            if (err) {
                console.log('Signin Error : ');
                console.log(err);
                let err_msg = 'An error has occurred while signing in with wallet.';
                if(err.message && err.message.includes('address-not-found')){
                    err_msg = 'This wallet is not associated to any account.';
                }else if(err.reason && err.reason === 'user rejected signing') {
                    err_msg = 'You have rejected the auth signing. Please try again.';
                }
                window.alertPopup('Signin error', err_msg);
                this.props.close();
            } else {
                this.props.close();
                this.props.onSignin();
            }
        })
    };

    render() {
        return <div>
            <div className="center-align tm">
                <h4 style={{ display: 'inline-block' }}>Wallet authentication</h4>
            </div>
            <WalletConnectPanel onWalletConnected={this.onWalletConnected} />
        </div>
    }

}

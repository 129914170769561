
const beta_config = {

    contract: {
        enable_beta_minting: true,
        land_network_name: 'sepolia',
        wallet_connect_project_id: '89f68ff9b7b3b9291e4ab056e5f7960c',
        enabled_networks: ['sepolia'],
        land: {
            sepolia: {
                address: '0x3D415DDF8b7c02354E211Bc681e18553d1b04063'
            }
        },
        subscription: {
            name: 'T18Subscription',
            networks: {
                sepolia: {
                    address: '0xfcE3E785FCdD0635F9775CC5E25Ee4B03eF28a6f'
                },
                mumbai: {
                    address: '0xdF8B2448070ABc8F08Ea0139444f6d3512dccdF0'
                }
            }
        },
        payment: {
            name: 'T18Payment',
            networks: {
                sepolia: {
                    address: '0x08c6Bd6f02acAD39004110c04ae9639D700A1B80'
                },
                mumbai: {
                    address: '0xAfc9b775bF3FA5E9E4E84651E521DF6222324A52'
                }
            }
        },
        usd_decimals: 8
    },

    deep_links: {
        metamask: 'https://metamask.app.link/dapp/beta.only.terminal18.org',
        coinbase: 'https://go.cb-w.com/dapp?cb_url=https%3A%2F%2Fbeta.only.terminal18.org',
    }

}

export default beta_config;
import React  from 'react';

import Modal from "../global/Modal";
import Button from "../global/Button";

import generic from "../../model/generic";

import MediaDisplay from "../media/MediaDisplay";


export default class MediaDisplayModal extends React.Component {

    state = { opened: false, openModal: null, closeModal: null };

    componentDidMount() {
        window.openMediaDisplayModal = this.openModal;
        // refresh when window is resized
        window.addEventListener("resize", () => {
            this.forceUpdate();
        });
    }

    getOpen = (open) => {
        this.setState({ openModal: open });
    };

    openModal = (options = {}, close_cb = function() {}) => {
        let state = { opened: true, undismissible: options.undismissible, media_id: options.media_id, close_cb: close_cb };

        this.setState(state, this.state.openModal);
    };

    getClose = (close) => {
        this.setState({ closeModal: close });
    };

    afterOpen = () => {
        //$(".lean-overlay").last().addClass("alert-popup-overlay");
    };

    afterClose = () => {
        this.setState({opened: false}, this.state.close_cb);
    };

    onValueChange = (value) => {
        this.setState({value: value});
    };

    onSubmit = () => {

        this.state.closeModal();
    };

    render() {
        let disabled = false, content;
        if(this.state.opened){
            let media = generic.getEntity("media", this.state.media_id),
                height = (window.innerHeight - 250) + "px";
            content = <div id="media-display-modal-content" className="reglisse">
                <h3 style={{margin: 0}}>{media && media.name}</h3>
                <MediaDisplay key={media && media.id} media_id={this.state.media_id} media_height={height} />
                <div className="modal-footer">
                    <Button text={"Close"} className='t18-btn black' onClick={this.state.closeModal} large={true}
                            style={{fontSize: "20px"}}/>
                </div>
            </div>;
        }
        return (
            <Modal id="media-display-modal"
                   className="large-modal"
                   content={content}
                   getOpen={this.getOpen}
                   getClose={this.getClose}
                   afterOpen={this.afterOpen}
                   afterClose={this.afterClose}
                   undismissible={this.state.undismissible}
            />
        )
    }
};

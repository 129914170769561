import React from "react";

import config from "../../config";

import generic from "../../model/generic";
import utils from "../../lib/utils";

import MediaDisplay from './MediaDisplay';
import ProfilePicture from "../profile/ProfilePicture";

export default class extends React.Component {

    state = { };

    componentDidMount() {

    }

    onHeaderClick = (e) => {
        if(this.props.onHeaderClick) {
            e.stopPropagation();
            this.props.onHeaderClick(this.props.media.id);
        }
    }

    refresh = () => {
        this.forceUpdate();
    }

    render() {
        let media = this.props.media,
            owner = generic.getEntity('user', media.owner) || {},
            className = 'media-mini-display z-depth-2',
            wrapper_className = "col " + (this.props.largeWrapper ? "xl2 l3 m4 s6" : "l4 m6 s12");
        if (this.props.className) {
            className += " " + this.props.className;
        }
        if (this.props.onClick) {
            className += " pointer";
        }
        if(this.props.noColClass) {
            wrapper_className = "";
        }
        return <div className={wrapper_className} style={{ padding: "20px 15px" }}>
            <div className={className} style={{ position: "relative" }}
                onClick={this.props.onClick && this.props.onClick.bind(null, media.id)} >
                    <div className="creator-header" onClick={this.onHeaderClick}>
                        <ProfilePicture user_id={media.owner} 
                            style={{ width: "40px", height: "40px", borderRadius: "50%", overflow: "hidden" }}
                            imgStyle={{objectFit: 'cover', maxHeight: 'initial', width: 'initial', height: 'initial'}} />
                        <span className="creator-name" style={{
                            width: 'calc(100% - 60px)',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                        }}>{(owner.username) || "Unnamed"}</span>
                    </div>
                <MediaDisplay wrapperClassName="media-display" 
                              key={media && media.id} 
                              media_id={media && media.id}
                              showBadges={true}
                              onMediaLoad={this.onRefresh} />
                <div className="media-footer">
                    <div className="media-title">{utils.formatMediaName(media.name)}</div>
                    {/* <div className="media-description">{media.description}</div> */}
                </div>
            </div>
        </div>

    }

}
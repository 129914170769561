import React from 'react';

import Modal from "../global/Modal";
import Button from "../global/Button";

import WalletConnectPanel from "../profile/WalletConnectPanel";

export default class ConnectWalletModal extends React.Component {

    state = { opened: false, openModal: null, closeModal: null };

    componentDidMount() {
        window.openConnectWalletModal = this.openModal;
    }

    getOpen = (open) => {
        this.setState({ openModal: open });
    };

    openModal = (options = {}, close_cb = function () { }) => {
        let state = { 
            opened: true, 
            undismissible: options.undismissible, 
            on_connect: options.on_connect || function () { },
            close_cb: close_cb 
        };

        this.setState(state, this.state.openModal);
    };

    getClose = (close) => {
        this.setState({ closeModal: close });
    };

    afterOpen = () => {
        //$(".lean-overlay").last().addClass("alert-popup-overlay");
    };

    afterClose = () => {
        this.setState({ opened: false }, this.state.close_cb);
    };

    onValueChange = (value) => {
        this.setState({ value: value });
    };

    onConnected = () => {
        this.state.closeModal();
        this.state.on_connect();
    };

    render() {
        let content;
        if (this.state.opened) {
            content = <div id="connect-wallet-modal-content" className="reglisse">
                <h3 style={{ margin: 0 }}>{"Connect Wallet"}</h3>
                <WalletConnectPanel onWalletConnected={this.onConnected} />
                <div className="modal-footer">
                    <Button text={"Cancel"} className='t18-btn black' onClick={this.state.closeModal} large={true}
                        style={{ fontSize: "20px" }} />
                </div>
            </div>;
        }
        return (
            <Modal id="connect-wallet-modal"
                content={content}
                getOpen={this.getOpen}
                getClose={this.getClose}
                afterOpen={this.afterOpen}
                afterClose={this.afterClose}
                undismissible={this.state.undismissible}
            />
        )
    }
};
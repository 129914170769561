import React from "react";

import config from "../../../../shared/config";

import User from "../../../../shared/model/user";

import Title from "../../../../shared/components/global/Title";
import ReturnIconButton from "../../../../shared/components/common/ReturnIconButton";
import ReturnTitle from "../../../../shared/components/common/ReturnTitle";
import MobileMenu from "../../../../shared/components/common/MobileMenu";
import ProfilePanel from '../../../../shared/components/profile/ProfilePanel';
import MediaView from "../../../../shared/components/manager/MediaView/MediaView";
import SubscriptionView from "../../../../shared/components/manager/SubscriptionView/SubscriptionView";
import WalletBalanceDisplay from "../../../../shared/components/profile/WalletBalanceDisplay";
import SubscriptionDisplay from "../../../../shared/components/profile/SubscriptionDisplay";
import SubscriptionTokenDisplay from '../../../../shared/components/profile/SubscriptionTokenDisplay';

const menus = {
    profile: {
        name: 'Profile',
        icon: 'person'
    },
    media: {
        name: 'My Medias',
        icon: 'photo_camera'
    },
    wallet: {
        name: 'My Wallet',
        icon: 'account_balance_wallet'
    },
    monetization: {
        name: 'Monetization',
        icon: 'attach_money'
    },
    subscription: {
        name: 'Subscriptions',
        icon: 'local_activity'
    }
} 

export default class extends React.Component {

    state = {view: 'menu'};

    componentDidUpdate(prevProps, prevState) {
        if(prevProps.show !== this.props.show && this.props.show){
            this.setState({view: 'menu'});
        }
    }

    refresh = () => {
        this.forceUpdate();
    }

    onSelectView = (view) => {
        this.setState({view: view});
    }

    onReturn = () => {
        this.setState({view: 'menu'});
    }

    renderMenu = () => {
        let menu_list = ['profile', 'subscription'];
        if(User.isContentCreator()){
            menu_list = ['profile', 'media', 'wallet', 'monetization', 'subscription']
        }else if(User.hasAddress()){
            menu_list = ['profile', 'wallet', 'subscription']
        }
        let menu_items = menu_list.map((item) => {
                return {name: menus[item].name, icon: menus[item].icon, onClick: this.onSelectView.bind(null, item)}
            });
        return <MobileMenu items={menu_items} className="tm" />
    }

    renderView = (view) => {
        if(view === 'profile'){
            return <ProfilePanel showSubscriptions={false} />
        }else if(view === 'media'){
            return <MediaView show={true} />
        }else if(view === 'wallet'){
            return <div className="col s12">
                    <WalletBalanceDisplay />
                </div>
        }else if(view === 'monetization'){
            return <div className="col s12">
                    <SubscriptionDisplay />
                </div>
        }else if(view === 'subscription'){
            return <div className="col s12">
                    <SubscriptionTokenDisplay />
                </div>
        }
    }

    render() {
        let view = this.state.view;
        return <div id="mobile-profile-view" className={"app-view white-text row" + (this.props.show ? '' : ' hide')} >
            {view === "menu" && this.renderMenu()}
            {view !== 'menu' && <div className="mobile-profile-view-wrapper" style={{position: 'relative', zIndex: 200, backgroundColor: "#161823"}}>
                    <ReturnTitle fixed={true} content={<div style={{position: 'relative', top: '8px'}}>{menus[view].name}</div>} onReturn={this.onReturn} />
                    {this.renderView(view)}
                    {/* <ReturnIconButton onClick={this.onReturn} /> */}
                </div>}
        </div>
    }

}
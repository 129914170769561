import React from 'react';
import _ from 'lodash';

import generic from '../../model/generic';

import MediaSelector from "../media/MediaSelector";

import Modal from "../global/Modal";
import Button from "../global/Button";


export default class DisplayMediaAddModal extends React.Component {

    state = { opened: false, selected: [], multiple_select: false, openModal: null, closeModal: null };

    componentDidMount() {
        window.openDisplayMediaAddModal = this.openModal;
    }

    getOpen = (open) => {
        this.setState({ openModal: open });
    };

    openModal = (options, close_cb = function () { }) => {
        let state = {
            opened: true, selected: options.selected || [], on_submit: options.on_submit, multiple_select: options.multiple_select,
            media_type_filter: options.media_type_filter, filter_out: options.filter_out, undismissible: options.undismissible, close_cb: close_cb
        };
        this.setState(state, this.state.openModal);
    };

    getClose = (close) => {
        this.setState({ closeModal: close });
    };

    afterOpen = () => {
        //$(".lean-overlay").last().addClass("alert-popup-overlay");
    };

    afterClose = () => {
        this.setState({ opened: false }, this.state.close_cb);
    };

    onMediaSelect = (id) => {
        if (this.state.multiple_select) {
            let selected = this.state.selected.slice(), index = selected.indexOf(id);
            if (index === -1) {
                selected.push(id);
            } else {
                selected.splice(index, 1);
            }
            this.setState({ selected: selected });
        } else {
            this.setState({ selected: [id] });
        }
    };

    onSubmit = () => {
        this.state.on_submit(this.state.multiple_select ? this.state.selected : this.state.selected[0]);
        this.state.closeModal();
    };

    getMedias = () => {
        let medias = generic.getOwnedEntities('media'), filter = this.state.media_type_filter, filter_out = this.state.filter_out || [];
        if (filter) {
            medias = _.filter(medias, (media) => {
                return filter.indexOf(media.type) > -1 && filter_out.indexOf(media.id) === -1;
            });
        }
        return medias;
    }

    render() {
        let content,
            footer;
        if (this.state.opened) {
            let selected = this.state.selected, disabled = selected.length === 0;
            let medias = this.getMedias();
            content = <div id="display-media-add-content" className="reglisse">
                <h3 style={{ margin: 0 }}>{"Select media content"}</h3>
                <MediaSelector medias={medias} selected={selected} onSelect={this.onMediaSelect} />
            </div>;
            footer = [
                <Button text={'Submit'} className='t18-btn' onClick={this.onSubmit} disabled={disabled} style={{ fontSize: "20px" }} />,
                <Button text={"Cancel"} className='t18-btn lm black' onClick={this.state.closeModal} large={true}
                    style={{ fontSize: "20px" }} />
            ]
        }
        return (
            <Modal id="display-media-add-modal"
                className="large-modal"
                content={content}
                footer={footer}
                fixedFooter={false}
                getOpen={this.getOpen}
                getClose={this.getClose}
                afterOpen={this.afterOpen}
                afterClose={this.afterClose}
                undismissible={this.state.undismissible}
            />
        )
    }
};

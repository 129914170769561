import React  from 'react';
import _ from 'lodash';

import utils from '../../lib/utils';

import Modal from "../global/Modal";
import Button from "../global/Button";
import InputField from "../global/InputField";
import CheckBox from '../global/CheckBox';

export default class GenericForm extends React.Component {

    state = { opened: false, openModal: null, closeModal: null, inputs: {} };

    componentDidMount() {
        window.openGenericForm = this.openModal;
    }

    getOpen = (open) => {
        this.setState({ openModal: open });
    };

    openModal = (options, close_cb = () => {}) => {
        let form_inputs = {},
            inputs = options.inputs || [];
        _.each(inputs, (input) => {
            if(typeof input === 'string'){
                input = {name: input, type: 'text'};   
            }
            let type = input.type || 'text',
                value = input.value;
            if(value === undefined){
                if(type === 'number'){
                    value = 0;
                } else if(type === 'boolean'){
                    value = false;
                } else {
                    value = '';
                }
            }
            form_inputs[input.name] = {
                value: input.value || '',
                type: type,
                label: input.label || input.name,
                required: input.required || false,
                control: input.control
            };
        });

        let state = { 
            opened: true, 
            title: options.title || "Form",
            inputs: form_inputs,
            undismissible: options.undismissible, 
            close_cb: close_cb, 
            on_submit: options.on_submit 
        };

        this.setState(state, this.state.openModal);
    };

    getClose = (close) => {
        this.setState({ closeModal: close });
    };

    afterOpen = () => {
        //$(".lean-overlay").last().addClass("alert-popup-overlay");
    };

    afterClose = () => {
        this.setState({opened: false}, this.state.close_cb);
    };

    onNameChange = (value) => {
        this.setState({name: value});
    };

    onInputValueChange = (name, value) => {
        let inputs = this.state.inputs;
        inputs[name].value = value;
        this.setState({inputs: inputs});
    };

    renderInput = (input, name) => {
        let options = _.get(input, 'control.options', {});
        if(['number', 'text'].includes(input.type)){
            // step={0.01} min={0} max={1000}
            return <InputField type={input.type} 
                               label={input.label} 
                               value={input.value} 
                               step={options.step}
                               min={options.min}
                               max={options.max}
                               onChange={this.onInputValueChange.bind(this, name)} />
        }else if(input.type === 'boolean'){
            return <CheckBox label={input.label} checked={input.value} onClick={this.onInputValueChange.bind(this, name)} />
        }
    };

    onSubmit = () => {
        let data = {};
        _.each(this.state.inputs, (input, name) => {
            data[name] = input.value;
        });
        this.state.on_submit(data);
        this.state.closeModal();
    };

    render() {
        let disabled = false,
            inputs_display = [];
        _.each(this.state.inputs, (input, name) => {
            inputs_display.push(this.renderInput(input, name));
            if(input.required && !input.value){
                disabled = true;
            }else if(input.control){
                if(typeof input.control === 'object' && !utils.checkVar(input.value, input.control.type, input.control.options)){
                    disabled = true;
                }else if(typeof input.control === 'function' && !input.control(input.value)){
                    disabled = true;
                }
            }
        });
        const content = this.state.opened ? <div id="generic-form-content" className="reglisse">
            <h3 style={{margin: 0}}>{this.state.title}</h3>
            <div style={{marginTop: '50px'}}>
                {inputs_display}
            </div>
            <div className="modal-footer">
                <Button text={'Submit'} className='t18-btn' onClick={this.onSubmit} disabled={disabled} style={{fontSize: "20px"}} />
                <Button text={"Cancel"} className='t18-btn lm black' onClick={this.state.closeModal} large={true}
                        style={{fontSize: "20px"}}/>
            </div>
        </div> : undefined;
        return (
            <Modal id="generic-form"
                   content={content}
                   getOpen={this.getOpen}
                   getClose={this.getClose}
                   afterOpen={this.afterOpen}
                   afterClose={this.afterClose}
                   undismissible={this.state.undismissible}
            />
        )
    }
};

import React from "react";

import generic from "../../../model/generic";

import IconButton from "../../global/IconButton";
import ProfilePicture from "../../profile/ProfilePicture";

export default class extends React.Component {

    onOpenUser = () => {
        if(window.showUser){
            window.showUser(this.props.userId);
        }
    }

    render() {
        let user = generic.getEntity('user', this.props.userId),
            redirect = this.props.redirectAction,
            className = "mobile-media-header";
        if(this.props.className){
            className += " " + this.props.className;
        }
        if(this.props.fixed){
            className += " fixed";
        }
        if(this.props.hidePicture){
            className += " hide-picture";
        }
        if(!user) return;
        return <div className={className}>
            <div className={"creator-header z-depth-1" + (redirect && false ? " clickable" : "")} >
                <div>
                    <IconButton icon="west" onClick={this.props.onReturn} className="return-btn" />
                    <span className={redirect ? "pointer" : ""} onClick={redirect ? this.onOpenUser : undefined}>
                        <ProfilePicture user_id={user.id} />
                        <span className="creator-name">{(user.username) || "Unnamed"}</span>
                    </span>
                </div>
                {user.description && <div className="creator-description">{user.description}</div>}
            </div>
        </div>
    }

}
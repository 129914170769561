import React from 'react';
import { parseUnits } from 'ethers';

import Modal from "../global/Modal";
import Button from "../global/Button";

import LoadingAnimation from "../global/LoadingAnimation";

import generic from "../../model/generic";
import Subscription from "../../model/subscription";
import utils from "../../lib/utils";
import web3 from '../../lib/web3';
import web3_mint from '../../lib/web3_mint';

export default class BetaMintingModal extends React.Component {

    state = { opened: false, openModal: null, closeModal: null, current_step: 'validate' };

    componentDidMount() {
        window.openBetaMintingModal = this.openModal;
    }

    getOpen = (open) => {
        this.setState({ openModal: open });
    };

    openModal = (options = {}, close_cb = function () { }) => {
        let state = {
            opened: true, 
            current_step: 'validate',
            network: options.network,
            currency: options.currency,
            target_address: options.target_address,
            amount: options.amount || 1000,
            on_transaction_processed: options.on_transaction_processed || function () { },
            on_transaction_error: options.on_transaction_error || function () { }, 
            undismissible: true, 
            close_cb: close_cb
        };

        this.setState(state, this.state.openModal);
    };

    getClose = (close) => {
        this.setState({ closeModal: close });
    };

    afterOpen = () => {
        //$(".lean-overlay").last().addClass("alert-popup-overlay");
    };

    afterClose = () => {
        this.setState({ opened: false }, this.state.close_cb);
    };

    onMint = () => {
        this.setState({ current_step: 'wait_mint' });
        let currency = this.state.currency,
            network = this.state.network,
            target_address = this.state.target_address,
            amount = this.state.amount;
        web3_mint.mintCurrency(network, currency.symbol, target_address, this.onMintTransactionSent, this.onTransactionProcessed);
    };

    onMintTransactionSent = (err, tx_id) => {
        if(err){
            this.state.closeModal();
            console.log('onMintTransactionSent error');
            console.log(err);
            window.alertPopup('Error', 'An error has occurred while sending mint transaction.');
            this.state.on_transaction_error(err);
        }else{
            this.setState({ current_step: 'wait_mint_transaction' });
        }
    };

    onTransactionProcessed = (err) => {
        if(err){
            this.state.closeModal();
            console.log('onTransactionProcessed error');
            console.log(err);
            window.alertPopup('Error', 'Transaction has been rejected.');
            this.state.on_transaction_error(err);
        }else{
            this.setState({ current_step: 'success' }, this.state.on_transaction_processed);
        }
    };

    render() {
        let disabled = false, content;
        if (this.state.opened) {
            let step = this.state.current_step,
                network = this.state.network,
                currency = this.state.currency,
                amount = this.state.amount;;
            content = <div id="beta-minting-modal-content" className="reglisse">
                <h3 style={{ margin: 0 }}>{"Mint"}</h3>
                {step === 'validate' && <div>
                    <h5>
                        <span>{'You are about to mint '}</span>
                        <span className='green-text'>{amount}</span>
                        <span className="theme-color">{' ' + currency.symbol}</span>
                        <span>{' on network ' + network}</span>
                    </h5>
                </div>}
                {step === 'wait_mint' && <div>
                    <h5>Waiting for minting transaction to start...</h5>
                </div>}
                {step === 'wait_mint_transaction' && <div>
                    <h5>Waiting for minting transaction to be processed...</h5>
                </div>}
                {step === 'success' && <div>
                    <h5 className="green-text">Minting has been processed successfully!</h5>
                    <h5>Balance might take a few seconds to refresh.</h5>
                </div>}
                {['wait_mint', 'wait_mint_transaction'].includes(step) && <div className="center-align">
                    <LoadingAnimation />
                </div>}
                {['validate', 'success'].includes(step) && <div className="modal-footer">
                    {step === 'validate' && <Button text={'Mint'} className='t18-btn' onClick={this.onMint} disabled={disabled} style={{ fontSize: "20px" }} />}
                    <Button text={step === 'success' ? "Close" : "Cancel"} className='t18-btn lm black' onClick={this.state.closeModal} large={true}
                        style={{ fontSize: "20px" }} />
                </div>}
            </div>;
        }
        return (
            <Modal id="beta-minting-modal"
                content={content}
                getOpen={this.getOpen}
                getClose={this.getClose}
                afterOpen={this.afterOpen}
                afterClose={this.afterClose}
                undismissible={true}
            />
        )
    }
};

import React  from 'react';
import _ from 'lodash';

import config from "../../config";

import TagSelect from './TagSelect';

export default class TagSelectPanel extends React.Component {

    state = { tag_select_opened: false };

    openTagSelect = () => {
        this.setState({ tag_select_opened: true })
    };

    closeTagSelect = () => {
        this.setState({ tag_select_opened: false })
    };

    render(){
        let mobile_display = config.MOBILE_DISPLAY,
            tag_select_opened = this.state.tag_select_opened,
            selected = this.props.selected;
        let style = {
            padding: '10px',
            zIndex: 100,
            verticalAlign: 'top',
            position: 'absolute',
            overflow: 'hidden',
            maxHeight: tag_select_opened ? '300px' : (mobile_display ? undefined : '150px'),
            backgroundColor: this.props.tagSelectColor || (tag_select_opened ? 'white' : "initial"),
            border: tag_select_opened ? '1px solid white' : undefined,
            borderBottomLeftRadius: '10px',
            borderBottomRightRadius: '10px',
            marginLeft: tag_select_opened ? '-1px' : undefined
        }
        if(this.props.noMaxHeight){
            delete style.maxHeight;
        }
        if(mobile_display){
            delete style.position;
            style.overflow = "auto";
        }
        return <div className={"inline-block transition-height" + (tag_select_opened ? ' opened z-depth-2' : '')}
            style={style}
            onMouseEnter={this.openTagSelect} onMouseLeave={this.closeTagSelect}>
            {tag_select_opened ? <TagSelect onTagClick={this.props.onTagClick} selected={selected} 
                closeBtn={mobile_display} onClose={this.closeTagSelect} /> : <div>
                <div>
                    <div className={'z-depth-1 clickable tag-badge'}>Select tags</div>
                    {_.map(selected, (tag) => {
                        return <div className={'z-depth-1 clickable tag-badge selected'}>{tag}</div>
                    })}
                </div>
            </div>}
        </div>
    }

}
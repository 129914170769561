import * as $ from'jquery';
import _ from 'lodash';

const API_KEY = '2cf513f5ebd9a9f86d06ef0c8f47a9c9';

let access_token;

function getAuthorization(options){
    if(access_token){
        return 'Bearer ' + access_token;
    }
    return 'API_KEY ' + API_KEY;
}

function requestAPI(options, successcb, errorcb, completecb) {

    if(!options.url)
        return;
    if(options.loading && window.showLoadingLayer){
        window.showLoadingLayer();
    }
    const headers = options.headers ? _.cloneDeep(options.headers) : {};
    headers.Authorization = getAuthorization(options);
    if(options.include_address_header){
        headers['Wallet-Address'] = options.include_address_header;
    }
    $.ajax({
        url: options.url,
        type: options.type ? options.type : "GET",
        contentType: options.data ? "application/json" : undefined,
        data: options.data ? JSON.stringify(options.data) : undefined,
        headers: headers,
        success: function(data, status, jqXHR) {
            if(successcb)
                successcb(data);
        },
        error: function(jqXHR, status, err) {
            console.log("error request");
            console.log(err);
            if(errorcb) {
                errorcb({ message: (jqXHR.responseJSON ? jqXHR.responseJSON.error : undefined), code: jqXHR.status });
            }
        },
        complete: function(jqXHR, status) {
            if(completecb)
                completecb();
            if(options.loading && window.hideLoadingLayer){
                window.hideLoadingLayer();
            }
        }
    });
}

function formatEntitiesName(entity_name){
    if(entity_name === 'currency'){
        return 'currencies';
    }
    return entity_name + 's';
}

const API = {

    requestAPI: requestAPI,

    setAccessToken: (token) => {
        access_token = token;
    },

    getEthPrice: (successcb, errorcb, completecb) => {
        let url = '/api/eth_price';
        requestAPI({type: 'GET', url: url}, successcb, errorcb, completecb);
    },

    signInWithCredentialsRequest: (username, password, successcb , errorcb) => {
        let url = '/api/signin';
        let data = {username: username, password: password};
        requestAPI({type: 'POST', url: url, data: data, loading: true}, successcb, errorcb);
    },

    signUpRequest: (data, successcb, errorcb) => {
        let url = '/api/signup';
        requestAPI({type: 'POST', url: url, data: data, loading: true}, successcb, errorcb);
    },

    getOtpSecretRequest: (address, successcb, errorcb) => {
        let url = '/api/request_otp';
        requestAPI({type: 'GET', url: url, loading: true, include_address_header: address}, successcb, errorcb);
    },

    otpSigninRequest: (address, secret, signed, successcb, errorcb) => {
        let url = '/api/otp_signin';
        requestAPI({type: 'POST', url: url, data: {secret, signed}, loading: true, include_address_header: address}, successcb, errorcb);
    },

    otpActionRequest: (address, action, successcb, errorcb) => {
        let url = '/api/request_otp/' + action;
        requestAPI({type: 'GET', url: url, loading: true, include_address_header: address, access_token: true}, successcb, errorcb);
    },

    associateUserWalletRequest: (address, secret, signed, successcb, errorcb) => {
        let url = '/api/user/current/associate_wallet';
        requestAPI({type: 'PUT', url: url, data: {secret, signed}, loading: true, include_address_header: address, access_token: true}, successcb, errorcb);
    },

    getLandsRequest: (options, successcb, errorcb) => {
        let url = '/api/lands', query = [];
        if(options.modified_since){
            query.push('modified_since=' + options.modified_since);
        }
        if(options.coordinates){
            let coordinates_list = [];
            for(let position of options.coordinates){
                coordinates_list.push(position.x);
                coordinates_list.push(position.y);
            }
            query.push('coordinates=' + coordinates_list.join(','));
        }
        if(options.without){
            query.push('without=' + options.without.join(','));
        }
        if(query.length > 0){
            url += '?' + query.join('&');
        }
        requestAPI({type: 'GET', url: url, loading: true}, successcb, errorcb);
    },

    getLandRequest: (id, successcb, errorcb) => {
        let url = '/api/lands/' + id;
        requestAPI({type: 'GET', url: url, loading: true}, successcb, errorcb);
    },

    getSceneRequest: (id, successcb, errorcb) => {
        let url = '/api/scenes/' + id;
        requestAPI({type: 'GET', url: url, loading: true}, successcb, errorcb);
    },

    updateLandRequest: (id, data, successcb, errorcb) => {
        let url = '/api/lands/' + id;
        requestAPI({type: 'PUT', url: url, data: data, loading: true, access_token: true}, successcb, errorcb);
    },

    getEntityRequest: (entity_name, id, successcb, errorcb, options = {}) => {
        let url = '/api/' + formatEntitiesName(entity_name) + '/' + id;
        requestAPI({type: 'GET', url: url, loading: !options.no_loading}, successcb, errorcb);
    },

    getEntitiesRequest: (entity_name, successcb, errorcb, options = {}) => {
        let url = '/api/' + formatEntitiesName(entity_name), query = [];
        if(options.modified_since){
            query.push('modified_since=' + options.modified_since);
        }
        if(options.without){
            query.push('without=' + options.without.join(','));
        }
        if(options.filters){
            let filters = []
            for(let prop in options.filters){
                filters.push(prop + ':' + options.filters[prop]);
            }
            query.push('filters=' + filters.join(','));
        }
        if(query.length > 0){
            url += '?' + query.join('&');
        }
        requestAPI({type: 'GET', url: url, loading: !options.no_loading}, successcb, errorcb);
    },

    getOwnedEntities: (entity_name, successcb, errorcb) => {
        let url = '/api/' + formatEntitiesName(entity_name) + '/owned';
        requestAPI({type: 'GET', url: url, loading: true, access_token: true}, successcb, errorcb);
    },

    createEntityRequest: (entity_name, data, successcb, errorcb) => {
        let url = '/api/' + formatEntitiesName(entity_name);
        requestAPI({type: 'POST', url: url, data: data, loading: true, access_token: true}, successcb, errorcb);
    },

    updateEntityRequest: (entity_name, id, data, successcb, errorcb) => {
        let url = '/api/' + formatEntitiesName(entity_name) + '/' + id;
        requestAPI({type: 'PUT', url: url, data: data, loading: true, access_token: true}, successcb, errorcb);
    },

    deleteEntityRequest: (entity_name, id, successcb, errorcb) => {
        let url = '/api/' + formatEntitiesName(entity_name) + '/' + id;
        requestAPI({type: 'DELETE', url: url, loading: true, access_token: true}, successcb, errorcb);
    },

    createMediaRequest: (data, successcb, errorcb) => {
        let url = '/api/medias';
        requestAPI({type: 'POST', url: url, data: data, loading: true, access_token: true}, successcb, errorcb);
    },

    updateMediaRequest: (id, data, successcb, errorcb) => {
        let url = '/api/medias/' + id;
        requestAPI({type: 'PUT', url: url, data: data, loading: true, access_token: true}, successcb, errorcb);
    },

    landBuyRequested: (id, tx_id, successcb, errorcb) => {
        let url = '/api/lands/' + id + '/buy_request';
        requestAPI({type: 'POST', url: url, data: {tx_id: tx_id}, loading: true, access_token: true}, successcb, errorcb);
    },

    getLandTemplates: (successcb, errorcb) => {
        let url = '/api/land_templates';
        requestAPI({type: 'GET', url: url, loading: true}, successcb, errorcb);
    },

    getSceneTemplates: (successcb, errorcb) => {
        let url = '/api/scene_templates';
        requestAPI({type: 'GET', url: url, loading: true}, successcb, errorcb);
    },

    initializeLand: (land_id, data, successcb, errorcb) => {
        let url = '/api/lands/' + land_id + '/initialize';
        requestAPI({type: 'PUT', url: url, data: data, loading: true, access_token: true}, successcb, errorcb);
    },

    updateLandTemplate: (land_id, template_id, data, successcb, errorcb) => {
        let url = '/api/lands/' + land_id + '/apply/template/' + template_id;
        requestAPI({type: 'PUT', url: url, data: data, loading: true, access_token: true}, successcb, errorcb);
    },

    updateSceneTemplate: (scene_id, template_id, data, successcb, errorcb) => {
        let url = '/api/scenes/' + scene_id + '/apply/template/' + template_id;
        requestAPI({type: 'PUT', url: url, data: data, loading: true, access_token: true}, successcb, errorcb);
    },

    addSceneFloor: (scene_id, template_id, data, successcb, errorcb) => {
        let url = '/api/scenes/' + scene_id + '/add_floor/template/' + template_id;
        requestAPI({type: 'PUT', url: url, data: data, loading: true, access_token: true}, successcb, errorcb);
    },

    deleteScene: (scene_id, successcb, errorcb) => {
        let url = '/api/scenes/' + scene_id;
        requestAPI({type: 'DELETE', url: url, loading: true, access_token: true}, successcb, errorcb);
    },

    updateAdminLandType: (id, type, successcb, errorcb) => {
        let url = '/api/lands/' + id + '/type';
        requestAPI({type: 'PUT', url: url, data: {type: type}, loading: true, access_token: true}, successcb, errorcb);
    },

    updateAdminLandTemplate: (id, data, successcb, errorcb) => {
        let url = '/api/lands/' + id + '/template';
        requestAPI({type: 'PUT', url: url, data: data, loading: true, access_token: true}, successcb, errorcb);
    },

    mediaUploadRequest: (data, successcb, errorcb) => {
        let url = '/api/medias/upload';
        requestAPI({type: 'POST', url: url, data: data, loading: true, access_token: true}, successcb, errorcb);
    },

    mediaUploadCompleteRequest: (media_id, successcb, errorcb) => {
        let url = '/api/medias/' + media_id + '/upload/complete';
        requestAPI({type: 'PUT', url: url, loading: true, access_token: true}, successcb, errorcb);
    },

    getContentCreatorsRequest: (successcb, errorcb) => {
        let url = '/api/users/content_creators';
        requestAPI({type: 'GET', url: url, loading: true}, successcb, errorcb);
    },

    getCurrentUserRequest: (successcb, errorcb) => {
        let url = '/api/user/current';
        requestAPI({type: 'GET', url: url, loading: true, access_token: true}, successcb, errorcb);
    },

    updateUserRequest: (data, successcb, errorcb) => {
        let url = '/api/user/current';
        requestAPI({type: 'PUT', url: url, data: data, loading: true, access_token: true}, successcb, errorcb);
    },

    changePasswordRequest: (data, successcb, errorcb) => {
        let url = '/api/user/current/password';
        requestAPI({type: 'PUT', url: url, data: data, loading: true, access_token: true}, successcb, errorcb);
    },

    addMediaFolderRequest: (data, successcb, errorcb) => {
        let url = '/api/user/current/media_folders';
        requestAPI({type: 'POST', url: url, data: data, loading: true, access_token: true}, successcb, errorcb);
    },

    updateUserAcceptedCurrenciesRequest: (data, successcb, errorcb) => {
        let url = '/api/user/current/accepted_currencies';
        requestAPI({type: 'PUT', url: url, data: data, loading: true, access_token: true}, successcb, errorcb);
    },

    editEntityTarget: (target_entity_type, target_entity_id, entity_type, target_index, data, successcb, errorcb) => {
        let url = '/api/' + target_entity_type + 's/' + target_entity_id + '/edit/' + entity_type + '/' + target_index;
        requestAPI({type: 'PUT', url: url, data: data, loading: true, access_token: true}, successcb, errorcb);
    },

    getWalletBalances: (successcb, errorcb) => {
        let url = '/api/wallet/balance';
        requestAPI({type: 'GET', url: url, loading: true, access_token: true}, successcb, errorcb);
    },

    getPaymentData: (request_data, successcb, errorcb, completecb) => {
        let queryParams = [];
        for(let prop in request_data){
            queryParams.push(prop + '=' + request_data[prop]);
        }
        let url = '/api/pay_data/?' + queryParams.join('&');
        requestAPI({type: 'GET', url: url, loading: true, access_token: true}, successcb, errorcb, completecb);
    },

    verifySubscriptionPaymentData: (subscription_id, network, successcb, errorcb, completecb) => {
        let url = '/api/pay_data/verify/subscription/' + subscription_id + '/network/' + network;
        requestAPI({type: 'GET', url: url, loading: true, access_token: true}, successcb, errorcb, completecb);
    },

    buySubscription: (network, target, subscription_id, unit_count, currency_chain_id, successcb, errorcb, completecb) => {
        let url = '/api/subscriptions/' + subscription_id + '/buy';
        let data = {network: network, target: target, unit_count: unit_count, currency_chain_id: currency_chain_id};
        requestAPI({type: 'PUT', url: url, data: data, loading: false, access_token: true}, successcb, errorcb, completecb);
    },

    extendSubscription: (token_id, unit_count, currency_chain_id, successcb, errorcb, completecb) => {
        let url = '/api/subscriptions/' + token_id + '/extend';
        let data = {unit_count: unit_count, currency_chain_id: currency_chain_id};
        requestAPI({type: 'PUT', url: url, data: data, loading: false, access_token: true}, successcb, errorcb, completecb);
    },

};

export default API;
import React from 'react';
import { parseUnits } from 'ethers';

import Modal from "../global/Modal";
import Button from "../global/Button";

import LoadingAnimation from "../global/LoadingAnimation";

import generic from "../../model/generic";
import Subscription from "../../model/subscription";
import utils from "../../lib/utils";
import web3 from '../../lib/web3';

export default class PaySubscriptionModal extends React.Component {

    state = { opened: false, openModal: null, closeModal: null, current_step: 'validate' };

    componentDidMount() {
        window.openPaySubscriptionModal = this.openModal;
    }

    getOpen = (open) => {
        this.setState({ openModal: open });
    };

    openModal = (options = {}, close_cb = function () { }) => {

        let state = {
            opened: true,
            current_step: 'validate',
            action: options.action,
            subscription_id: options.subscription_id,
            target_address: options.target_address,
            token_id: options.token_id,
            network: options.network,
            unit_count: options.unit_count,
            currency: options.currency,
            processing_error: false,
            on_transaction_processed: options.on_transaction_processed || function () { },
            on_transaction_error: options.on_transaction_error || function () { },
            undismissible: true,
            close_cb: close_cb
        };
        Subscription.verifySubscriptionPaymentData(state.subscription_id, state.network, (err, data) => {
            if (err) {
                state.processing_error = true;
            } else {
                let subscription = generic.getEntity('subscription', state.subscription_id);
                subscription.price = data.price;
            }
            this.setState(state, this.state.openModal);
        });
    };

    getClose = (close) => {
        this.setState({ closeModal: close });
    };

    afterOpen = () => {
        //$(".lean-overlay").last().addClass("alert-popup-overlay");
    };

    afterClose = () => {
        this.setState({ opened: false }, this.state.close_cb);
    };

    onPay = () => {
        this.setState({ current_step: 'wait_approval' });
        let currency = this.state.currency,
            subscription = generic.getEntity('subscription', this.state.subscription_id),
            price = this.state.unit_count * utils.getPriceForCurrency(currency, subscription.price),
            contract_value = parseUnits(price + "", currency.decimals || 18);
        web3.approveSubscriptionBuy(currency, contract_value, this.onApproveTransactionSent, this.onApproveTransactionProcessed);
    };

    onResetAllowance = () => {
        this.setState({ current_step: 'wait_allowance_reset' });
        let currency = this.state.currency;
        web3.approve(currency, 0, this.onAllowanceResetTransactionSent, this.onAllowanceResetTransactionProcessed);
    };

    onAllowanceResetTransactionSent = (err, tx_id) => {
        if (err) {
            this.state.closeModal();
            console.log('onAllowanceResetTransactionSent error');
            console.log(err);
            window.alertPopup('Error', 'An error has occurred while sending allowance reset transaction.');
            this.state.on_transaction_error(err);
        } else {
            this.setState({ current_step: 'wait_allowance_reset_transaction' });
        }
    };

    onAllowanceResetTransactionProcessed = (err) => {
        if (err) {
            this.state.closeModal();
            console.log('onAllowanceResetTransactionProcessed error');
            console.log(err);
            window.alertPopup('Error', 'An error has occurred while processing allowance reset transaction.');
            this.state.on_transaction_error(err);
        } else {
            this.setState({ current_step: 'validate' });
        }
    };

    onApproveTransactionSent = (err, tx_id) => {
        if (err === 'need-allowance-reset') {
            this.setState({ current_step: 'allowance_reset' });
        } else if (err) {
            this.state.closeModal();
            console.log('onApproveTransactionSent error');
            console.log(err);
            window.alertPopup('Error', 'An error has occurred while sending approve transaction.');
            this.state.on_transaction_error(err);
        } else {
            this.setState({ current_step: 'wait_approval_transaction' });
        }
    };

    onApproveTransactionProcessed = (err) => {
        if (err) {
            this.state.closeModal();
            console.log('onApproveTransactionProcessed error');
            console.log(err);
            window.alertPopup('Error', 'An error has occurred while processing approve transaction.');
            this.state.on_transaction_error(err);
        } else {
            this.setState({ current_step: 'wait_payment' });
            if (this.state.action === 'extend') {
                Subscription.extendSubscription(this.state.token_id, this.state.currency.chain_id, this.state.unit_count, this.onBuyRequestProcessed);
            } else {
                Subscription.buySubscription(this.state.subscription_id, this.state.target_address, this.state.network, this.state.currency.chain_id, this.state.unit_count, this.onBuyRequestProcessed);
            }
        }
    };

    onBuyRequestProcessed = (err, tx_id) => {
        if (err) {
            this.state.closeModal();
            console.log('onBuyRequestProcessed error');
            console.log(err);
            window.alertPopup('Error', 'An error has occurred while processing subscription buy transaction.');
            this.state.on_transaction_error(err);
        } else {
            web3.awaitTransaction(tx_id, this.onTransactionProcessed);
        }
    };

    onTransactionProcessed = (err) => {
        if (err) {
            this.state.closeModal();
            console.log('onTransactionProcessed error');
            console.log(err);
            window.alertPopup('Error', 'Transaction has been rejected.');
            this.state.on_transaction_error(err);
        } else {
            this.setState({ current_step: 'success' }, this.state.on_transaction_processed);
        }
    };

    render() {
        let disabled = false, content;
        if (this.state.opened) {
            if (this.state.processing_error) {
                content = <div id="pay-subscription-modal-content" className="reglisse">
                    <h3 style={{ margin: 0 }}>{"Pay Subscription"}</h3>
                    <h5>This subscription is currently undergoing modifications on the blockchain, try again later.</h5>
                    <div className="modal-footer">
                        <Button text={"Close"} className='t18-btn lm black' onClick={this.state.closeModal} large={true}
                            style={{ fontSize: "20px" }} />
                    </div>
                </div>;
            } else {
                let step = this.state.current_step,
                    network = this.state.network,
                    currency = this.state.currency,
                    subscription = generic.getEntity('subscription', this.state.subscription_id);
                let price_for_currency = this.state.unit_count * utils.getPriceForCurrency(currency, subscription.price);
                content = <div id="pay-subscription-modal-content" className="reglisse">
                    <h3 style={{ margin: 0 }}>{"Pay Subscription"}</h3>
                    {step === 'validate' && <div>
                        <h5>
                            <span>{'You are about to pay '}</span>
                            <span className='green-text'>{price_for_currency}</span>
                            <span className="theme-color">{' ' + currency.symbol}</span>
                            <span>{' on network ' + utils.getNetworkDisplayName(network)}</span>
                        </h5>
                    </div>}
                    {step === 'allowance_reset' && <div>
                        <h5>Allowance needs to be reset before proceeding with the transaction.</h5>
                    </div>}
                    {step === 'wait_allowance_reset' && <div>
                        <h5>Waiting for allowance reset...</h5>
                    </div>}
                    {step === 'wait_allowance_reset_transaction' && <div>
                        <h5>Waiting for allowance reset transaction to be processed...</h5>
                    </div>}
                    {step === 'wait_approval' && <div>
                        <h5>Waiting for approval...</h5>
                    </div>}
                    {step === 'wait_approval_transaction' && <div>
                        <h5>Waiting for approval transaction to be processed...</h5>
                    </div>}
                    {step === 'wait_payment' && <div>
                        <h5>Waiting for payment processing...</h5>
                    </div>}
                    {step === 'success' && <div>
                        <h5 className="green-text">Subscription has been paid successfully</h5>
                        <h5>Medias might take a few seconds to unlock.</h5>
                    </div>}
                    {['wait_approval', 'wait_approval_transaction', 'wait_payment', 'wait_allowance_reset', 'wait_allowance_reset_transaction'].includes(step) && <div className="center-align">
                        <LoadingAnimation />
                    </div>}
                    {['validate', 'success', 'allowance_reset'].includes(step) && <div className="modal-footer">
                        {step === 'validate' && <Button text={'Pay'} className='t18-btn' onClick={this.onPay} disabled={disabled} style={{ fontSize: "20px" }} />}
                        {step === 'allowance_reset' && <Button text={'Reset allowance'} className='t18-btn' onClick={this.onResetAllowance} style={{ fontSize: "20px" }} />}
                        <Button text={step === 'success' ? "Close" : "Cancel"} className='t18-btn lm black' onClick={this.state.closeModal} large={true}
                            style={{ fontSize: "20px" }} />
                    </div>}
                </div>;
            }
        }
        return (
            <Modal id="pay-subscription-modal"
                content={content}
                getOpen={this.getOpen}
                getClose={this.getClose}
                afterOpen={this.afterOpen}
                afterClose={this.afterClose}
                undismissible={true}
            />
        )
    }
};

import React from "react";
import moment from "moment";

import config from "../../../../shared/config";

import User from "../../../../shared/model/user";

import ProfilePanel from '../../../../shared/components/profile/ProfilePanel';
import MobileProfileView from './MobileProfileView';

export default class extends React.Component {

    state = {};

    refresh = () => {
        this.forceUpdate();
    }

    render() {
        if (!User.isAuthenticated()) {
            return null;
        }else if(config.MOBILE_DISPLAY){
            return <MobileProfileView show={this.props.show} />
        }
        return <div id="profile-view" className={"app-view white-text row" + (this.props.show ? '' : ' hide')} >
            <ProfilePanel show={this.props.show} onReturn={this.props.onReturn} showSubscriptions={true} />
        </div>

    }

}
import React  from 'react';

import Modal from "../../../shared/components/global/Modal";
import Button from "../../../shared/components/global/Button";

export default class WelcomeModal extends React.Component {

    state = { opened: false, openModal: null, closeModal: null, name: "" };

    componentDidMount() {
        let dismissed = localStorage.getItem("welcome_dismissed");
        if(!dismissed){
            this.openModal();
            localStorage.setItem("welcome_dismissed", true);
        }
    }

    getOpen = (open) => {
        this.setState({ openModal: open }, () => {
            if(this.state.opened){
                this.state.openModal();
            }
        });
    };

    openModal = () => {
        let state = { opened: true };
        this.setState(state, this.state.openModal);
    };

    getClose = (close) => {
        this.setState({ closeModal: close });
    };

    afterOpen = () => {
        //$(".lean-overlay").last().addClass("alert-popup-overlay");
    };

    afterClose = () => {
        this.setState({opened: false}, this.state.close_cb);
    };

    render() {
        if(!this.state.opened){
            return null;
        }
        const content = <div id="welcome-modal-content" className="reglisse">
            <h3 className="center-align" style={{margin: 0}}>Welcome in Only Terminal18 Beta</h3>
            <p className="bold">
                Welcome into the beta version of Only Terminal18. <br/>
                We are still in the process of building the platform and we are excited to have you here. <br/>
                We are working hard to make the platform better and we would love to hear your feedback. <br/>
                Please feel free to reach out to us in our <a href="https://discord.gg/br6kgW7t">Discord server</a> !
            </p>
            <h5>Beta mode</h5>
            <p>
                For the duration of the beta, the content creator inscription process is simplified and available to everyone.<br/>
                The goal of the beta being to test the platform and its monetization system.<br/>
                For now, all transactions are taking place on test networks and no real money is involved.<br/>
                We have set up some minting features to allow you to test the monetization system.
            </p>
            <h5>As a user</h5>
            <p>
                As a user, you will be able to browse, search and display all medias available on the platform. <br/>
                You will also be able to buy content creators subscriptions to display their hidden content.
            </p>
            <h5>As a content creator</h5>
            <p>
                As a content creator, you will be able to upload and manage your medias. <br/>
                You will also be able to create and manage your subscription plan for your fans to access your hidden content.
            </p>
            <div className="modal-footer">
                <Button text={"Close"} className='t18-btn lm black' onClick={this.state.closeModal} large={true}
                        style={{fontSize: "20px"}}/>
            </div>
        </div>;
        return (
            <Modal id="welcome-modal"
                   content={content}
                   getOpen={this.getOpen}
                   getClose={this.getClose}
                   afterOpen={this.afterOpen}
                   afterClose={this.afterClose}
                   undismissible={true}
            />
        )
    }
};

import React from "react";
import web3 from "../../../shared/lib/web3";
import ReactTooltip from "react-tooltip";
import M from "materialize-css";

import config from "../../../shared/config";
import model from '../model/model';
import User from '../../../shared/model/user';

import Button from "../../../shared/components/global/Button";
import Title from "../../../shared/components/global/Title";

import LoadingLayer from "../../../shared/components/LoadingLayer";
import InitialLoading from "../../../shared/components/InitialLoading";

import Navbar from "../../../shared/components/Navbar";
import MobileNavbar from "../../../shared/components/MobileNavbar";
import ExploreMediaView from "./ExploreMediaView/ExploreMediaView";
import CreatorView from "./CreatorView/CreatorView";
import CreatorPage from "./CreatorView/CreatorPage";
// import ManagerView from "./ManagerView/ManagerView";
import MediaView from "../../../shared/components/manager/MediaView/MediaView";
import ProfileView from "./ProfileView/ProfileView";

import AlertPopup from "../../../shared/components/modal/AlertPopup";
import ConfirmActionModal from "../../../shared/components/modal/ConfirmActionModal";
import NameForm from "../../../shared/components/modal/NameForm";
import UserPasswordForm from "../../../shared/components/modal/UserPasswordForm";
import GenericForm from "../../../shared/components/modal/GenericForm";
import LoginModal from "../../../shared/components/modal/LoginModal";
import SignUpModal from "../../../shared/components/modal/SignUpModal";
import ConnectWalletModal from "../../../shared/components/modal/ConnectWalletModal";
import WalletAssociateModal from "../../../shared/components/modal/WalletAssociateModal";
import MediaDisplayModal from "../../../shared/components/modal/MediaDisplayModal";
import PaySubscriptionModal from "../../../shared/components/modal/PaySubscriptionModal";
import SubcriptionForm from "../../../shared/components/modal/SubscriptionForm";
import MediaUploadModal from "../../../shared/components/modal/MediaUploadModal";
import MediaDetailsModal from "../../../shared/components/modal/MediaDetailsModal";
import MediaForm from "../../../shared/components/modal/MediaForm";
import MediaFolderSelectModal from "../../../shared/components/modal/MediaFolderSelectModal";
import DisplayMediaAddModal from "../../../shared/components/modal/DisplayMediaAddModal";
import UserCurrenciesForm from "../../../shared/components/modal/UserCurrenciesForm";
import BetaMintingModal from "../../../shared/components/modal/BetaMintingModal";
import ProfilePictureSelectModal from "../../../shared/components/modal/ProfilePictureSelectModal";
import WelcomeModal from "./WelcomeModal";

import banner_url from "../../../shared/images/banner_t18.png";

window.onAppViewSelect = null;

window.refreshApp = () => { };

window.toast = (text) => {
    M.Toast.dismissAll();
    M.toast({ html: text });
}

export default class extends React.Component {

    state = { age_verified: false };

    componentDidMount = () => {
        let age_verified = sessionStorage.getItem("age_verified");
        if (age_verified) {
            this.setState({ age_verified: true });
        }
    }

    onVerifyAge = () => {
        this.setState({ age_verified: true });
        sessionStorage.setItem("age_verified", "true");
    }

    render() {
        return this.state.age_verified ? <MainApp /> : <VerifyAgePage onVerifyAge={this.onVerifyAge} />;
    }

}

class MainApp extends React.Component {

    state = {
        selectedView: 'explore', app_loaded: false
    };

    onViewSelect = (view, cb = () => { }) => {
        console.log("onViewSelect", view);
        if (typeof cb !== 'function') {
            cb = () => { };
        }
        if (view !== this.state.selectedView) {
            if (view === 'profile' && !User.isAuthenticated()) {
                return this.onSigninClick();
            }
            this.setState({ selectedView: view }, cb);
        } else if (view === "explore") {
            this.setState({ selected_user_id: null }, () => {
                window.explore_media_views['main-explore'].onMediaSelect(null, undefined, cb);
            });
        } else {
            cb();
        }
    };

    onSigninClick = () => {
        window.openLoginModal({ on_signin_cb: model.afterSignin });
    };

    onSearchClick = () => {
        if (this.state.selectedView !== 'explore') {
            this.onViewSelect('explore', () => {
                let filter_tab = window.filter_tabs['main-explore-grid-browser-filter'];
                filter_tab.onExpandToggle();
            });
        } else {
            let filter_tab = window.filter_tabs['main-explore-grid-browser-filter'];
            filter_tab.onExpandToggle();
        }
    }

    onMediaSelect = (media_id, index) => {
        let previous_media_index = this.state.selected_media_index || 0;
        this.setState({ selected_media_id: media_id, selected_media_index: index }, () => {
            if (!media_id) {
                console.log('Scroll to index: ' + previous_media_index);
                let grid = window.grid_display['main-explore-grid-browser-grid'];
                grid.scrollToItemFromIndex(previous_media_index);
                // setTimeout(() => {
                //     grid.scrollToItemFromIndex(previous_media_index);
                // }, 300);
            }
        });
    }

    componentDidMount = () => {
        window.onAppViewSelect = this.onViewSelect;
        window.refreshApp = this.refresh;
        web3.onWalletUpdateSubscribe(this.refresh);
        web3.initialize(() => {
            User.initialize(() => {
                model.initialize(() => {
                    this.setState({ app_loaded: true });
                });
            });
        });
        setInterval(() => {
            try {
                ReactTooltip.rebuild();
            } catch (e) {
                console.error("Tooltip rebuild error")
            }
        }, 1000);
        window.showUser = (user_id) => {
            console.log('showUser', user_id)
            this.setState({ selected_user_id: user_id })
        }
    };

    hideCreatorPage = () => {
        console.log('hideCreatorPage');
        this.setState({ selected_user_id: null }, () => {
            if(!this.state.selected_media_id){
                this.onViewSelect('explore');
            }
        });
    }

    refresh = () => {
        console.log('RefreshApp');
        this.forceUpdate();
    };

    getMenus = () => {
        let mobile_display = config.MOBILE_DISPLAY,
            menus = [
                { key: 'explore', display: 'Explore', icon: 'smart_display', onClick: this.onViewSelect.bind(null, 'explore') }
            ];
        if (mobile_display) {
            menus.push({ key: 'search', icon: 'search', onClick: this.onSearchClick });
            menus.push({ key: 'profile', icon: 'person', onClick: this.onViewSelect.bind(null, 'profile') });
        } else {
            if (User.isAuthenticated() && User.isContentCreator()) {
                menus.push({ key: 'manager', display: 'My Medias', onClick: this.onViewSelect.bind(null, 'manager') });
            }
        }
        return menus;
    };

    render() {
        if (!this.state.app_loaded) {
            return <InitialLoading />
        }
        let selected = this.state.selectedView,
            selected_user_id = this.state.selected_user_id,
            mobile_display = config.MOBILE_DISPLAY,
            menus = this.getMenus();
        return <div id="app-container" className={config.MOBILE_DISPLAY ? "mobile-display" : ""}>
            <Navbar title={mobile_display ? "" : "Only Terminal18"}
                userDisplay={!mobile_display}
                logo={banner_url}
                logoStyle={mobile_display ? { width: '100%' } : undefined}
                menus={mobile_display ? [] : menus}
                selectedMenu={selected}
                onViewSelect={this.onViewSelect}
                onSignin={this.onSigninClick} />
            {mobile_display && <MobileNavbar show={true} selectedMenu={selected} menus={menus} />}
            <ExploreMediaView id="main-explore" className="app-view" show={selected === 'explore' && !selected_user_id} showUser={true} onMediaSelect={this.onMediaSelect} />
            {/* <CreatorView show={selected === 'creator'} /> */}
            {selected_user_id && <CreatorPage userId={selected_user_id} onReturn={this.hideCreatorPage} />}
            {User.isAuthenticated() && User.isContentCreator() && !mobile_display && <MediaView show={selected === 'manager'} />}
            <ProfileView show={selected === 'profile'} />
            <ReactTooltip id={"application-tooltip"} className={"tool-tip z-depth-1"} effect={"solid"} backgroundColor="white" textColor="black" />
            <LoadingLayer />
            <AlertPopup />
            <ConfirmActionModal />
            <GenericForm />
            <NameForm />
            <UserPasswordForm />
            <LoginModal />
            <SignUpModal />
            <ConnectWalletModal />
            <WalletAssociateModal />
            <MediaDisplayModal />
            <PaySubscriptionModal />
            <SubcriptionForm />
            <MediaUploadModal />
            <MediaDetailsModal />
            <MediaForm />
            <MediaFolderSelectModal />
            <DisplayMediaAddModal />
            <UserCurrenciesForm />
            <BetaMintingModal />
            <ProfilePictureSelectModal />
            <WelcomeModal />
        </div>
    }
}


class VerifyAgePage extends React.Component {

    onExit = () => {
        window.close();
    }

    render() {
        let mobile_display = config.MOBILE_DISPLAY;
        return <div id="verify-age-page" className="white-text" style={{
            height: '100%',
            backgroundColor: "#161823",
            paddingTop: '10%',
            paddingLeft: "20px",
            paddingRight: "20px",
        }}>
            <div className="center-align" style={{ height: "80px" }}>
                <img src={banner_url} style={config.MOBILE_DISPLAY ? { width: '80%' } : { height: '100%' }} />
            </div>
            <div className="tm">
                <Title text="Age verification" size="3" />
            </div>
            <div className="tm">
                <Title text="Terminal18 is an adult community that contains age-restricted content." />
                <Title text="You must be 18 years old or over to enter." />
            </div>
            <div className="center-align tm" >
                {!mobile_display && <div className="clickable z-depth-1"
                    onClick={this.onExit}
                    style={{ display: 'inline-block', backgroundColor: 'grey', color: 'black', padding: '10px 20px', borderRadius: "3px", width: '160px' }}>
                    <div>No</div>
                    <div>I am under 18</div>
                </div>}
                <div className="clickable z-depth-1"
                    onClick={this.props.onVerifyAge}
                    style={{ display: 'inline-block', backgroundColor: '#f51982', padding: '10px 20px', marginLeft: mobile_display ? undefined : "20px", borderRadius: "3px", width: '160px' }}>
                    <div>Yes</div>
                    <div>I am 18 or older</div>
                </div>
            </div>
            {mobile_display && <div className="center-align tm" >
                <div className="clickable z-depth-1"
                    onClick={this.onExit}
                    style={{ display: 'inline-block', backgroundColor: 'grey', color: 'black', padding: '10px 20px', borderRadius: "3px", width: '160px' }}>
                    <div>No</div>
                    <div>I am under 18</div>
                </div>
            </div>}
        </div>
    }
}

// I would gladly discuss such opportunities in the web3 
import React from "react";

import config from "../../config";
import generic from "../../model/generic";

import ToolTipped from "../global/ToolTipped";

let default_profile_picture = "https://terminal18.b-cdn.net/misc/default_profile_picture.png";

export default class extends React.Component {

    state = {show_edit_icon: false};

    componentDidMount = () => {
        if(this.props.selfEditAction){
            this.setState({show_edit_icon: true});
        }
        this.checkPictureLoading();
    }

    componentDidUpdate = (prevProps) => {
        if(prevProps.user_id !== this.props.user_id){
            this.checkPictureLoading();
        }
    }

    refresh = () => {
        this.forceUpdate();
    }

    checkPictureLoading = () => {
        let user = generic.getEntity('user', this.props.user_id);
        if(user && user.picture_id){
            let picture = generic.getEntity('media', user.picture_id);
            if(!picture){
                generic.refreshEntity('media', user.picture_id, this.refresh);
            }
        }
    }

    renderProcessing = () => {
        let style = {display: 'inline', fontSize: "20px", position: 'relative', right: "100%", top: "10px"};
        if(this.props.style){
            Object.assign(style, this.props.style);
        }
        return <div style={style}>
            <div className={"green-text z-depth-1"} style={{padding: '10px', borderRadius: "5px"}}>Processing picture ...</div>
        </div>
    }

    onEdit = () => {
        window.openProfilePictureSelectModal({});
    }

    onOpen = () => {
        if(window.showUser){
            window.showUser(this.props.user_id);
        }
    }

    renderRedirectAction = () => {
        if(!this.props.redirectAction){
            return;
        }
        if(config.MOBILE_DISPLAY){
            return <i className="material-icons clickable z-depth-1 profile-action-icon" onClick={this.onOpen} >open_in_new</i>;
        }else{
            return <ToolTipped content={<i className="material-icons clickable z-depth-1 profile-action-icon" onClick={this.onOpen} >open_in_new</i>}
                               tooltip={"Open profile"} position={"bottom"} className="profile-action-icon-tooltip"/>
        }
    }

    render() {
        let user = generic.getEntity('user', this.props.user_id),
            picture_url = default_profile_picture;
        if(user && user.picture_id){
            let picture = generic.getEntity('media', user.picture_id);
            if(picture && picture.processing){
                return this.renderProcessing();
            }else if(picture && picture.url){
                picture_url = picture.url;
            }
        }
        let style = {display: 'inline-block'};
        if(this.props.style){
            Object.assign(style, this.props.style);
        }
        let className = "profile-picture";
        if(this.props.redirectAction){
            className += " pointer";
        }
        let img_style = {
            maxHeight: "100%",
            maxWidth: "100%",
            borderRadius: '10px',
            overflow: "hidden"
        };
        if(this.props.imgStyle){
            Object.assign(img_style, this.props.imgStyle);
        }
        return (
            <div className={className} style={style} onClick={this.props.redirectAction ? this.onOpen : undefined}>
                {this.state.show_edit_icon && <i className="material-icons clickable z-depth-1 profile-action-icon" onClick={this.onEdit} >edit</i>}
                {this.renderRedirectAction()}
                {false && this.props.redirectAction && <i className="material-icons clickable z-depth-1 profile-action-icon" onClick={this.onOpen} >open_in_new</i>}
                <img src={picture_url} alt="Profile" style={img_style}/>
            </div>
        );
    }

}
import React from "react";
import _ from 'lodash';
import utils from '../../../lib/utils';

import config from '../../../config';

import User from '../../../model/user';
import generic from '../../../model/generic';

import IconButton from "../../global/IconButton";
import MediaSelector from "../../media/MediaSelector";

export default class extends React.Component {

    state = { selected: [], path: '/' };

    isCtrlPressed = false;

    componentDidMount() {
        generic.addEntityListener('media', this.refresh);
        // debounced refresh on window resize
        window.addEventListener('resize', _.debounce(this.refresh, 500));
        document.addEventListener('keydown', (event) => {
            if (event.ctrlKey) {
                this.isCtrlPressed = true;
            }
        });
        document.addEventListener('keyup', (event) => {
            if (event.ctrlKey) {
                this.isCtrlPressed = false;
            }
        });
    }

    onMediaSelectOne = (media_id) => {
        if (this.isCtrlPressed) {
            this.onMediaSelect(media_id);
        } else {
            this.setState({ selected: [media_id] });
        }
    };

    onMediaSelect = (media_id) => {
        let selected = this.state.selected.slice(), index = selected.indexOf(media_id);
        if (index > -1) {
            selected.splice(index, 1);
        } else {
            selected.push(media_id);
        }
        this.setState({ selected: selected });
    };

    onUploadMedia = () => {
        window.openMediaUploadModal({ path: this.state.path });
    };

    onToggleMediaVisibility = (media_id) => {
        let media = generic.getEntity('media', media_id);
        let update = media.visibility === 'private' ? { visibility: 'public' } : { visibility: 'private' };
        generic.updateEntity('media', media_id, update, (err, res) => {
            if (err) {
                console.log(err);
                window.alertPopup('Error', 'An error has occurred while updating media visibility.');
            } else if (window.refreshAp) {
                window.refreshApp();
            } else {
                this.refresh();
                let text = update.visibility === 'public' ? 'Media visibility set to public.' : 'Media visibility set to private.';
                window.toast(text);
            }
        });
    };

    onToggleMediaPaidAccess = (media_id) => {
        let subscription = User.getUserSubscription(),
            media_id_list = (subscription.media_id_list || []).slice();
        let index = media_id_list.indexOf(media_id);
        if (index > -1) {
            media_id_list.splice(index, 1);
        } else {
            media_id_list.push(media_id);
        }
        generic.updateEntity('subscription', subscription.id, { media_id_list: media_id_list }, (err, res) => {
            if (err) {
                window.alertPopup('Error', 'An error has occurred while updating media access.');
            } else {
                generic.refreshEntity('media', media_id, this.refresh, { no_loading: true });
                let text = index > -1 ? 'Media access set to free.' : 'Media access added to subscription plan.';
                window.toast(text);
            }
        });
    };

    onShowMedia = (media_id) => {
        window.openMediaDisplayModal({ media_id: media_id });
    };

    onMediaDetails = (media_id) => {
        window.openMediaDetailsModal({ media_id: media_id });
    }

    onEditMedia = (media_id) => {
        window.openMediaForm({ action: 'edit', target_id: media_id });
    };

    onDeleteMedia = (media_id) => {
        let selected = this.state.selected,
            media_list = [];
        if (selected.length > 0 && selected.indexOf(media_id) > -1) {
            for (let media_id of selected) {
                media_list.push(generic.getEntity('media', media_id));
            }
        } else {
            media_list.push(generic.getEntity('media', media_id));
        }
        let media_names_display = media_list.map((media) => {
            return <div>{" - " + media.name}</div>
        });
        window.openConfirmActionModal({
            action_text: <div>
                <div>{"Do you really want to delete the following media ?"}</div>
                {media_names_display}
            </div>,
            confirm_cb: () => {
                if (selected.length > 1) {
                    let error = false;
                    utils.asyncMap(selected, (media_id, next) => {
                        generic.deleteEntity("media", media_id, (err) => {
                            if (err) {
                                window.alertPopup('Error', 'An error has occurred while deleting media.');
                                error = true;
                            } 
                            next();
                        });
                    }, () => {
                        generic.loadOwnedEntities('subscription');
                        if(!error){
                            window.toast('Media deleted successfully.');
                        }
                    });
                } else {
                    generic.deleteEntity("media", media_id, (err) => {
                        if (err) {
                            window.alertPopup('Error', 'An error has occurred while deleting the media.');
                        } else {
                            generic.loadOwnedEntities('subscription');
                            window.toast('Media deleted successfully.');
                        }
                    });
                }
            }
        });
    };

    onAddFolder = () => {
        window.openNameForm({
            title: 'Add folder', on_submit: (name) => {
                User.addMediaFolder({ parent_path: this.state.path, name: name }, (err, res) => {
                    if (err) {
                        console.log(err);
                        window.alertPopup('Error', 'An error has occurred while creating new folder.');
                    } else {
                        this.refresh();
                        window.toast('Folder created successfully.');
                    }
                });
            }
        })
    }

    onFolderSelect = (path) => {
        this.setState({ path: path, selected: [] });
    }

    onFolderReturn = () => {
        let path = this.state.path;
        if (path !== '/') {
            path = path.substring(0, path.lastIndexOf('/'));
            if (path === '') path = '/';
            this.onFolderSelect(path);
        }
    }

    onMoveMedia = (media_id) => {
        window.openMediaFolderSelectModal({
            path: this.state.path, on_submit: (target_path) => {
                let selected = this.state.selected;
                if (selected.length > 1) {
                    let error = false;
                    utils.asyncMap(selected, (media_id, next) => {
                        this.moveMediaToFolder(media_id, target_path, (err) => {
                            if (err) {
                                error = true;
                            }
                            next();
                        });
                    }, () => {
                        if(!error){
                            window.toast('Medias moved successfully.');
                        }
                    });
                } else {
                    this.moveMediaToFolder(media_id, target_path, (err) => {
                        if (!err) {
                            window.toast('Media moved successfully.');
                        }
                    });
                }
            }
        });
    }

    onMediaDrop = (media_id, target_path) => {
        let selected = this.state.selected;
        if (selected.length > 1) {
            utils.asyncMap(selected, (media_id, next) => {
                this.moveMediaToFolder(media_id, target_path, (err) => {
                    if (err) {
                        error = true;
                    }
                    next();
                });
            }, () => {
                if(!error){
                    window.toast('Medias moved successfully.');
                }
            });
        } else {
            this.moveMediaToFolder(media_id, target_path, (err) => {
                if (!err) {
                    window.toast('Media moved successfully.');
                }
            });
        }
    }

    moveMediaToFolder = (media_id, target_path, cb = () => {}) => {
        generic.updateEntity('media', media_id, { path: target_path }, (err, res) => {
            if (err) {
                console.log(err);
                window.alertPopup('Error', 'An error has occurred while moving media.');
            } else {
                this.refresh();
            }
            cb(err);
        });
    }

    getMediaSelectActions = () => {
        let actions = [
            { icon: 'upload', tooltip: 'Upload medias', onClick: this.onUploadMedia },
            { icon: 'create_new_folder', tooltip: 'Create new folder', onClick: this.onAddFolder }
        ];
        if (this.state.path !== '/') {
            actions.push({ icon: 'keyboard_return', tooltip: 'Return', onClick: this.onFolderReturn });
        }
        return actions;
    }

    getMediaActions = () => {
        let actions;
        if (this.state.selected.length > 1) {
            actions = [
                { icon: 'drive_file_move', tooltip: 'Move media', onClick: this.onMoveMedia },
                { icon: "delete", tooltip: 'Delete media', onClick: this.onDeleteMedia }
            ];
        } else {
            let subscription = User.getUserSubscription();
            if (config.MOBILE_DISPLAY) {
                actions = [
                    { icon: "edit", tooltip: 'Edit media', onClick: this.onEditMedia },
                    { icon: 'drive_file_move', tooltip: 'Move media', onClick: this.onMoveMedia },
                    { icon: "delete", tooltip: 'Delete media', onClick: this.onDeleteMedia }
                ];
            } else {
                actions = [
                    // {icon: 'visibility', tooltip: 'Set visibility to private', color: 'green', onClick: this.onToggleMediaVisibility},
                    { icon: "zoom_out_map", tooltip: 'Display media', onClick: this.onShowMedia },
                    { icon: "edit", tooltip: 'Edit media', onClick: this.onEditMedia },
                    { icon: 'drive_file_move', tooltip: 'Move media', onClick: this.onMoveMedia },
                    // {icon: 'notes', tooltip: 'Media details', onClick: this.onMediaDetails},
                    { icon: "delete", tooltip: 'Delete media', onClick: this.onDeleteMedia }
                ];
            }
            if (subscription) {
                actions.unshift({ icon: 'attach_money', tooltip: 'Make media access free', color: 'green', onClick: this.onToggleMediaPaidAccess });
            } else {
                actions.unshift({ icon: 'attach_money', tooltip: 'You need to set up your subscription plan', backgroundColor: "grey", disabled: true, onClick: () => {
                    if (config.MOBILE_DISPLAY) {
                        window.toast('You need to set up your subscription plan.');
                    }
                } });
            }
            if(User.isAdmin()){
                actions.unshift({icon: 'visibility', tooltip: 'Set visibility to private', color: 'green', onClick: this.onToggleMediaVisibility});
            }
        }
        return actions;
    }

    renderFloatingAction = (action, media) => {
        let icon = action.icon,
            tooltip = action.tooltip,
            color = action.color || 'white',
            backgroundColor = action.backgroundColor;
        if (icon === 'visibility' && media.visibility === 'private') {
            icon = 'visibility_off';
            tooltip = 'Set visibility to public';
            color = 'red';
        } else if (icon === 'attach_money' && !media.paid_access && !action.disabled) {
            icon = 'money_off';
            tooltip = 'Add media to subscription plan';
            color = 'black';
        }
        let className = "media-view-action-btn utils-btn theme-color large z-depth-2";
        return <IconButton icon={icon} onClick={action.onClick && action.onClick.bind(null, media.id)} style={{ display: 'block' }}
            tooltip={undefined && action.tooltip} className={className} color={color} backgroundColor={backgroundColor} />
    }

    renderFloatingMediaActions = () => {
        let actions = this.getMediaActions(),
            display = [];
        if (actions.length === 0 || this.state.selected.length !== 1) {
            return undefined;
        }
        let media = generic.getEntity('media', this.state.selected[0]);
        if(media){
            for (let action of actions) {
                display.push(this.renderFloatingAction(action, media));
            }
            return <div className="mobile-media-view-action-toolbox">
                {display}
            </div>
        }
    }

    refresh = () => {
        if (this.state.selected.length > 0) {
            let selected = this.state.selected.slice(), checked = [];
            for (let media_id of selected) {
                let media = generic.getEntity('media', media_id);
                if (media && media.path === this.state.path) {
                    checked.push(media_id);
                }
            }
            if (selected.length !== checked.length) {
                this.setState({ selected: checked });
            }else{
                this.forceUpdate();
            }
        } else {
            this.forceUpdate();
        }
    };

    render() {
        let medias = generic.getOwnedEntities('media'),
            mobile_display = config.MOBILE_DISPLAY;
        if (mobile_display) {
            let floating_actions_style = {};
            if (this.state.path === '/') {
                // floating_actions_style = { bottom: "80px" };
            }
            return <div className={"row" + (this.props.show ? '' : ' hide')}>
                <div className="col s12" style={{ minHeight: "90px" }}>
                    {this.renderFloatingMediaActions()}
                    <MediaSelector medias={medias} selected={this.state.selected} tagSelectColor="#161823" textColor="white"
                        hideActionsIfNotSelected={this.state.selected.length > 1}
                        onFolderSelect={this.onFolderSelect} onMediaDrop={this.onMediaDrop}
                        miniFolderFormat={true} actions={this.getMediaSelectActions()}
                        floatingActions={true} floatingActionsStyle={floating_actions_style}
                        onSelect={this.onMediaSelectOne} currentPath={this.state.path} largeWrapper={window.innerWidth > 1600}
                    />
                </div>
            </div>
        } else {
            return <div className={"row" + (this.props.show ? '' : ' hide')}>
                <div className="col s12" style={{ borderRight: '2px solid white', minHeight: "90px" }}>
                    <MediaSelector medias={medias} selected={this.state.selected} tagSelectColor="#161823" textColor="white"
                        selectCheckBox={true} onSelectCheckBoxClick={this.onMediaSelect}
                        hideActionsIfNotSelected={this.state.selected.length > 1}
                        onFolderSelect={this.onFolderSelect} onMediaDrop={this.onMediaDrop}
                        onSelect={this.onMediaSelectOne} currentPath={this.state.path} largeWrapper={window.innerWidth > 1600}
                        actions={this.getMediaSelectActions()} media_actions={this.getMediaActions()}
                    />
                </div>
            </div>
        }
    }

}
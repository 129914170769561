import React from "react";

import ToolTipped from "./ToolTipped";

/**
 * Props : --> id : id to give to the component
 *         --> text : button text
 *         --> onClick : callback function when button is clicked
 *         --> color : color of the button
 *         --> backgroundColor : background color of the button
 *         --> style : custom style to add to the button
 *         --> className : additional classname to give to the button
 *         --> iconLeft : button icon left
 *         --> disabled : disable the button
 *         --> uppercase : if true, text will be uppercased
 */
export default class IconButton extends React.Component {
    state = { id: (this.props.id ? this.props.id : window.generateID()) + "-icon-button" };

    onClick = () => {
        if(!this.props.disabled && this.props.onClick){
            this.props.onClick()
        }
    }

    render() {
        let className = `material-icons icon-btn`;
        if(this.props.disabled){
            className += " disabled grey-text";
        }else{
            className += " clickable";
            if(this.props.color){
                className += ` ${this.props.color}-text`;
            }
        }
        if(this.props.className){
            className += ` ${this.props.className}`;
        }
        let icon_style = {};
        if(this.props.style){
            Object.assign(icon_style, this.props.style);
        }
        if(this.props.backgroundColor){
            icon_style.backgroundColor = this.props.backgroundColor;
        }
        let icon_btn = <i id={this.state.id} data-test={this.state.id}
                          style={icon_style}
                          className={className}
                          onClick={this.onClick}>{this.props.icon}</i>;
        let style = {display: 'inline-block'};
        if(this.props.style){
            Object.assign(style, this.props.style);
        }
        if(this.props.tooltip && !this.props.disableTooltip){
            return (
                <ToolTipped tooltip={this.props.tooltip} position={this.props.tooltipPosition} content={icon_btn} style={style} />
            )
        }else{
            return icon_btn
        }
    }
}

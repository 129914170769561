import React from "react";

import generic from "../../../model/generic";

import MediaSelector from "../../media/MediaSelector";

export default class extends React.Component {

    render() {
        let selected_id = this.props.selected, selected;
        if(selected_id){
            selected = generic.getEntity('subscription', selected_id);
        }
        return <div>
            {selected ? <SubscriptionMediaDisplay subscription={selected} /> : <div className="center-align" style={{marginTop: '50px'}}>
                <h4 className='white-text inline-block'>No subscription selected</h4>
            </div>}
        </div>
    }

}

class SubscriptionMediaDisplay extends React.Component {

    state = {path: '/', selected: []};

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.subscription !== this.props.subscription){
            this.setState({path: '/'});
        }
    };

    onFolderSelect = (path) => {
        this.setState({path: path, selected: []});
    };

    onFolderReturn = () => {
        let path = this.state.path;
        if(path !== '/'){
            path = path.substring(0, path.lastIndexOf('/'));
            if(path === '') path = '/';
            this.onFolderSelect(path);
        }
    };

    onAddMedia = () => {
        window.openDisplayMediaAddModal({
            selected: this.props.subscription.media_id_list,
            multiple_select: true,
            on_submit: (selected => {
                generic.updateEntity('subscription', this.props.subscription.id, {media_id_list: selected}, (err) => {
                    if(err){
                        window.alertPopup('Error', 'An error has occurred while updating subscription media list.')
                    }
                });
        })});
    };

    onRemoveMedia = (media_id) => {
        let selected = this.state.selected,
            to_remove = selected.length > 0 && selected.indexOf(media_id) > -1 ? selected : [media_id];
        let media_id_list = this.props.subscription.media_id_list.slice();
        for(let media_id of to_remove){
            let index = media_id_list.indexOf(media_id);
            if(index > -1){
                media_id_list.splice(index, 1);
            }
        }
        generic.updateEntity('subscription', this.props.subscription.id, {media_id_list: media_id_list});
    };

    onShowMedia = (media_id) => {
        window.openMediaDisplayModal({media_id: media_id});
    };

    onMediaDetails = (media_id) => {
        window.openMediaDetailsModal({media_id: media_id});
    };

    onMediaSelect = (media_id) => {
        let selected = this.state.selected.slice(), index = selected.indexOf(media_id);
        if (index > -1) {
            selected.splice(index, 1);
        }else{
            selected.push(media_id);
        }
        this.setState({selected: selected});
    };

    getMediaSelectActions = () => {
        let actions = [
            {icon: 'add', tooltip: 'Add medias', onClick: this.onAddMedia}
        ];
        if(this.state.path !== '/'){
            actions.push({icon: 'keyboard_return', tooltip: 'Return', onClick: this.onFolderReturn});
        }
        return actions;
    }

    getMediaActions = () => {
        let actions = this.state.selected.length > 1 ? [
            {icon: "delete", tooltip: 'Remove from subscription', onClick: this.onRemoveMedia}
        ] : [
            {icon: "zoom_out_map", tooltip: 'Display media', onClick: this.onShowMedia},
            {icon: 'notes', tooltip: 'Media details', onClick: this.onMediaDetails},
            {icon: "delete", tooltip: 'Remove from subscription', onClick: this.onRemoveMedia}
        ];
        return actions;
    }

    render() {
        let subscription = this.props.subscription, media_id_list = subscription.media_id_list || [], medias = [];
        for(let media_id of media_id_list){
            medias.push(generic.getEntity('media', media_id));
        }
        return <div className="row">
            <MediaSelector medias={medias} selected={this.state.selected} tagSelectColor="#161823" textColor="white"
                               selectCheckBox={true} onSelectCheckBoxClick={this.onMediaSelect}
                               hideActionsIfNotSelected={this.state.selected.length > 1}
                               onFolderSelect={this.onFolderSelect} hideEmptyFolders={true}
                               currentPath={this.state.path}
                               actions={this.getMediaSelectActions()} media_actions={this.getMediaActions()}
                />
        </div>
    }

}
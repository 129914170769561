import React  from 'react';

import config from '../../config';

import Modal from "../global/Modal";
import Button from "../global/Button";


export default class ConfirmActionModal extends React.Component {

    state = { opened: false, openModal: null, closeModal: null };

    componentDidMount() {
        window.openConfirmActionModal = this.openModal;
    }

    getOpen = (open) => {
        this.setState({ openModal: open });
    };

    openModal = (options, close_cb = function() {}) => {
        let state = { action_text: options.action_text, confirm_cb: options.confirm_cb, opened: true, undismissible: options.undismissible, close_cb: close_cb };

        this.setState(state, this.state.openModal);
    };

    getClose = (close) => {
        this.setState({ closeModal: close });
    };

    afterOpen = () => {
        //$(".lean-overlay").last().addClass("alert-popup-overlay");
    };

    afterClose = () => {
        this.setState({opened: false}, this.state.close_cb);
    };

    onValueChange = (value) => {
        this.setState({value: value});
    };

    onSubmit = () => {
        this.state.confirm_cb();
        this.state.closeModal();
    };

    render() {
        let disabled = false, content;
        if(this.state.opened){
            let mobile_display = config.MOBILE_DISPLAY;
            content = <div id="confirm-action-content" className="reglisse">
                <h3 style={{margin: 0}}>{"Confirm action"}</h3>
                <div style={{
                        marginTop: mobile_display ? '20px' : '50px', 
                        fontSize: mobile_display ? '20px' : '32px'
                        }}>
                    {this.state.action_text}
                </div>
                <div className="modal-footer">
                    <Button text={'Confirm'} className='t18-btn' onClick={this.onSubmit} disabled={disabled} style={{fontSize: "20px"}} />
                    <Button text={"Cancel"} className='t18-btn lm black'  onClick={this.state.closeModal} large={true}
                    style={{fontSize: "20px"}}/>
                </div>
            </div>;
        }
        return (
            <Modal id="confirm-action-modal"
                   content={content}
                   getOpen={this.getOpen}
                   getClose={this.getClose}
                   afterOpen={this.afterOpen}
                   afterClose={this.afterClose}
                   undismissible={this.state.undismissible}
            />
        )
    }
};

import React from "react";

import config from "../../config";
import web3 from "../../lib/web3";
import web3_mint from "../../lib/web3_mint";
import utils from "../../lib/utils";
import User from "../../model/user";

import Button from "../global/Button";
import DataTable from "../global/DataTable";

const MINT_ETH_URL_MAP = {
    'sepolia': 'https://sepoliafaucet.com/',
    'mumbai': 'https://faucet.polygon.technology/',
}

export default class PaymentCurrencyTable extends React.Component {

    componentDidMount() {
        web3.onNetworkChangeSubscribe(this.refresh);
    }

    refresh = () => {
        this.forceUpdate();
    };

    onBuyClick = (network, currency) => {
        if (!web3.isConnected()) {
            return window.openConnectWalletModal({
                on_connect: this.onBuyClick.bind(null, network, currency)
            });
        }
        if (web3.network_name !== network) {
            return window.alertPopup('Network Error', 'Please switch to ' + network + ' network with your wallet if you want buy with this currency.');
        }
        let subscription = this.props.subscription,
            target_address = User.getUserAddress(),
            has_token = (subscription.tokens || []).length > 0,
            token = has_token ? subscription.tokens[0] : undefined;
        window.openPaySubscriptionModal({
            action: this.props.action,
            subscription_id: subscription.id,
            target_address: target_address,
            token_id: token ? token.id : undefined,
            network: network,
            currency: currency,
            unit_count: this.props.unit_count,
            on_transaction_processed: this.props.onTransactionProcessed,
            on_transaction_error: this.props.onTransactionError
        });
    };

    onMintEthClick = () => {
        let url = MINT_ETH_URL_MAP[web3.network_name];
        if (!url) {
            return window.alertPopup('Network Error', 'Minting ETH is not supported on ' + web3.network_name + ' network.');
        }
        // Open in a new tab
        var win = window.open(url, '_blank');
    };

    onMintClick = (network, currency) => {
        let target_address = User.getUserAddress()
        window.openBetaMintingModal({
            target_address: target_address,
            network: network,
            currency: currency,
            amount: web3_mint.getMintAmount(currency.symbol),
            on_transaction_processed: () => {
                this.props.reloadData();
            },
            on_transaction_error: this.props.onTransactionError
        });
    };

    onConnectWallet = () => {
        window.openConnectWalletModal({
            on_connect: this.refresh
        })
    };


    render() {
        let subscription = this.props.subscription,
            action = this.props.action,
            currencies = subscription.accepted_currencies || {},
            unit_count = this.props.unit_count,
            has_token = (subscription.tokens || []).length > 0,
            token = has_token ? subscription.tokens[0] : undefined,
            rows = [],
            headers = ['Symbol', 'Price', 'Balance', ''],
            mint_enabled = config.contract.enable_beta_minting,
            mobile_display = config.MOBILE_DISPLAY;
        if (mint_enabled) {
            headers.push('');
        }
        if(!mobile_display){
            headers.unshift('Network');
        }
        for (let network in currencies) {
            if(mobile_display){
                rows.push({
                    data: [
                        <div className="bold">{utils.getNetworkDisplayName(network)}</div>
                    ]
                });
            }
            for (let currency of currencies[network]) {
                let price_for_currency = unit_count * utils.getPriceForCurrency(currency, subscription.price),
                    has_balance = price_for_currency <= currency.balance;
                let buy_disabled = false,
                    mint_disabled = false,
                    disabled_tooltip_text,
                    mint_disabled_tooltip_text;
                if(!User.hasAddress()){
                    buy_disabled = true;
                    mint_disabled = true;
                    disabled_tooltip_text = 'You need to associate a wallet to your account to buy a subscription';
                    mint_disabled_tooltip_text = 'You need to associate a wallet to your account to mint a currency';
                } else if(!User.userMatchWeb3Address()){
                    buy_disabled = true;
                    mint_disabled = true;
                    disabled_tooltip_text = 'You need to connect the wallet associated to your account to buy a subscription';
                    mint_disabled_tooltip_text = 'You need to connect the wallet associated to your account to mint a currency';
                } else if (web3.network_name !== network) {
                    buy_disabled = true;
                    mint_disabled = true;
                    disabled_tooltip_text = 'You can only buy a subscription with a currency on the same network on which you are connected';
                    mint_disabled_tooltip_text = 'You can only mint a currency on the same network on which you are connected';
                } else if (!has_balance) {
                    buy_disabled = true;
                    disabled_tooltip_text = 'Insufficient balance';
                } else if (action === 'extend' && token.network !== network) {
                    buy_disabled = true;
                    disabled_tooltip_text = 'You can only extend your subscription with a currency on the same network on which you bought it';
                }
                let data = [
                    utils.getNetworkDisplayName(network),
                    currency.symbol,
                    price_for_currency,
                    <span className={has_balance ? "green-text" : "red-text"}>{currency.balance}</span>,
                    <Button text="Buy" className="t18-btn" onClick={this.onBuyClick.bind(null, network, currency)} disabled={buy_disabled} tooltip={disabled_tooltip_text} />
                ];
                if(mobile_display){
                    data.shift();
                }
                if (mint_enabled && web3_mint.mintEnabled(network, currency.symbol)) {
                    let mint_btn = <Button text="Mint" className="t18-btn" onClick={this.onMintClick.bind(null, network, currency)} disabled={mint_disabled} tooltip={mint_disabled_tooltip_text} />;
                    if(mobile_display){
                        data[3] = [data[3], mint_btn]
                    }else{
                        data.push(mint_btn);
                    }
                }
                rows.push({
                    data: data
                });
            }
        }
        return <div>
            {!web3.connected_wallet && <div>
                <div className="bold reglisse">{"Please connect your wallet to buy a subscription"}</div>
                <Button text="Connect Wallet" className="t18-btn lm" onClick={this.onConnectWallet} />    
            </div>}
            {mint_enabled && web3.connected_wallet && <div>
                    <span className="bold reglisse">{"Current network: " + utils.getNetworkDisplayName(web3.network_name)}</span>
                    <Button text="Mint Chain Token"  className="t18-btn lm" onClick={this.onMintEthClick} />
                </div>}
            <div>
                <DataTable className="tm payment-currency-table" headers={{ headers }} rows={rows} />
            </div>
        </div>
    }

}
import React from "react";

import generic from "../../model/generic";

import IconButton from "../global/IconButton";
import CheckBox from "../global/CheckBox";
import MediaDisplay from "./MediaDisplay";

export default class MediaCard extends React.Component {

    onRefresh = () => {
        this.forceUpdate();
    }

    onDragStart = (e, media_id) => {
        e.dataTransfer.setData("media_id", media_id);
    };

    onDragEnd = (e) => {
        e.preventDefault();
    }

    onActionClick = (e, action, media_id) => {
        e.stopPropagation();
        if(action.onClick){
            action.onClick(media_id);
        }
    }

    onSelectCheckBoxClick = (media_id) => {
        this.props.onSelectCheckBoxClick(media_id);
    }

    renderAction = (action, media) => {
        let icon = action.icon, tooltip = action.tooltip, color = action.color;
        if(icon === 'visibility' && media.visibility === 'private'){
            icon = 'visibility_off';
            tooltip = 'Set visibility to public';
            color = 'red';
        }else if(icon === 'attach_money' && !media.paid_access && !action.disabled){
            icon = 'money_off';
            tooltip = 'Add media to subscription plan';
            color = 'red';
        }
        return <span onClick={(e) => {this.onActionClick(e, action, media.id)}}>
        <IconButton icon={icon} tooltip={tooltip} color={color} className="theme-color lm" disabled={action.disabled} /></span>
    }

    render = () => {
        let media_id = this.props.media_id, media = generic.getEntity('media', media_id),
            action_display = [], media_actions = this.props.media_actions || [],
            className = "t18-card z-depth-1 " + (this.props.isSelected ? ' selected': '');
        if(this.props.className){
            className += " " + this.props.className;
        }
        if(this.props.onClick){
            className += " pointer";
        }
        for(let action of media_actions){
            action_display.push(this.renderAction(action, media));
            // action_display.push(<span onClick={(e) => {this.onActionClick(e, action, media_id)}}>
            //     <IconButton icon={action.icon} tooltip={action.tooltip} className="theme-color lm"/></span>)
        }
        let height = action_display.length > 0 ? '310px' : '280px';
        return <div className={"col " + (this.props.largeWrapper ? "xl2 l3 m6 s12" : "l3 m6 s12")} style={{padding: "10px"}}>
            <div className={className} style={{position: "relative", height: height}}
                 onClick={this.props.onClick && this.props.onClick.bind(null, media_id)}
                 draggable="true" onDragStart={(e) => this.onDragStart(e, media_id)} onDragEnd={this.onDragEnd} >
                <MediaDisplay key={media && media.id} 
                              media_id={media && media.id} 
                              onMediaLoad={this.onRefresh} 
                              showBadges={true}
                              displayName={true} />
                {action_display.length > 0 && (!this.props.hideActionsIfNotSelected || this.props.isSelected) &&
                    <div className={"card-action-toolbar" + (this.props.isSelected ? " selected" : "")}>
                        {action_display}
                    </div>}
                {this.props.selectCheckBox && <div style={{position: 'absolute', bottom: this.props.isSelected ? '0' : '4px', left: '12px'}}>
                    <CheckBox checked={this.props.isSelected} preventClickPropagation={true}
                              onClick={this.onSelectCheckBoxClick.bind(null, media_id)} />
                </div>}
            </div>
        </div>
    }

}

const local_config = {

    contract: {
        enable_beta_minting: true,
        land_network_name: 'sepolia',
        wallet_connect_project_id: '89f68ff9b7b3b9291e4ab056e5f7960c',
        enabled_networks: ['sepolia'],
        // enabled_networks: ['sepolia'],
        land: {
            sepolia: {
                address: '0x3D415DDF8b7c02354E211Bc681e18553d1b04063'
            }
        },
        subscription: {
            name: 'T18Subscription',
            networks: {
                sepolia: {
                    address: '0xBD64CcfFAd67c181B70dDF1BccBDd1870C2eC828'
                },
                mumbai: {
                    address: '0x75647Cb6EF434Fa8aCC5b444BF14afc393acc7E5'
                }
            }
        },
        payment: {
            name: 'T18Payment',
            networks: {
                sepolia: {
                    address: '0x567D57aa88Ee091bbd7b0b444fB6A3033C1B8C8f'
                },
                mumbai: {
                    address: '0xd282157B5F3401fdd194ce06d69dbC8A6BF05701'
                }
            }
        },
        usd_decimals: 8
    }

}

export default local_config;
import React from 'react';
import moment from "moment";
import User from "../../model/user";

import utils from "../../lib/utils";

import web3 from "../../lib/web3";

import Modal from "../global/Modal";
import Button from "../global/Button";
import InputField from "../global/InputField";
import LoadingAnimation from "../global/LoadingAnimation";

import metamask_icon from "../../images/wallet_icons/metamask.png";
import ledger_icon from "../../images/wallet_icons/ledger.png";
import coinbase_icon from "../../images/wallet_icons/coinbase.png";
import wallet_connect_icon from "../../images/wallet_icons/wallet_connect.png";

export default class LoginModal extends React.Component {

    state = { opened: false, username: "", email: "", password: "", confirm_password: "" };

    componentDidMount = () => {
        window.openSignUpModal = this.openModal;
    };

    getOpen = (open) => {
        this.setState({ openModal: open });
    };

    openModal = (options = {}, undismissible = false, close_cb = () => { }) => {
        this.setState({
            opened: true,
            undismissible: undismissible,
            on_signup_cb: options.on_signup_cb || (() => { }),
            close_cb: close_cb
        }, this.state.openModal);
    };

    getClose = (close) => {
        this.setState({ closeModal: close });
    };

    afterOpen = () => {
        //$(".lean-overlay").last().addClass("alert-popup-overlay");
    };

    afterClose = () => {
        this.setState({ opened: false }, this.state.close_cb);
    };

    onUsernameChange = (value) => {
        this.setState({ username: value });
    }

    onEmailChange = (value) => {
        this.setState({ email: value });
    }

    onPasswordChange = (value) => {
        this.setState({ password: value });
    }

    onConfirmPasswordChange = (value) => {
        this.setState({ confirm_password: value });
    }

    checkPasswordValidity = () => {
        let password = this.state.password,
            confirm_password = this.state.confirm_password;
        let check = true;
        if (confirm_password !== password) {
            check = false;
        } else if (!checkPassword(password)) {
            check = false;
        } else if (!checkPassword(confirm_password)) {
            check = false;
        } 
        return check;
    }

    onSubmit = () => {
        let data = { 
            username: this.state.username,
            email: this.state.email, 
            password: this.state.password
        }
        User.signUp(data, (err) => {
            if (err) {
                console.log('Signup Error : ' + JSON.stringify(err));
                if(err.message.includes('username-already-used')){
                    window.alertPopup('Error', 'This username is already used.');
                }else if(err.message.includes('email-already-used')){
                    window.alertPopup('Error', 'This e-mail is already used.');
                }else{
                    window.alertPopup('Error', 'An error has occurred while signing up.');
                }
            } else {
                this.state.closeModal();
                this.state.on_signup_cb();
            }
        })
    }

    render = () => {
        let content;
        if (this.state.opened) {
            let disabled = false,
                username = this.state.username,
                email = this.state.email,
                password = this.state.password,
                confirm_password = this.state.confirm_password;
            let password_typed = password || confirm_password;
            let valid_password = this.checkPasswordValidity();
            if(!valid_password){
                disabled = true;
            }
            if(!username || !email){
                disabled = true;
            }
            content = <div className="reglisse">
                <h2 className="center-align">Sign Up</h2>
                <div>
                    <div className="center-align tm">
                        <div className="center-align tm">
                            <InputField style={{ display: 'inline-block', width: '300px' }} label="Username"
                                onChange={this.onUsernameChange} />
                        </div>
                        <div className="center-align tm">
                            <InputField style={{ display: 'inline-block', width: '300px' }} label="Email" type="email"
                                onChange={this.onEmailChange} invalid={email && !utils.isEmail(email)} />
                        </div>
                        <div className="center-align tm">
                            <InputField style={{ display: 'inline-block', width: '300px' }} label="Password" type="password"
                                onChange={this.onPasswordChange} invalid={password_typed && !valid_password} />
                        </div>
                        <div className="center-align tm">
                            <InputField style={{ display: 'inline-block', width: '300px' }} label="Confirm Password" type="password"
                                onChange={this.onConfirmPasswordChange} />
                        </div>
                        {password_typed && !valid_password && <div className="red-text">{"Password needs at least 8 characters, 1 uppercase, 1 lowercase and 1 digit"}</div>}
                        <div className="center-align tm">
                            <Button className="t18-btn tm" text="Sign Up" onClick={this.onSubmit} disabled={disabled} />
                        </div>
                    </div>
                </div>
                <div className="modal-footer">
                    <Button className='t18-btn lm black' text="Cancel" style={{ fontSize: "20px" }}
                        onClick={this.state.closeModal} large={true} />
                </div>
            </div>
        }
        return (
            <Modal id="sign-up-modal"
                content={content}
                getOpen={this.getOpen}
                getClose={this.getClose}
                afterOpen={this.afterOpen}
                afterClose={this.state.close_cb}
                undismissible={this.state.undismissible}
            />
        )
    }

}

function checkPassword(password){
    return password.match(/[a-zA-Z0-9~!@#$%^&\(\)_+\-={}\[\];\\\',.]{8}/)
        && password.match(/[a-z]{1}/)
        && password.match(/[A-Z]{1}/)
        && password.match(/[0-9]{1}/);
}
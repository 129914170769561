import React from "react";
import _ from "lodash";

import config from "../config";
import User from "../model/user";

import logo_t18 from '../images/logo_t18.png';
import Button from "./global/Button";
import IconButton from "./global/IconButton";

export default class extends React.Component {

    renderMenu = (menu) => {
        let selected = this.props.selectedMenu === menu.key;
        if (config.MOBILE_DISPLAY) {
            return <span key={menu.key}>
                <i className={"navbar-icon-button material-icons white-text clickable" + (selected ? " selected" : "")} 
                onClick={this.props.onViewSelect.bind(null, menu.key)}>{menu.icon}</i>
            </span>
        } else {
            return <span key={menu.key}
                className={"navbar-menu-option" + (this.props.selectedMenu === menu.key ? ' selected' : '')}
                onClick={this.props.onViewSelect.bind(null, menu.key)}>
                {menu.display}
            </span>
        }
    }

    render() {
        let logo_style = config.MOBILE_DISPLAY ? {
            height: '50px',
            position: 'relative',
            top: '5px'
        } : {
            height: '60px',
            marginRight: '40px'
        }
        if(this.props.logoStyle){
            logo_style = this.props.logoStyle;
        }
        return <div id="navbar" className="" style={{ padding: "10px", position: 'relative' }}>
            <div style={{ display: 'inline-block', marginLeft: config.MOBILE_DISPLAY ? "10px" : "20px" }}>
                <img className="navbar-logo" src={this.props.logo || logo_t18} style={logo_style} />
                <span id="navbar-title" >{this.props.title}</span>
                {_.map(this.props.menus || [], (menu) => {
                    return this.renderMenu(menu);
                })}
                {this.props.userDisplay && <UserDisplay selectedView={this.props.selectedMenu}
                    onViewSelect={this.props.onViewSelect}
                    onSignin={this.props.onSignin} />}
            </div>
        </div>
    }

}


class UserDisplay extends React.Component {

    onSignin = () => {
        if (this.props.onSignin) {
            this.props.onSignin();
        }
    };

    renderSigninButton = () => {
        if(config.MOBILE_DISPLAY){
            return <Button className="pink-t18-bg" style={{ marginLeft: "5px", height: "58px", padding: "10px 15px 10px 10px", borderRadius: "15px", marginBottom: "30px" }}
                    text={<i className="material-icons" style={{fontSize: '30px'}}>login</i>} onClick={this.onSignin} />
        }else{
            return <Button className="pink-t18-bg" style={{ fontSize: "30px", height: "58px", padding: "10px 20px", borderRadius: "15px", marginTop: "15px" }}
            text={"Log In"} onClick={this.onSignin} />
        }
    }

    render() {
        let is_auth = User.isAuthenticated();
        return <span className='user-navbar-display'>
            {is_auth ? <UserButton selected={this.props.selectedView === 'profile'} onClick={this.props.onViewSelect.bind(null, 'profile')} /> : <span>
                {this.renderSigninButton()}
            </span>}
        </span>
    }

}

class UserButton extends React.Component {

    render() {
        return <span>
            <i className={"navbar-icon-button material-icons white-text clickable" + (this.props.selected ? " selected" : "")} 
                onClick={this.props.onClick}>person</i>
        </span>
    }

}
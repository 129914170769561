import { JsonRpcProvider, BrowserProvider, AbstractProvider, Contract, parseEther, formatEther, formatUnits } from 'ethers';
import { EthereumProvider } from '@walletconnect/ethereum-provider'
//import { LedgerConnector } from "@web3-react/ledger-connector";
import T18Subscription from '../artifacts/contracts/T18Subscription.sol/T18Subscription.json';

import _ from 'lodash';

import Config from '../config';

const DEBUG = false;

const CHAIN_IDS = {
    mainnet: 1,
    sepolia: 11155111,
    mumbai: 80001,
}

function log(str) {
    if (DEBUG) {
        console.log('ContractHandler: ' + str);
    }
}

const ERROR_CODES = {
    EROP1: "Cannot set zero as target",
    EROW1: "Caller is not contract owner",
    EROW2: "Error while sending fund to target",
    ERLO1: "Lock is not disengaged by other owner",
    ERLO2: "Lock was not disengaged recently",
    ERLO3: "Lock target is not set",
    ERLO4: "Unlocked target not corresponding to given target",
    ERLT1: "Invalid token id",
    ERLT2: "Public sale not open for this zone",
    ERLT3: "Value sent does not correspond to price",
    ERLT4: "Land already minted",
    ERZ1: "Invalid zone number",
    "user rejected transaction": "Transaction rejected by user",
    "insufficient funds for intrinsic transaction cost": "Insufficient funds for transaction",
};

let isWalletAccessed = false;

let wallet_connect_error = false;

const web3 = {

    initialized: false,
    provider: null,
    signer: null,
    contract: null,
    user_address: null,
    user_balance: 0,
    watching_transfer_events: false,

    wallets: {
        MetaMask: null,
        Coinbase: null,
        WalletConnect: null,
        Ledger: null,
    },

    connected_wallet: null,

    initialize: (cb = () => { }) => {
        web3.detectWallets().then();
        setInterval(() => {
            web3.detectWallets().then();
        }, 1000);
        web3.reconnect(cb);
    },

    sign_out: () => {},

    setSignOut: (sign_out) => {
        web3.sign_out = sign_out;
    },

    isConnected: () => {
        return web3.connected_wallet !== null;
    },

    detectWallets: async () => {

        if (window.ethereum) {
            if (window.ethereum.providers) {
                for (let provider of window.ethereum.providers) {
                    if (provider.isMetaMask) {
                        web3.wallets.MetaMask = provider;
                    } else if (provider.isCoinbaseWallet) {
                        web3.wallets.Coinbase = provider;
                    }
                }
            } else {
                if (web3.wallets.MetaMask === null && typeof window.ethereum !== "undefined") {
                    web3.wallets.MetaMask = window.ethereum;
                    window.ethereum.on('accountsChanged', function (accounts) {
                        if(web3.isConnected()){
                            web3.reconnect();
                        }
                        // console.log(accounts);
                        // if (isWalletAccessed) {
                        //     window.location.reload();
                        // } else {
                        //     isWalletAccessed = true;
                        // }
                    });
                    window.ethereum.on('networkChanged', function (networkId) {
                        if(web3.connected_wallet){
                            web3.connectWallet(web3.connected_wallet, web3.onNetworkChange);
                        }
                    });
                }

                if (
                    web3.wallets.Coinbase === null &&
                    typeof window.web3 !== "undefined" &&
                    (typeof window.web3.currentProvider.isCoinbaseWalletApp !== "undefined" || window.web3.currentProvider.isCoinbaseWallet)
                ) {
                    web3.wallets.Coinbase = window.web3.currentProvider;
                }
            }
        }

        if (web3.wallets.WalletConnect === null && !wallet_connect_error) {
            try{
                let chain_ids = [];
                for(let name of Config.contract.enabled_networks){
                    chain_ids.push(CHAIN_IDS[name]);
                }
                // web3.wallets.WalletConnect = await EthereumProvider.init({
                //     projectId: Config.contract.wallet_connect_project_id, // required
                //     chains: chain_ids, // required
                //     showQrModal: true // requires @walletconnect/modal
                // });
                web3.wallets.WalletConnect = EthereumProvider.init({
                    projectId: Config.contract.wallet_connect_project_id, // required
                    chains: chain_ids, // required
                    showQrModal: true // requires @walletconnect/modal
                }).then(() => {
                    console.log('WalletConnect initialized');
                }).catch((err) => {
                    console.log('WalletConnect initialization failed');
                    console.log(err);
                    wallet_connect_error = true;
                })
            }catch(err){
                console.log(err);
                wallet_connect_error = true;
            }
            
        }

        /*if (web3.wallets.Ledger === null) {
            web3.wallets.Ledger = new LedgerConnector({
                chainId: 1,
                url: Config.contract.infura_url,
                pollingInterval: 15000,
            });
        }*/

    },

    getWallet: (walletName) => {
        return web3.wallets[walletName];
    },

    resetWallet: (walletName) => {
        console.log('resetWallet', walletName);
        web3.wallets[walletName] = null;
    },

    reconnect: (cb = () => {}) => {
        let connected_wallet = sessionStorage.getItem('connected_wallet');
        if (connected_wallet) {
            web3.connectWallet(connected_wallet).then(() => {
                console.log('Wallet reconnected');
                return cb();
            }).catch((err) => {
                console.log('Wallet reconnection failed');
                console.log(err);
                return cb();
            });
        } else {
            return cb();
        }
    },

    check_address_interval: null,

    connectWallet: async (walletName, cb = () => {}) => {

        let previous_check_address_interval = web3.check_address_interval;
        setTimeout(() => {
            clearInterval(previous_check_address_interval);
        }, 3000);
        console.log('connectWallet', walletName);
        let walletProvider = web3.getWallet(walletName);
        if (!walletProvider) {
            return;
        }
        /*if (walletProvider instanceof LedgerConnector) {
            const ledgerProvider = await walletProvider.connect();
            walletProvider = ledgerProvider;
        } else */if (walletProvider instanceof EthereumProvider) {
            await walletProvider.enable();
        } else {
            await walletProvider.enable();
        }

        await new Promise((resolve) => setTimeout(resolve, 1000));
        let provider = ['MetaMask', 'Coinbase'].indexOf(walletName) > -1 ? new BrowserProvider(window.ethereum) : new AbstractProvider(walletProvider);
        web3.provider = provider;
        let network_data = await provider.getNetwork();
        console.log(network_data);
        web3.network_name = getNetworkName(network_data);
        console.log('Connected to ' + web3.network_name + ' network');
        web3.signer = await provider.getSigner();
        let user_address = (await web3.signer.getAddress()).toString();
        web3.user_address = user_address;
        web3.check_address_interval = setInterval(async () => {
            let address = await web3.signer.getAddress();
            // console.log('check_address_interval', address, user_address);
            if (address !== user_address) {
                web3.sign_out();
            }
        }, 1000)
        // let contract_address = _.get(Config, `contract.subscription.networks.${web3.network_name}.address`, null);
        // if (contract_address) {
        //     web3.contract = new Contract(contract_address, T18Subscription.abi, web3.signer);
        // }
        console.log('Connected to ' + web3.network_name + ' network');
        web3.connected_wallet = walletName;
        sessionStorage.setItem('connected_wallet', walletName);
        cb();
    },

    setUserAddress: (address) => {
        web3.user_address = address;
    },

    signMessage: (message, cb) => {
        try{
            let signer = web3.signer;
            signer.signMessage(message).then((data) => {
                return cb(null, data);
            }).catch(err => {
                log('Error while signing message : ' + JSON.stringify(err));
                log(err);
                return cb(err);
            });
        }catch(e){
            return cb(e);
        }
    },

    handleContractError: (err, msg_prefix) => {
        let error_msg = "Unknown";
        if (err) {
            for (let code in ERROR_CODES) {
                if (err.indexOf(code) > -1) {
                    error_msg = ERROR_CODES[code];
                    break;
                }
            }
        }
        if (msg_prefix) {
            error_msg = msg_prefix + error_msg;
        }
        window.alertPopup('Smart Contract Error', error_msg);
    },

    getTransactionFromId: (tx_id, cb) => {
        web3.provider.getTransactionReceipt(tx_id).then((data) => {
            return cb(null, data);
        }).catch((err) => {
            console.log('Error while retriving transaction from id : ' + JSON.stringify(err));
            return cb(err);
        })
    },

    awaitTransaction: (tx_id, cb) => {
        // console.log('Checking transaction status: ' + tx_id);
        web3.getTransactionFromId(tx_id, (err, data) => {
            if (data && data.blockNumber) {
                let error = data.status === 0 ? 'transaction-failed' : undefined;
                console.log('Transaction ' + tx_id + ' has ' + (error ? 'failed' : 'succeeded'));
                return cb(error);
            } else {
                setTimeout(() => {
                    web3.awaitTransaction(tx_id, cb);
                }, 500);
            }
        });
    },

    approve: async (currency, amount, on_sent, on_processed) => {
        let currency_address = currency.address;
        let currency_approve_contract = new Contract(currency_address, [
            {
                "constant": false,
                "inputs": [
                    { "name": "_spender", "type": "address" },
                    { "name": "_value", "type": "uint256" }
                ],
                "name": "approve",
                "outputs": [
                    { "name": "", "type": "bool" }
                ],
                "type": "function"
            }
        ], web3.signer);
        let payment_address = _.get(Config, `contract.payment.networks.${web3.network_name}.address`, null);
        let tx;
        try{
            if(payment_address){
                tx = await currency_approve_contract.approve(payment_address, amount);
                on_sent(null, tx.hash);
            }else{
                return on_sent('contract address not found', null);
            }
        }catch(err){
            console.log(err);
            return on_sent(err);
        }

        const receipt = await tx.wait();
        if (receipt.status === 1) {
            console.log("Transaction was successful!");
            on_processed();
        } else if (receipt.status === 0) {
            console.log("Transaction failed.");
            on_processed("transaction-failed");
        } else {
            console.log("Unexpected transaction status:", receipt.status);
            on_processed("transaction-failed");
        }
    },

    approveSubscriptionBuy: async (currency, amount, on_sent, on_processed) => {
        let currency_address = currency.address;
        let currency_allowance_contract = new Contract(currency_address, [
            {
                "constant": true,
                "inputs": [
                    { "name": "owner", "type": "address" },
                    { "name": "spender", "type": "address" }
                ],
                "name": "allowance",
                "outputs": [
                    { "name": "", "type": "uint256" }
                ],
                "type": "function",
                "stateMutability": "view"
            }
        ], web3.provider)

        let payment_address = _.get(Config, `contract.payment.networks.${web3.network_name}.address`, null);
        // Check current allowance
        let allowance = await currency_allowance_contract.allowance(web3.user_address, payment_address);
        if(allowance == amount){
            return on_processed();
        }else if(allowance > 0 && currency.symbol === 'USDT'){
            return on_sent('need-allowance-reset');
        }else{
            await web3.approve(currency, amount, on_sent, on_processed);
        }
    },

    getBalance: async () => {
        return await web3.provider.getBalance(web3.user_address);
    },

    getERC20Balance: async (token_address) => {
        let token_contract = new Contract(token_address, [
            {
                "constant": true,
                "inputs": [
                    { "name": "owner", "type": "address" }
                ],
                "name": "balanceOf",
                "outputs": [
                    { "name": "", "type": "uint256" }
                ],
                "type": "function",
                "stateMutability": "view"
            }
        ], web3.provider);
        let balance = await token_contract.balanceOf(web3.user_address);
        return balance;  
    },

    getChainlinkRate: async (chainlink_address) => {
        let chainlink_contract = new Contract(chainlink_address, [
            "function latestRoundData() external view returns (uint80 roundId, int256 answer, uint256 startedAt, uint256 updatedAt, uint80 answeredInRound)"
        ], web3.provider);
        let round_data = await chainlink_contract.latestRoundData();
        return formatUnits(round_data.answer, 8);
    },

    onWalletUpdateListenerList: [],

    onWalletUpdateSubscribe: (cb) => {
        web3.onWalletUpdateListenerList.push(cb);
    },

    onWalletUpdate: () => {
        for (let cb of web3.onWalletUpdateListenerList) {
            cb();
        }
    },

    onNetworkChangeListenerList: [],

    onNetworkChangeSubscribe: (cb) => {
        web3.onNetworkChangeListenerList.push(cb);
    },

    onNetworkChange: () => {
        for (let cb of web3.onNetworkChangeListenerList) {
            cb();
        }
    },

}

function getNetworkName(data){
    if(data.chainId || data['#chainId']){
        let chain_id = data.chainId || data['#chainId'];
        return getNetworkNameFromChainId(chain_id);
    }else{
        return data.network.name;
    }
}

function getNetworkNameFromChainId(chain_id) {
    for (let name in CHAIN_IDS) {
        if (CHAIN_IDS[name] == chain_id) {
            return name;
        }
    }
}

window.t18_web3 = web3;

export default web3;
import React from "react";
import moment from "moment";

import config from "../../config";

import generic from "../../model/generic";

import DataTable from "../global/DataTable";
import Title from "../global/Title";

export default class SubscriptionTokenDisplay extends React.Component {

    render() {
        let headers = ["Subscription", "Expiration"],
            rows = [],
            subscription_tokens = generic.getOwnedEntities('subscription_token'),
            mobile_display = config.MOBILE_DISPLAY;
        for (let token of subscription_tokens) {
            let subscription = generic.getEntity('subscription', token.subscription_id),
                owner = generic.getEntity('user', subscription.owner);
            rows.push({
                data: [
                    (owner.username || "Unnamed") + " (" + (subscription.media_id_list || []).length + " medias)",
                    moment(token.expiration_time * 1000).format('DD/MM/YYYY')
                ]
            })
        }
        return <div style={{
            padding: mobile_display ? "10px" : "40px 20px",
            paddingTop: mobile_display ? undefined : "10px",
            margin: mobile_display ? '10px 5px' : '20px',
            borderRadius: "15px",
            color: 'white',
            backgroundColor: 'rgb(35, 40, 57)'
        }}>
            <div className="center-align">
                <Title text="Subscription Tokens" size="4" />
            </div>
            {rows.length === 0 && <div className="tm">
                <Title text="You don't have any subscription token yet." />
            </div>}
            {rows.length > 0 && <DataTable headers={{ headers }} rows={rows} />}
        </div>
    }

}
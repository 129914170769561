import React from "react";
import _ from 'lodash';

import config from "../../../../shared/config";
import generic from '../../../../shared/model/generic';

import ProfileMiniDisplay from "../../../../shared/components/profile/ProfileMiniDisplay";
import ProfilePicture from "../../../../shared/components/profile/ProfilePicture";
import GridContentBrowser from "../../../../shared/components/common/GridContentBrowser";

import ExploreMediaView from "../ExploreMediaView/ExploreMediaView";
import MobileMediaHeader from "../../../../shared/components/media/SelectedMediaView/MobileMediaHeader";

export default class extends React.Component {

    state = { selected_user_id: null };

    componentDidMount() {
        window.showUser = (user_id) => {
            window.onAppViewSelect('creator', () => {
                if(user_id === this.state.selected_user_id){
                    this.onUserSelect(null, () => {
                        this.onUserSelect(user_id);
                    });
                }else{
                    this.onUserSelect(user_id);
                }
            });
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if(prevProps.show !== this.props.show && this.props.show){
            this.setState({ selected_user_id: null });
        }
    }

    onUserSelect = (user_id, cb = () => {}) => {
        this.setState({ selected_user_id: user_id }, cb);
    }

    refresh = () => {
        this.forceUpdate();
    }

    renderUserCell = (user, without_on_click) => {
        return <ProfileMiniDisplay
            noColClass={true}
            key={user.id}
            user={user}
            displayName={true}
            displayDescription={true}
            onClick={without_on_click ? undefined : () => this.onUserSelect(user.id)}
        />
    }

    renderSelectedUser = () => {
        let selected_user_id = this.state.selected_user_id;
        if(!selected_user_id){
            return null;
        }
        let selected_user = generic.getEntity('user', selected_user_id);
        if(config.MOBILE_DISPLAY){
            return <div>
                <MobileMediaHeader userId={selected_user_id} fixed={true} />
                <ExploreMediaView show={true} style={{height: '100%', overflow: 'hidden'}}
                        disableMobileMediaHeader={true}
                        enabledFilters= {['search', 'type', 'access', 'tags']}
                        medias={generic.getEntitiesFromProperty('media', 'owner', selected_user_id)}
                        onReturn={this.onUserSelect.bind(null, null)} />
            </div>
        }else{
            return <ExploreMediaView show={true} style={{height: '100%', overflow: 'hidden'}}
                                    medias={generic.getEntitiesFromProperty('media', 'owner', selected_user_id)}
                                    topFilterContent={this.renderUserCell(selected_user, true)}
                                    enabledFilters= {['search', 'type', 'access', 'tags']}
                                    onReturn={this.onUserSelect.bind(null, null)} />
        }
    }

    render() {
        let selected_user_id = this.state.selected_user_id,
            users = generic.getEntities('user', true);
        users = _.filter(users, (user) => {
            if(!user.content_creator){
                return false;
            }
            let medias = generic.getEntitiesFromProperty('media', 'owner', user.id);
            return medias.length > 0;
        });
        return <div className={"app-view" + (this.props.show ? '' : ' hide')}>
            <GridContentBrowser show={!selected_user_id} 
                                contentList={users}
                                renderContent={this.renderUserCell}
                                rowHeight={380}
                                enabledFilters= {['search']}
                                searchTarget={'username'}
                                defaultFilters={{content_creator: true}}
                                noContentText={"No matching user"} />
            {this.renderSelectedUser()}
        </div>

    }

}
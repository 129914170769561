import React from "react";
import _ from 'lodash';

import config from "../../../config";
import generic from "../../../model/generic";

import SelectedMediaDisplay from "./SelectedMediaDisplay";
import SelectedMediaTab from "./SelectedMediaTab";
import MediaPaymentPanel from "./MediaPaymentPanel";
import MobileMediaHeader from "./MobileMediaHeader";

import UserReturnTitle from "../../common/UserReturnTitle";

export default class extends React.Component {

    state = { show_payment_panel: false }

    onBuyClick = () => {
        this.setState({ show_payment_panel: true });
    }

    hidePaymentPanel = () => {
        this.setState({ show_payment_panel: false });
    }

    render() {
        let selected_media_id = this.props.selectedId,
            mobile_display = config.MOBILE_DISPLAY;
        if (!selected_media_id) {
            return null;
        }
        let media = generic.getEntity('media', selected_media_id);
        let show_payment_panel = this.state.show_payment_panel,
            display = [];
        if(show_payment_panel){
            display.push(<MediaPaymentPanel mediaId={selected_media_id} 
                onTransactionProcessed={this.props.onTransactionProcessed} 
                onReturn={this.hidePaymentPanel} />);
        }else{
            if(mobile_display){
                if(this.props.mobileMediaHeader){
                    display.push(<UserReturnTitle userId={media.owner} onReturn={this.props.onReturn} showPicture={true} redirectAction={this.props.showUser} />);
                    // display.push(<MobileMediaHeader userId={media.owner} redirectAction={true} showUser={this.props.showUser} onReturn={this.props.onReturn} />);
                }
            }else{
                display.push(<SelectedMediaTab show={true} 
                    onReturn={this.props.onReturn}
                    mediaId={selected_media_id} 
                    showUser={this.props.showUser}
                    onBuyClick={this.onBuyClick} />);
            }
            display.push(<SelectedMediaDisplay mediaId={selected_media_id}  />);
            if(mobile_display){
                display.push(<SelectedMediaTab 
                    show={true} 
                    mediaId={selected_media_id} 
                    onBuyClick={this.onBuyClick} />);
            }
        }
        return <div className={"selected-media-view h-100 row" + (this.props.putAbove ? " above" : "")} >
            {display}
        </div>
    }

}
import React from "react";

import config from "../../config";
import generic from "../../model/generic";
import utils from "../../lib/utils";

import EntityLoader from "../EntityLoader";
import IconButton from "../global/IconButton";
import MediaBadges from "./MediaBadges";

// import VRVideoPlayer from "../common/VRVideoPlayer";

let LOCK_URL = config.locked_media_url;

export default class MediaDisplay extends React.Component {

    state = { render_video: false, id: generateID() };

    componentDidMount() {
        this.forceUpdate();
    }

    onPlayVideo = () => {
        this.setState({ render_video: true });
    }

    renderProcessing = () => {
        return <div style={{ height: this.props.media_height || "200px", paddingTop: '90px', fontSize: '20px' }}>
            <div className={"green-text"}>Processing ongoing ...</div>
        </div>
    }

    renderPicture = (url, width, height, preview_url) => {
        return <MediaPicture url={url} width={width} height={height} mediaClassName={this.props.mediaClassName}
                preview_url={preview_url} wrapper_id={this.state.id} />
    }

    renderPictureMedia = (media) => {
        return this.renderPicture(media.url, _.get(media, 'metadata.width'), _.get(media, 'metadata.height'));
    }

    renderVideoMedia = (media) => {
        if (!media.thumbnail_url && media.url === LOCK_URL) {
            return this.renderPicture(LOCK_URL, _.get(media, 'metadata.width'), _.get(media, 'metadata.height'));
        } else if (media.thumbnail_url && !this.state.render_video) {
            if (this.state.render_video) {
                return <video className={this.props.mediaClassName || ""} style={{ height: "100%", maxWidth: "100%" }} src={media.url} controls preload="metadata" />
            } else {
                let icon = media.paid_access_denied ? "lock" : "play_circle",
                    disabled = media.paid_access_denied ? true : false;
                return <div className={"video-preview-wrapper"}>
                    {this.renderPicture(media.thumbnail_url, _.get(media, 'metadata.width'), _.get(media, 'metadata.height'), media.preview_url)}
                    <IconButton icon={icon} onClick={this.onPlayVideo} className="white-text" disabled={disabled}
                        style={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", fontSize: '50px' }} />
                </div>;
            }
        } else {
            if(media.type === 'video' || true){
                return <video className={this.props.mediaClassName || ""} style={{ height: "100%", maxWidth: "100%" }} src={media.url} controls preload="metadata" />
            }else{
                // return <VRVideoPlayer url={media.url} />
            }
        }
    }

    renderVrVideoMedia = (media) => {

    };

    onMediaLoad = () => {
        if (this.props.onMediaLoad) {
            this.props.onMediaLoad();
        }
        this.forceUpdate();
    }

    refresh = () => {
        this.forceUpdate();
    }

    render() {
        let media = generic.getEntity('media', this.props.media_id);
        if (!media) {
            return <EntityLoader entity='media' id={this.props.media_id} onLoad={this.onMediaLoad} />
        } else if (media.processing) {
            return this.renderProcessing();
        }
        return (
            <div className={this.props.wrapperClassName} style={this.props.wrapperStyle} >
                <div id={"wrapper-" + this.state.id} 
                    className={"media-display-wrapper " + (this.props.subWrapperClassName || "")} 
                        style={{ height: this.props.media_height }}>
                    {this.props.showBadges && <MediaBadges media={media} />}
                    {/* {media.type === 'picture' && this.renderPictureMedia(media)}
                    {media.type === 'video' && this.renderVideoMedia(media)}
                    {media.type === 'video_360' && this.renderVrVideoMedia(media)} */}
                    {(media.type === 'picture') ? this.renderPictureMedia(media) : this.renderVideoMedia(media)}
                </div>
                {this.props.displayName && <div className="card-text card-name">{media && media.name && utils.formatMediaName(media.name)}</div>}
            </div>
        )
    }

}

class MediaPicture extends React.Component {

    constructor(props) {
        super(props);
        this.state = { id: window.generateID(), src: this.props.url, inView: false };
        this.imageRef = React.createRef();
    }

    componentDidMount() {
        if (config.MOBILE_DISPLAY && this.props.preview_url) {
            const observer = new IntersectionObserver(
                (entries) => {
                    const [entry] = entries;
                    if (entry.isIntersecting) {
                        this.setState({ inView: true, src: this.props.preview_url });
                    } else {
                        this.setState({ inView: false, src: this.props.url });
                    }
                },
                {
                    rootMargin: '-50px 0px',
                    threshold: 1, // Adjust as needed
                }
            );

            if (this.imageRef.current) {
                observer.observe(this.imageRef.current);
            }

            this.observer = observer;
        }
    }

    componentWillUnmount() {
        if (this.observer && this.imageRef.current) {
            this.observer.unobserve(this.imageRef.current);
            this.observer.disconnect();
        }
    }

    onMouseOver = () => {
        if (this.props.preview_url) {
            this.setState({ src: this.props.preview_url });
        }
    }

    onMouseOut = () => {
        if (this.props.preview_url) {
            this.setState({ src: this.props.url });
        }
    }

    render() {
        let style = { height: "100%", maxWidth: "100%" },
            width = this.props.width,
            height = this.props.height;
        if (width && height) {
            let wrapper = document.getElementById("wrapper-" + this.props.wrapper_id),
                ratio = width / height,
                wrapper_ratio = wrapper ? wrapper.clientWidth / wrapper.clientHeight : 1;
            if (ratio > wrapper_ratio) {
                style = { width: "100%", maxHeight: "100%" };
            }
        }
        return <img ref={this.imageRef} style={style} src={this.state.src} className={this.props.mediaClassName || ""}
                id={this.state.id} onMouseOver={this.onMouseOver} onMouseOut={this.onMouseOut} />
    }

}
import React from "react";

import ProfilePicture from './ProfilePicture';

export default class extends React.Component {

    state = {  };

    componentDidMount() {

    }

    refresh = () => {
        this.forceUpdate();
    }

    render() {
        let user = this.props.user,
            className = 'profile-mini-display',
            wrapper_className = "col " + (this.props.largeWrapper ? "xl2 l3 m4 s6" : "l4 m6 s12");
        if (this.props.className) {
            className += " " + this.props.className;
        }
        if (this.props.onClick) {
            className += " pointer";
        }
        if(this.props.noColClass) {
            wrapper_className = "";
        }
        return <div className={wrapper_className} style={{ padding: "20px 15px" }}>
            <div className={className} style={{ position: "relative" }}
                onClick={this.props.onClick && this.props.onClick.bind(null, user.id)} >
                    {this.props.displayName && <div className="creator-name">{(user.username) || "Unnamed"}</div>}
                    <ProfilePicture user_id={user.id} style={{height: '200px'}} />
                    {this.props.displayDescription && <div className="creator-name">{(user.description) || ""}</div>}
            </div>
        </div>

    }

}
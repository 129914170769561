import React, { useEffect, useRef, useState } from "react";

import IconButton from "../global/IconButton";

// export default class extends React.Component {

//     render() {
//         let className = "return-title z-depth-1";
//         if (this.props.className) {
//             className += " " + this.props.className;
//         }
//         if (this.props.fixed) {
//             className += " fixed";
//         }
//         return <div className={className}>
//             <IconButton icon="west" onClick={this.props.onReturn} className="return-btn" />
//             <span className="return-title-content">
//                 {this.props.content}
//             </span>
//         </div>
//     }

// }

let return_index = 1;

let return_clicked = {};

class ReturnTitle extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isVisible: false,
            returnIndex: return_index++, // Unique index for each component
        };
        this.componentRef = React.createRef(); // Reference to the component DOM node
        this.on_return_click = this.on_return_click.bind(this);
    }

    componentDidMount() {
        // console.log("History Push: " + this.state.returnIndex);
        history.pushState({ index: this.state.returnIndex }, null); // Create a new history entry

        // Setting up Intersection Observer
        const observer = new IntersectionObserver((entries) => {
            // Assuming a single observed item, handle visibility change
            const [entry] = entries;
            this.setState({ isVisible: entry.isIntersecting }); // Update visibility state
        });

        if (this.componentRef.current) {
            observer.observe(this.componentRef.current); // Start observing
        }

        this.cleanupObserver = () => {
            if (this.componentRef.current) {
                observer.unobserve(this.componentRef.current);
            }
        };

        // popstate event listener
        this.handlePopState = (event) => {
            // Execute action only if component is visible
            if (this.state.isVisible) {
                return_clicked[this.state.returnIndex] = true;
                if (this.props.onReturn) {
                    this.props.onReturn();
                }
            }
        };

        window.addEventListener('popstate', this.handlePopState);
    }

    componentWillUnmount() {
        this.cleanupObserver();
        // console.log("Return clicked: " + return_clicked[this.state.returnIndex]);
        if (!return_clicked[this.state.returnIndex]) {
            // console.log("History Back: " + this.state.returnIndex);
            history.back();
        }
        window.removeEventListener('popstate', this.handlePopState);
    }

    on_return_click() {
        // console.log("Return Clicked: " + this.state.returnIndex);
        return_clicked[this.state.returnIndex] = true;
        if (this.props.onReturn) {
            this.props.onReturn();
            history.back();
        }
    }

    render() {
        let className = "return-title z-depth-1";
        if (this.props.className) {
            className += " " + this.props.className;
        }
        if (this.props.fixed) {
            className += " fixed";
        }

        return (
            <div ref={this.componentRef} className={className}>
                <IconButton icon="west" onClick={this.on_return_click} className="return-btn" />
                <span className="return-title-content">
                    {this.props.content}
                </span>
            </div>
        );
    }
}

export default ReturnTitle;
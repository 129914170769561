import React from "react";
import moment from "moment";
import _ from "lodash";

import config from "../../config";
import utils from "../../lib/utils";
import Subscription from "../../model/subscription";

import LoadingAnimation from "../global/LoadingAnimation";
import InputField from "../global/InputField";
import Button from "../global/Button";
import Title from "../global/Title";

import logo_t18 from "../../images/logo_t18.png";

import PaymentCurrencyTable from "./PaymentCurrencyTable";


export default class extends React.Component {

    state = { view: 'loading', subscriptions: [] };

    componentDidMount = () => {
        this.reloadData();
    };

    componentDidUpdate = (prevProps) => {
        if (prevProps.media_id !== this.props.media_id || prevProps.subscription_id !== this.props.subscription_id
            || prevProps.subscription_id_list !== this.props.subscription_id_list) {
            this.reloadData();
        }
    };

    reloadData = () => {
        this.setState({ view: 'loading' }, () => {
            let req_data = {};
            if (this.props.media_id) {
                req_data.media_id = this.props.media_id;
            } else if (this.props.subscription_id) {
                req_data.sub_id = this.props.subscription_id;
            } else if (this.props.subscription_id_list) {
                req_data.sub_id_list = this.props.subscription_id_list;
            }
            Subscription.loadSubscriptionPaymentData(req_data, (err, data) => {
                if (err || !data.subscriptions || !data.subscriptions.length) {
                    // window.alertPopup('Error', 'An error has occurred while loading subscription payment data.')
                    this.setState({ view: 'error' });
                } else {
                    this.setState({ subscriptions: data.subscriptions, view: 'loaded' });
                }
            });
        });
    };

    render() {
        let view = this.state.view;
        switch (view) {
            case 'loading':
                return <div>
                    <Title text={"Loading payment data..."} />
                    <LoadingAnimation />
                </div>;
            case 'error':
                return <h5>An error has occurred while loading subscription payment data.</h5>;
            case 'loaded':
                return <PaymentPanel subscriptions={this.state.subscriptions} 
                                     onTransactionProcessed={this.props.onTransactionProcessed} 
                                     onTransactionError={this.props.onTransactionError}
                                     reloadData={this.reloadData}
                                     onReturn={this.props.onReturn}
                        />;
        }
    }

}

class PaymentPanel extends React.Component {

    state = { selected_index: null, unit_count: 1 };

    componentDidMount = () => {
        if (this.props.subscriptions.length === 1) {
            this.setState({ selected_index: 0 });
        }
    };

    onUnitCountChange = (value) => {
        this.setState({ unit_count: parseInt(value) });
    }

    render() {
        let subscriptions = this.props.subscriptions,
            selected_index = this.state.selected_index,
            mobile_display = config.MOBILE_DISPLAY;
        if (selected_index === null) {
            return <SubscriptionSelect subscriptions={subscriptions} onSelect={(index) => {
                this.setState({ selected_index: index });
            }} />;
        }
        let subscription = subscriptions[selected_index],
            has_token = (subscription.tokens || []).length > 0,
            action = has_token ? 'extend' : 'buy',
            token = has_token ? subscription.tokens[0] : null,
            unit_time_text = utils.getUnitTimeText(subscription.unit_time),
            unit_count = this.state.unit_count,
            expiration_date_text = action === 'buy' ? 'Expiration Date' : 'New Expiration Date',
            expiration_date = action === 'buy' ? moment().add(unit_count * subscription.unit_time, 's') : moment(token.expiration_time * 1000).add(unit_count * subscription.unit_time, 's');
        return <div className="center-align">
            <div id="pay-app-view">
                {/* <h4>{"Subscription: " + subscription.name}</h4> */}
                <h4 className="black-text">{subscription.price + '$ / ' + unit_time_text}</h4>
                {action === 'extend' && <div>
                    <h5>{"Current expiration date: " + moment(token.expiration_time * 1000).format('YYYY-MM-DD HH:mm')}</h5>
                </div>}
                <div>
                    <InputField label="Unit Count"
                        className="inline-block w-80"
                        type="number"
                        step={1}
                        min={1}
                        style={{width: "120px"}}
                        value={this.state.unit_count}
                        onChange={this.onUnitCountChange} />
                    <h5 className="inline-block lm black-text">{unit_time_text}</h5>
                </div>
                <div>
                    <h5 style={{ marginTop: 0 }}>{expiration_date_text + ': ' + expiration_date.format('YYYY-MM-DD HH:mm')}</h5>
                </div>
                {action === 'extend' && <div className="black-text">You can only extend this subscription using currencies from the same network you first bought it.</div>}
                <PaymentCurrencyTable action={action} 
                                      subscription={subscription} 
                                      unit_count={unit_count}
                                      onTransactionProcessed={this.props.onTransactionProcessed} 
                                      onTransactionError={this.props.onTransactionError}
                                      reloadData={this.props.reloadData}
                                      onReturn={this.props.onReturn}
                                       />
            </div>
        </div>

    }

}

class SubscriptionSelect extends React.Component {

    renderSubscription = (subscription, index) => {
        let unit_time = subscription.unit_time,
            unit_time_text = utils.getUnitTimeText(unit_time),
            has_token = (subscription.tokens || []).length > 0,
            subscription_count = this.props.subscriptions.length;
        return <div className={"col s4 reglisse" + (subscription_count === 2 && index === 0 ? " offset-s2": "")} style={{ padding: "20px"}}>
            <div className={"t18-card"}  style={{ border: "3px solid #de5c89", borderRadius: "10px" }} >
                <div className="card-text">
                    {subscription.name}
                </div>
                <img style={{ maxHeight: "200px", width: "100%" }} src={subscription.image_url || logo_t18} />
                <div className="card-text ">{subscription.price + '$ / ' + unit_time_text}</div>
                <Button style={{width: '100px', fontSize: '20px'}} className="t18-btn" text={has_token ? 'Extend' : 'Buy'} onClick={this.props.onSelect.bind(null, index)} />
            </div>
        </div>
    }

    render() {
        let subscriptions = this.props.subscriptions,
            display = _.map(subscriptions, (subscription, index) => {
                return this.renderSubscription(subscription, index);
            })
        return <div>
            <div className="title-wrap">
                <h5>Select the subscription you wish to buy/extend</h5>
            </div>
            <div className="row">
                {display}
            </div>
        </div>;
    }

}
import React from "react";

import config from "../../config";

import generic from "../../model/generic";

import ReturnTitle from "./ReturnTitle";
import ProfilePicture from "../profile/ProfilePicture";

export default class extends React.Component {

    onOpenUser = () => {
        if (window.showUser) {
            window.showUser(this.props.userId);
        }
    }

    render() {
        let user = generic.getEntity('user', this.props.userId),
            mobile_display = config.MOBILE_DISPLAY,
            show_picture = this.props.showPicture,
            redirect = this.props.redirectAction;
        if (!user) return;
        let name_style = {
            display: 'inline-block',
            position: 'relative',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            width: mobile_display ? 'calc(100% - 5px)' : 'calc(100% - 10px)',
            top: '8px',
            marginLeft: mobile_display ? "5px" : "10px"
        }
        if (show_picture) {
            name_style.width = 'calc(100% - 50px)';
            name_style.top = '5px';
        }
        return <ReturnTitle fixed={this.props.fixed}
            onReturn={this.props.onReturn}
            content={<div style={{ height: "100%" }} className={redirect ? "pointer hover-color" : ""} onClick={redirect ? this.onOpenUser : undefined}>
                {show_picture && <ProfilePicture user_id={user.id}
                    style={{ width: "40px", height: "40px", borderRadius: "50%", overflow: "hidden", position: 'relative', top: "5px" }}
                    imgStyle={{ maxHeight: 'initial', objectFit: 'cover' }}
                />}
                <span style={name_style} >{(user.username) || "Unnamed"}</span>
            </div>} />
    }

}
import React  from 'react';

import User from "../../model/user";

import Modal from "../global/Modal";
import Button from "../global/Button";
import InputField from "../global/InputField";

function checkPassword(password){
    return password.match(/[a-zA-Z0-9~!@#$%^&\(\)_+\-={}\[\];\\\',.]{8}/)
        && password.match(/[a-z]{1}/)
        && password.match(/[A-Z]{1}/)
        && password.match(/[0-9]{1}/);
}

export default class UserPasswordForm extends React.Component {

    state = { opened: false, openModal: null, closeModal: null, previous_password_input: "", password_input: "", confirm_password_input: "" };

    componentDidMount() {
        window.openUserPasswordForm = this.openModal;
    }

    getOpen = (open) => {
        this.setState({ openModal: open });
    };

    openModal = (options, close_cb = () => {}) => {
        let state = { 
            opened: true, 
            previous_password_input: "", 
            password_input: "", 
            confirm_password_input: "",
            undismissible: options.undismissible, 
            close_cb: close_cb
        };

        this.setState(state, this.state.openModal);
    };

    getClose = (close) => {
        this.setState({ closeModal: close });
    };

    afterOpen = () => {
        //$(".lean-overlay").last().addClass("alert-popup-overlay");
    };

    afterClose = () => {
        this.setState({opened: false}, this.state.close_cb);
    };

    onPreviousPasswordChange = (value) => {
        this.setState({ previous_password_input: value });
    }

    onPasswordChange = (value) => {
        this.setState({ password_input: value });
    }

    onConfirmPasswordChange = (value) => {
        this.setState({ confirm_password_input: value });
    }

    checkPasswordValidity = () => {
        let user = User.getUser(), password = this.state.password_input, previous_password = this.state.previous_password_input,
            confirm_password = this.state.confirm_password_input;
        let check = true;
        if (confirm_password !== password) {
            check = false;
        } else if (!checkPassword(password)) {
            check = false;
        } else if (!checkPassword(confirm_password)) {
            check = false;
        } else if (user.has_password) {
            if (!checkPassword(previous_password)) {
                check = false;
            } else if (previous_password === password) {
                check = false;
            }
        }
        return check;
    }

    onSubmit = () => {
        let previous_password = this.state.previous_password_input;
        let password = this.state.password_input;
        let user = User.getUser(), data = { pwd: password };
        if (user.has_password) {
            data.previous_pwd = previous_password;
        }
        User.changePassword(data, (err) => {
            if (err) {
                let msg = "An error has occurred while updating your password.";
                if (err.message === 'previous-password-incorrect') {
                    msg = "Your previous password is incorrect.";
                }
                window.alertPopup('Error', msg);
            } else {
                this.setState({ editing_password: false, previous_password_input: "", password_input: "", confirm_password_input: "" });
                window.toast("Password updated successfully.");
            }
        });
        this.state.closeModal();
    };

    render() {
        let user = User.getUser();
        let validate_password_disabled = !this.checkPasswordValidity();
        const content = this.state.opened ? <div id="user-password-form-content" className="reglisse">
            <h3 style={{margin: 0}}>Update Password</h3>
            <div style={{marginTop: '50px'}}>
            {user.has_password ? <div>
                    <InputField label="Previous password" style={{ display: 'inline-block', width: '300px' }}
                        value={this.state.previous_password_input} onChange={this.onPreviousPasswordChange} type="password" />
                </div> : undefined}
                <div>
                    <InputField label="Password" style={{ display: 'inline-block', width: '300px' }}
                        value={this.state.password_input} onChange={this.onPasswordChange} type="password" />
                </div>
                <div>
                    <InputField label="Confirm Password" style={{ display: 'inline-block', width: '300px' }}
                        value={this.state.confirm_password_input} onChange={this.onConfirmPasswordChange} type="password" />
                </div>
                {validate_password_disabled && <div className="red-text">{"Password needs at least 8 characters, 1 uppercase, 1 lowercase, 1 digit and needs to be different than previous."}</div>}
            </div>
            <div className="modal-footer">
                <Button text={'Submit'} className='t18-btn' onClick={this.onSubmit} disabled={validate_password_disabled} style={{fontSize: "20px"}} />
                <Button text={"Cancel"} className='t18-btn lm black' onClick={this.state.closeModal} large={true}
                        style={{fontSize: "20px"}}/>
            </div>
        </div> : undefined;
        return (
            <Modal id="user-password-form"
                   content={content}
                   getOpen={this.getOpen}
                   getClose={this.getClose}
                   afterOpen={this.afterOpen}
                   afterClose={this.afterClose}
                   undismissible={this.state.undismissible}
            />
        )
    }
};

import API from '../lib/api';

const self = {

    loadSubscriptionPaymentData: (data, cb) => {
        API.getPaymentData(data, (data) => {
            return cb(null, data);
        }, (err) => {
            console.error(err);
            cb(err);
        });
    },

    verifySubscriptionPaymentData: (subscription_id, network, cb) => {
        API.verifySubscriptionPaymentData(subscription_id, network, (data) => {
            return cb(null, data);
        }, (err) => {
            console.error(err);
            cb(err);
        });
    },

    buySubscription: (subscription_id, target_address, network, currency_chain_id, unit_count, cb) => {
        API.buySubscription(network, target_address, subscription_id, unit_count, currency_chain_id, (data) => {
            return cb(null, data.tx_id);
        }, (err) => {
            console.error(err);
            //ex: {message: "Internal error : contract => {\"reason\":\"cannot estimate gas; transaction may fail or may require manual gas limit\",\"code\":\"UNPREDICTABLE_GAS_LIMIT\",\"error\":{\"reason\":\"execution reverted: ERCU6\",\"code\":\"UNPREDICTABLE_GAS_LIMIT\",\"method\"
            cb(err);
        });
    },

    extendSubscription: (token_id, currency_chain_id, unit_count, cb) => {
        API.extendSubscription(token_id, unit_count, currency_chain_id, (data) => {
            return cb(null, data.tx_id);
        }, (err) => {
            console.error(err);
            cb(err);
        });
    }

}

export default self;
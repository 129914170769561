import React from 'react';
import OpenApp from "react-open-app";

import web3 from "../../lib/web3";
import config from "../../config";

import Button from "../global/Button";
import LoadingAnimation from "../global/LoadingAnimation";

import metamask_icon from "../../images/wallet_icons/metamask.png";
import ledger_icon from "../../images/wallet_icons/ledger.png";
import coinbase_icon from "../../images/wallet_icons/coinbase.png";
import wallet_connect_icon from "../../images/wallet_icons/wallet_connect.png";

const wallet_list = [
    { name: 'Metamask', icon: metamask_icon, id: 'MetaMask', deep_link: config.deep_links.metamask },
    { name: 'Coinbase', icon: coinbase_icon, id: 'Coinbase', deep_link: config.deep_links.coinbase },
    { name: 'WalletConnect', icon: wallet_connect_icon, id: 'WalletConnect' },
    //{name: 'Ledger', icon: ledger_icon, id: 'Ledger'}
]

export default class WalletConnectPanel extends React.Component {

    refresh_interval = null;

    connecting = false;

    componentDidMount = () => {
        this.refresh_interval = setInterval(this.refresh, 500);
    };

    componentWillUnmount = () => {
        clearInterval(this.refresh_interval);
    };

    refresh = () => {
        this.forceUpdate();
    };

    onConnect = (wallet_id) => {
        if (wallet_id) {
            this.connecting = true;
            this.refresh();
            web3.connectWallet(wallet_id).then(() => {
                this.props.onWalletConnected();
            }).catch((err) => {
                this.connecting = false;
                if (err.toString() === 'Error: User closed modal') {
                    web3.resetWallet("WalletConnect");
                    return this.refresh();
                }
                if (wallet_id === 'Ledger') {
                    window.alertPopup('Wallet error', 'It seems no Ledger device is connected. Please connect your Ledger device and try again. If this error persists, please contact our support on our Discord server.')
                    return this.refresh();
                }
                if (err.message && err.message.includes("Already processing eth_requestAccounts")) {
                    this.connecting = true;
                    return this.refresh();
                }
                console.log('Wallet connect error : ', err);
                console.log(err);
                window.test_err = err;
                window.alertPopup('Wallet error', 'An error has occurred while connecting wallet.');
            });
        }
    };

    renderButton = (wallet) => {
        return <div className="center-align tm">
            <Button className="t18-btn" style={{
                fontSize: "32px",
                height: "58px",
                padding: "10px 20px",
                borderRadius: "15px",
                marginTop: config.MOBILE_DISPLAY ? "0" : "15px",
                width: config.MOBILE_DISPLAY ? '100%' : '320px',
                textAlign: 'left'
            }}
                text={<span>
                    <img style={{ height: '40px', padding: '5px', backgroundColor: 'white', borderRadius: '5px', marginRight: '15px' }}
                        src={wallet.icon} />
                    <span style={{
                        position: 'relative',
                        bottom: config.MOBILE_DISPLAY ? '10px' : "10px"
                    }}>{wallet.name}</span>
                </span>}
                onClick={this.onConnect.bind(null, wallet.id)}
                disabled={web3.getWallet(wallet.id) === null}
            />
        </div>
    }

    renderDeepLinkButton = (wallet) => {
        if(web3.getWallet(wallet.id)){
            return this.renderButton(wallet);
        }
        return <div>
            <span className="wallet-connect-deep-link-button">
                <img className="wallet-connect-deep-link-icon" src={wallet.icon} />
                <OpenApp className="wallet-connect-deep-link" href={wallet.deep_link}>{wallet.name}</OpenApp>
            </span>
        </div>
    }

    renderConnectButtons = () => {
        let buttons = [],
            mobile_display = config.MOBILE_DISPLAY;
        for (let wallet of wallet_list) {
            if(mobile_display && wallet.deep_link){
                buttons.push(this.renderDeepLinkButton(wallet));
            }else if(!mobile_display){
                buttons.push(this.renderButton(wallet));
            }
        }
        return buttons;
    }

    render() {
        return <div>
            {this.connecting ? <AwaitingWalletConnection /> : <div>
                {this.renderConnectButtons()}
            </div>}
        </div>
    }

}

class AwaitingWalletConnection extends React.Component {

    render() {
        return <div>
            <h5>Waiting for wallet connection ...</h5>
            <LoadingAnimation />
        </div>
    }

}
import React from "react";

import config from "../../config";

import ToolTipped from "../global/ToolTipped";

export default class ReturnIconButton extends React.Component {

    state = { id: (this.props.id ? this.props.id : window.generateID()) + "-return-icon-button" };

    onClick = () => {
        if (!this.props.disabled && this.props.onClick)
            this.props.onClick()
    }

    render() {
        let className = `material-icons icon-btn white-text z-depth-2`,
            mobile_display = config.MOBILE_DISPLAY;
        if (this.props.disabled) {
            className += " disabled grey-text";
        } else {
            className += " clickable";
        }
        if (this.props.className) {
            className += ` ${this.props.className}`;
        }
        let style = {
            display: 'inline-block',
            // position: 'fixed',
            // bottom: mobile_display ? '10px' : '20px',
            // right:  mobile_display ? '10px' : '20px',
            width: "60px",
            height: '60px',
            backgroundColor: '#de5c89',
            borderRadius: '50%',
            padding: '10px',
            paddingLeft: '7px',
            fontSize: '40px',
            zIndex: 100
        };
        if (this.props.style) {
            Object.assign(style, this.props.style);
        }
        let tooltip_style = {
            position: 'fixed',
            bottom: mobile_display ? '10px' : '20px',
            right: mobile_display ? '10px' : '20px',
            width: "60px",
            height: '60px',
            zIndex: 100,
        }
        if (mobile_display) {
            Object.assign(style, tooltip_style);
        }
        let icon_btn = <i id={this.state.id} data-test={this.state.id} onClick={this.onClick}
            style={style}
            className={className} >arrow_back_ios_new</i>;
        if (mobile_display) {
            return icon_btn;
        }
        return <ToolTipped content={icon_btn} tooltip="Return" style={tooltip_style} />
    }
}


const dev_config = {

    contract: {
        enable_beta_minting: true,
        land_network_name: 'sepolia',
        wallet_connect_project_id: '89f68ff9b7b3b9291e4ab056e5f7960c',
        enabled_networks: ['sepolia'],
        land: {
            sepolia: {
                address: '0x3D415DDF8b7c02354E211Bc681e18553d1b04063'
            }
        },
        subscription: {
            name: 'T18Subscription',
            networks: {
                sepolia: {
                    address: '0x308D081b254021b2cff1A53d422Db32dC4771f55'
                },
                mumbai: {
                    address: '0x04a7BBd4FdBFEe133266318163F0a073Fa0C1dfD'
                }
            }
        },
        payment: {
            name: 'T18Payment',
            networks: {
                sepolia: {
                    address: '0x0F3FBe2A188A1ed300d716A2f7bF4Cb8D70bbDEE'
                },
                mumbai: {
                    address: '0x607766a8C336246927bE194b8C4D44d4757796e9'
                }
            }
        },
        usd_decimals: 8
    }

}

export default dev_config;
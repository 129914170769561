import React from "react";
import moment from "moment";

import config from "../../../config";
import utils from "../../../lib/utils";
import generic from "../../../model/generic";
import User from "../../../model/user";

import Title from "../../global/Title";
import Button from "../../global/Button";

import UserReturnTitle from "../../common/UserReturnTitle";
import ProfilePicture from "../../profile/ProfilePicture";
import MobileMediaHeader from "./MobileMediaHeader";

export default class extends React.Component {

    componentDidMount = () => {
        window.printExploreSelectedMedia = () => {
            console.log(generic.getEntity('media', this.props.mediaId));
        }
    }

    renderPaidAccessBlock = (media) => {
        if (media.owner === User.getUserId()) {
            return;
        }
        let mobile_display = config.MOBILE_DISPLAY,
            buy_disabled = false,
            buy_tooltip;
        if (media.paid_access && media.paid_access_denied) {
            if(mobile_display && false){
                buy_disabled = true;
                buy_tooltip = "Payment on mobile coming soon. Please use a desktop device to buy subscription for now.";
            }else if (!User.isAuthenticated()) {
                buy_disabled = true;
                buy_tooltip = "You must be logged in to buy subscription.";
            } else if (!User.hasAddress()) {
                buy_disabled = true;
                buy_tooltip = "You must associate a wallet to your account to buy subscription.";
            }
        }
        return <div className="bm">
            {media.paid_access_denied ? <div>
                <Title text="This media is not available for free." fontSize={"24px"} themeColor={true} style={{ margin: 0 }} />
                <div className="center-align tm">
                    <Button className="t18-btn"
                        text={"Subscribe"}
                        onClick={this.props.onBuyClick}
                        style={{ paddingLeft: '20px', paddingRight: '20px', fontSize: '20px' }}
                        disabled={buy_disabled}
                        tooltip={buy_tooltip}
                    />
                </div>
                {mobile_display && false && <div className="center-align tm">
                    Payment on mobile coming soon!
                </div>}
            </div> : <div>
                <Title text="You paid the access to this media." fontSize={"20px"} themeColor={true} />
            </div>}
        </div>
    }

    render() {
        let media = generic.getEntity('media', this.props.mediaId),
            creator = generic.getEntity('user', media.owner),
            creation_date = media.creation_date && moment(media.creation_date).format("DD/MM/YYYY");
        // console.log(media);

        let className = "selected-media-tab col s12 m3 white-text";
        if (!this.props.show) {
            className += " hide";
        }
        if (config.MOBILE_DISPLAY) {
            return <div className={className} style={{ padding: '10px 30px' }}>
                <div>
                    {media.paid_access && this.renderPaidAccessBlock(media)}
                    <Title text={'Name: ' + utils.formatMediaName(media.name)}
                        leftAlign={true}
                        style={{ fontSize: "20px", wordBreak: "break-word" }}
                        wrapperStyle={{ display: 'inline-block' }}
                    />
                    {creation_date && <Title text={'Published on: ' + creation_date} leftAlign={true} style={{ fontSize: "20px", wordBreak: "break-word" }} />}
                </div>
            </div>
        }
        className += " h-100";
        return <div className={className} style={{ borderRight: '1px solid white', padding: 0 }}>
            <UserReturnTitle userId={media.owner} onReturn={this.props.onReturn} showPicture={true} redirectAction={this.props.showUser} />
            <div style={{ padding: '30px'}} >
                <Title text={'Name: ' + utils.formatMediaName(media.name)} leftAlign={true} style={{ fontSize: "30px", wordBreak: "break-word" }} />
                {creator && <Title text={'Creator: ' + (creator.username || "Unnamed")} leftAlign={true} style={{ fontSize: "30px", wordBreak: "break-word" }} />}
                {creation_date && <Title text={'Published on: ' + creation_date} leftAlign={true} style={{ fontSize: "30px", wordBreak: "break-word" }} />}
                {media.paid_access && this.renderPaidAccessBlock(media)}
            </div>
        </div>
    }

}
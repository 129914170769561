import local_config from './configs/local.config.js';
import dev_config from './configs/dev.config.js';
import beta_confif from './configs/beta.config.js';

function isDesktopDevice() {
    let agent = navigator.userAgent;
    return agent.indexOf('Linux') > -1 || agent.indexOf('Windows') > -1 || agent.indexOf('Mac') > -1;
}

function checkBrowser() {
    let browser = "";
    let c = navigator.userAgent.search("Chrome");
    let f = navigator.userAgent.search("Firefox");
    let m8 = navigator.userAgent.search("MSIE 8.0");
    let m9 = navigator.userAgent.search("MSIE 9.0");
    if (c > -1) {
        browser = "Chrome";
    } else if (f > -1) {
        browser = "Firefox";
    } else if (m9 > -1) {
        browser = "MSIE 9.0";
    } else if (m8 > -1) {
        browser = "MSIE 8.0";
    }
    return browser;
}

let host = window.location.host,
    use_testnet = host === 'dev.land.terminal18.org' || host.indexOf('localhost') > -1;

function detectEnv() {
    let env = 'prod';
    if (host.includes('dev')) {
        env = 'dev';
    } else if (host.includes('beta')) {
        env = 'beta';
    } else if (host.includes('localhost')) {
        env = 'local';
    }
    return env;
}

let env = detectEnv();

const config = {

    theme: {
        color: '#de5c89',
        light_color: '#ff80ab',
    },

    MOBILE_RES_THRESHOLD: isDesktopDevice() ? 750 : 1054,
    MOBILE_DISPLAY: window.innerWidth < (isDesktopDevice() ? 750 : 1054),

    browser: checkBrowser(),

    map_size: 147,

    price_decimals: 10000,

    contract: {
        enable_beta_minting: false,
        land_network_name: 'sepolia',
        wallet_connect_project_id: '89f68ff9b7b3b9291e4ab056e5f7960c',
        enabled_networks: use_testnet ? ['sepolia'] : [],
        land: {
            sepolia: {
                address: '0x3D415DDF8b7c02354E211Bc681e18553d1b04063'
            },
            homestead: {
                address: '0x33E5923D15067BDDCd8C664dD3BdF425539EFfc4'
            }
        },
        subscription: {
            name: 'T18Subscription',
            networks: {
                sepolia: {
                    //address: '0xf538F85d24B9a7835285e29eE757BDD9F6329ac5'
                    address: env === 'local' ? '0xf538F85d24B9a7835285e29eE757BDD9F6329ac5' : '0xb5a2C7edaBD1a1F41fcc08B7e4d310e6aa713B50'
                }
            }
        },
        usd_decimals: 8
    },

    enable_access_handling: false,

    amazon: {
        credentials: {
            accessKeyId: "AKIAWERE7FVJ3B5LXRMF",
            secretAccessKey: "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",
            region: 'eu-west-3'
        },
        buckets: {
            upload: 't18-media-upload'
        }
    },

    max_image_size: 4096,
    max_video_size: 4294967296,

    max_image_count: 20,
    max_video_count: 5,

    video_formats: ['r480p', 'r720p', 'r1080p', 'r1440p', 'r4k'],

    picture_formats: ['r256p', 'r512p', 'r1024p', 'r2048p', 'r4096p'],

    picture_format_sizes: {
        'r256p': 256,
        'r512p': 512,
        'r1024p': 1024,
        'r2048p': 2048,
        'r4096p': 4096
    },

    display_dollar_price: true,

    land_handlers_whitelist: [
        '0x5c07cfbd894e0f248b186f2316ff49844ea0b974', '0x36e91fc3541fa75a2d416ba6ae48056db736caa0',
        '0xfc6c5ee0bb5e570f63d0f0e7d21b317e6dbbc6e3', '0x8e3508fb57e6425aabded66fb0a00429613baa12',
        '0x159d4d6a45a94035389b4652dfa4d82c30484273', '0x0e7e0ed2acb1284d82365697698e3f3bd965576d',
        '0xf43bd2eb01355379f7faee7e7a00ff208edc2ff8', '0xc6c9614f8e751988ae1fe35cc027ea608a9ee6d7',
        '0x2b6de530f788fb77ab8eda62bb5ed47657bfecdf', '0x1D21Ab2FfbeA84c28Feb49Cc1D102c75eB3A4b3F'
    ],

    default_image_placeholder_url: "https://terminal18.b-cdn.net/logo/logo_t18.png",

    locked_media_url: 'https://terminal18.b-cdn.net/misc/lock/lock_614x614.jpg',

    deep_links: {
        metamask: 'https://metamask.app.link/dapp/dev.land.termina18.org/media',
        coinbase: 'https://go.cb-w.com/dapp?cb_url=https%3A%2F%2Fdev.land.terminal18.org%2Fmedia',
    }

};

if (env === 'local') {
    console.log('Using local config');
    Object.assign(config, local_config);
} else if (env === 'dev') {
    console.log('Using dev config');
    Object.assign(config, dev_config);
} else if (env === 'beta') {
    console.log('Using beta config');
    Object.assign(config, beta_confif);
}

window.MOBILE_DISPLAY = config.MOBILE_DISPLAY;

window.common_config = config;

export default config;
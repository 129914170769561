import web3 from './web3';
import { Contract, parseEther, formatEther } from 'ethers';

const MINT_ADDRESS = {
    T18: {
        sepolia: "0xf7d3eebc5c73a87e3f86c8858f82e461f95f1e13",
        mumbai: "0x3D415DDF8b7c02354E211Bc681e18553d1b04063"
    },
    USDT: {
        sepolia: "0x7169D38820dfd117C3FA1f22a697dBA58d90BA06",
        // mumbai: https://faucet.softbinator.com/
    }
},
MINT_AMOUNT = {
    T18: 100000,
    USDT: 1000
},
MINT_DECIMALS = {
    T18: 18,
    USDT: 6
};

function getT18Contract(network) {
    let t18_contract = new Contract(MINT_ADDRESS.T18[network], [
        {
            "constant": false,
            "inputs": [
                { "name": "to", "type": "address" },
                { "name": "amount", "type": "uint256" }
            ],
            "name": "mint",
            "outputs": [],
            "type": "function"
        }
    ], web3.signer);
    return t18_contract;
}

function getUSDTContract(network) {
    let usdt_contract = new Contract(MINT_ADDRESS.USDT[network], [
        {
            "constant": false,
            "inputs": [
                { "name": "amount", "type": "uint256" }
            ],
            "name": "_giveMeATokens",
            "outputs": [],
            "type": "function"
        }
    ], web3.signer);
    return usdt_contract;
}

const self = {

    mintEnabled: (network, symbol) => {
        return MINT_ADDRESS[symbol] && MINT_ADDRESS[symbol][network];
    },

    getMintAmount: (symbol) => {
        return MINT_AMOUNT[symbol];
    },

    mintCurrency: async (network, symbol, target, on_sent, on_processed) => {
        let amount = ((MINT_AMOUNT[symbol] + '') + '0'.repeat(MINT_DECIMALS[symbol]));
        if(symbol === 'T18'){
            return await self.mintT18(network, target, amount, on_sent, on_processed);
        }else if(symbol === 'USDT'){
            return await self.mintUSDT(network, target, amount, on_sent, on_processed);
        }
    },

    mintT18: async (network, target, amount, on_sent, on_processed) => {
        let tx;
        // console.log('Minting T18');
        try{
            let t18_contract = getT18Contract(network);
            tx = await t18_contract.mint(target, amount);
            on_sent(null, tx.hash);
            // console.log('Mint transaction sent.');
        }catch(err){
            console.log('Mint transaction error:');
            console.log(err);
            return on_sent(err);
        }
        const receipt = await tx.wait();
        if (receipt.status === 1) {
            console.log("Mint transaction was successful!");
            on_processed();
        } else if (receipt.status === 0) {
            console.log("Mint transaction failed.");
            on_processed("transaction-failed");
        } else {
            console.log("Unexpected mint transaction status:", receipt.status);
            on_processed("transaction-failed");
        }
    },

    mintUSDT: async (network, target, amount, on_sent, on_processed) => {
        let tx;
        try{
            let usdt_contract = getUSDTContract(network);
            tx = await usdt_contract._giveMeATokens(amount);
            on_sent(null, tx.hash);
        }catch(err){
            console.log('Mint transaction error:');
            console.log(err);
            return on_sent(err);
        }
        console.log('Mint transaction sent.')
        try{
            const receipt = await tx.wait();
            if (receipt.status === 1) {
                console.log("Mint transaction was successful!");
                on_processed();
            } else if (receipt.status === 0) {
                console.log("Mint transaction failed.");
                on_processed("transaction-failed");
            } else {
                console.log("Unexpected mint transaction status:", receipt.status);
                on_processed("transaction-failed");
            }
        }catch(err){
            console.log('Mint transaction await error:');
            console.log(err);
            on_processed(err);
        }
        
    }

}

export default self;
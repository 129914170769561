import React from "react";
import _ from 'lodash';

import utils from "../../lib/utils";

import FilterTab from "./FilterTab";
import GridDisplayPanel from "./GridDisplayPanel";


export default class extends React.Component {

    state = { id: this.props.id || window.generateID(), filters: { } };

    constructor(props) {
        super(props);
        this.state.filters = _.cloneDeep(props.defaultFilters || {});
    }

    onFiltersUpdate = (filters) => {
        this.setState({ filters: filters });
    }

    refresh = () => {
        this.forceUpdate();
    }

    render() {
        let content_list = this.props.contentList,
            filters = this.state.filters,
            filtered_content_list = utils.getFilteredTargets(content_list, filters, {search_target: this.props.searchTarget, search_function: this.props.searchFunction});
        return <div id={this.state.id} className={"h-100 row" + (this.props.show ? '' : ' hide')} >
            <FilterTab id={this.state.id + "-filter"}
                       filters={filters} 
                       searchTarget={this.props.searchTarget}
                       enabledFilters={this.props.enabledFilters}
                       onFiltersUpdate={this.onFiltersUpdate}
                       topFilterContent={this.props.topFilterContent} 
                       targets={content_list} />
            <GridDisplayPanel id={this.state.id + "-grid"} 
                              rowHeight={this.props.rowHeight} 
                              contentList={filtered_content_list}
                              onScrolled={this.props.onScrolled} 
                              searchTarget={this.props.searchTarget}
                              noContentText={this.props.noContentText}
                              renderContent={this.props.renderContent} />
        </div>
    }

}
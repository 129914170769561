import React from 'react';

import config from '../config.js';

import LoadingAnimation from './global/LoadingAnimation.js';

const logo_url = "https://terminal18.b-cdn.net/logo/logo1024.png";

export default class InitialLoading extends React.Component {

    state = { loading: 1 };

    componentDidMount() {
        window.setInitialLoadingValue = this.setLoadingValue;
    }

    setLoadingValue = (value) => {
        this.setState({ loading: Math.round(value) });
    };

    render() {
        return (
            <div id="initial-loading" data-test={"initial-loading"}>
                <div id="initial-loading-container" className="z-depth-2">
                    <img src={logo_url} className="logo" alt="" />
                    <LoadingAnimation id="initial-loading-animation" type="linear" value={this.state.loading}
                                      progressColor={config.theme.color}
                                      backgroundProgressColor={config.theme.light_color} />
                </div>
            </div>
        )
    }
}


import React from "react";
import _ from 'lodash';

import config from "../../config";
import utils from "../../lib/utils";
import User from "../../model/user";
import generic from "../../model/generic";

import InputField from "../global/InputField";
import IconButton from "../global/IconButton";
import CheckBox from "../global/CheckBox";
import Title from "../global/Title";
import ToolTipped from "../global/ToolTipped";

const default_filter_list = [
    'subscription',
    'search',
    'type',
    'access',
    'tags'
]

const type_filters = [
    { key: 'all', display: 'All', tooltip: 'Show all types of media' },
    { key: 'picture', display: 'Picture', tooltip: 'Show only pictures' },
    { key: 'video', display: 'Video', tooltip: 'Show only videos' },
    { key: 'video_360', display: 'VR', tooltip: 'Show only VR videos' }
];

const access_filters = [
    { key: 'all', display: 'All', tooltip: 'Show all types of media' },
    { key: 'free', display: 'Free', tooltip: 'Show only free media' },
    { key: 'subscription', display: 'Subscription', tooltip: 'Show only media available through subscription' },
    { key: 'paid', display: 'Paid', tooltip: 'Show only purchased media' }
];

export default class extends React.Component {

    state = { id: this.props.id || window.generateID(), editing_search: false, search: "", expanded: false };

    componentDidMount() {
        this.debouncedUpdateFilters = _.debounce(this.updateFiltersSearch, 500);
        window.onSearchClick = this.onExpandToggle;
        window.filter_tabs = window.filter_tabs || {};
        window.filter_tabs[this.state.id] = this;
        this.handlePopState = (event) => {
            if(this.state.expanded){
                this.onExpandToggle(true);
            }
        };

        window.addEventListener('popstate', this.handlePopState);
    }

    componentWillUnmount() {
        window.removeEventListener('popstate', this.handlePopState);
    }

    getFilters = () => {
        return _.cloneDeep(this.props.filters);
    }

    onSearchChange = (value) => {
        this.setState({ search: value, editing_search: true });
    }

    onValidateSearch = () => {
        this.setState({ editing_search: false }, () => {
            this.updateFiltersSearch();
            this.onExpandToggle();
        });
    }

    onClearSearch = () => {
        this.setState({ search: "", editing_search: false }, this.updateFiltersSearch);
    };

    onFiltersUpdate = (filters, verify_tags) => {
        let tags = filters.tags || [];
        if (verify_tags && tags.length > 0) {
            let filtered_targets = utils.getFilteredTargets(this.props.targets, filters),
                filtered_tags = utils.computeExistingTags(filtered_targets),
                remove_tags = [];
            for (let tag of tags) {
                if (!filtered_tags.includes(tag)) {
                    remove_tags.push(tag);
                }
            }
            if (remove_tags.length > 0) {
                tags = tags.filter(tag => !remove_tags.includes(tag));
                filters.tags = tags;
            }
        }
        this.props.onFiltersUpdate(filters);
    }

    updateFiltersSearch = () => {
        let value = this.state.search;
        let filters = this.getFilters();
        filters.search = value;
        this.onFiltersUpdate(filters, true);
    }

    onTypeFilterClick = (type) => {
        let filters = this.getFilters();
        filters.type = type;
        this.onFiltersUpdate(filters, true);
    }

    onAccessFilterClick = (access) => {
        let filters = this.getFilters();
        filters.access = access;
        this.onFiltersUpdate(filters, true);
    }

    onTagFilterClick = (tag) => {
        let filters = this.getFilters(),
            tags = filters.tags || [];
        if (tag === 'all') {
            tags = [];
        } else if (tags.indexOf(tag) !== -1) {
            tags.splice(tags.indexOf(tag), 1);
        } else {
            tags.push(tag);
        }
        filters.tags = tags;
        this.onFiltersUpdate(filters);
    }

    onSubscriptionFilterChange = (subscription_id) => {
        let filters = this.getFilters(),
            subscription_filter = filters.subscriptions || [];
        if (subscription_filter.indexOf(subscription_id) !== -1) {
            subscription_filter.splice(subscription_filter.indexOf(subscription_id), 1);
        } else {
            subscription_filter.push(subscription_id);
        }
        filters.subscriptions = subscription_filter;
        this.onFiltersUpdate(filters);
    }

    onExpandToggle = (back_event) => {
        let expanded = this.state.expanded;
        this.setState({ expanded: !expanded }, () => {
            if(!expanded){
                history.pushState({}, null);
            }else{
                if(!back_event){
                    history.back();
                }
            }
        });
    }

    renderExpandToggle = () => {
        return <div style={{
            display: 'inline-block', position: 'absolute', bottom: '10px', left: '10px', zIndex: 1000
        }}>
            <IconButton icon="search" className="t18-btn z-depth-2" color="white"
                onClick={() => this.onExpandToggle()}
                style={{ fontSize: '42px', borderRadius: '50%', padding: '10px' }}
            />
        </div>
    }

    renderSearchFilter = (filters) => {
        let editing_search = this.state.editing_search,
            search = this.state.search,
            icon_style = {
                fontSize: '36px',
                position: "relative",
                top: "13px",
                marginLeft: "5px"
            };
        return <div>
            <div className="center-align">
                <h5 className="white-text inline-block">Search</h5>
            </div>
            <div style={{ width: "calc(90% - 45px)", marginLeft: '5%', display: "inline-block" }}>
                <InputField className="white-text" value={search} onChange={this.onSearchChange} />

            </div>
            <div style={{display: "inline-block"}}>
                {editing_search && <IconButton icon="check" color="green" onClick={this.onValidateSearch} style={icon_style} />}
                {!editing_search && search !== "" && <IconButton icon="clear" color="red" onClick={this.onClearSearch} style={icon_style} />}
            </div>
        </div>
    }

    renderSubscriptionFilter = (filters) => {
        let subscription_filter = filters.subscriptions || [],
            subscription_tokens = generic.getOwnedEntities('subscription_token'),
            display = [];
        if (subscription_tokens.length === 0) {
            return null;
        }
        for (let token of subscription_tokens) {
            let subscription = generic.getEntity('subscription', token.subscription_id),
                owner = generic.getEntity('user', subscription.owner);
            display.push(<div className="subscription-filter-item">
                <CheckBox label={(owner.username || "Unnamed") + "  (" + (subscription.media_id_list || []).length + " medias)"}
                    checked={subscription_filter.indexOf(token.subscription_id) !== -1}
                    onClick={this.onSubscriptionFilterChange.bind(null, token.subscription_id)}
                />
            </div>)
        }
        return <div className="subscription-filter">
            <div className="subscription-filter-title">
                <Title text="Subscriptions" />
            </div>
            <div className="subscription-filter-items">
                {display}
            </div>
        </div>
    }

    renderTypeFilters = () => {
        let filters = this.getFilters(),
            type = filters.type || 'all',
            enabled_filters = this.props.enabledFilters || default_filter_list;
        return enabled_filters.includes('type') && <div>
            <div className="center-align">
                <h5 className="white-text inline-block">Type</h5>
            </div>
            <div>
                {_.map(type_filters, (type_filter) => {
                    return <ToolTipped content={<div
                        onClick={this.onTypeFilterClick.bind(null, type_filter.key)}>{type_filter.display}
                    </div>} tooltip={type_filter.tooltip} style={{ display: 'inline-block' }} className={'z-depth-1 clickable tag-badge' + (type === type_filter.key ? " selected" : "")} />
                })}
            </div>
        </div>
    }

    renderAccessFilters = () => {
        let filters = this.getFilters(),
            access = filters.access || 'all',
            enabled_filters = this.props.enabledFilters || default_filter_list,
            access_filter_list = access_filters.slice(),
            media_access_filter_style = User.isAuthenticated() || config.MOBILE_DISPLAY ? { display: 'inline-block' } : { display: 'inline-block', width: '29%', margin: '10px 2%' };
        if (!User.isAuthenticated()) {
            access_filter_list.pop();
        }
        return enabled_filters.includes('access') && <div>
            <div className="center-align">
                <h5 className="white-text inline-block">Access</h5>
            </div>
            <div>
                {_.map(access_filter_list, (access_filter) => {
                    return <ToolTipped content={<div
                        onClick={this.onAccessFilterClick.bind(null, access_filter.key)}>{access_filter.display}
                    </div>} tooltip={access_filter.tooltip} style={media_access_filter_style} className={'z-depth-1 clickable tag-badge' + (access === access_filter.key ? " selected" : "")} />;
                })}
            </div>
        </div>
    }

    refresh = () => {
        this.forceUpdate();
    }

    render() {
        let filters = this.getFilters(),
            tags = filters.tags || [],
            filtered_targets = utils.getFilteredTargets(this.props.targets, filters, { search_target: this.props.searchTarget }),
            filtered_tags = utils.computeExistingTags(filtered_targets),
            enabled_filters = this.props.enabledFilters || default_filter_list;
        filtered_tags.unshift('all');
        let className = "media-filter-tab col s12 m3 h-100 no-padding";
        if (config.MOBILE_DISPLAY) {
            if (!this.state.expanded) {
                if (this.props.showToggle) {
                    return this.renderExpandToggle();
                } else {
                    return null;
                }
            } else {
                className += " z-depth-2";
            }
        }
        let media_access_filter_style = User.isAuthenticated() || config.MOBILE_DISPLAY ? {} : { width: '29%', margin: '10px 2%' };
        return <div id={this.state.id} className={className}>
            {config.MOBILE_DISPLAY && <IconButton icon="clear"
                onClick={() => this.onExpandToggle()}
                style={{ color: "#de5c89", fontSize: '42px', borderRadius: '50%', position: 'absolute', top: '5px', right: '5px', zIndex: 1000 }}
            />}
            {this.props.topFilterContent}
            {enabled_filters.includes('subscription') && this.renderSubscriptionFilter(filters)}
            {enabled_filters.includes('search') && this.renderSearchFilter(filters)}
            {this.renderTypeFilters()}
            {this.renderAccessFilters()}
            {enabled_filters.includes('tags') && filtered_tags.length > 1 && <div>
                <div className="center-align">
                    <h5 className="white-text inline-block">Tags</h5>
                </div>
                <div>
                    {_.map(filtered_tags, (tag) => {
                        let selected = (tag === 'all' && tags.length === 0) || tags.indexOf(tag) !== -1;
                        return <div key={tag}
                            className={'z-depth-1 clickable tag-badge' + (selected ? " selected" : "")}
                            onClick={this.onTagFilterClick.bind(null, tag)}>{tag}
                        </div>
                    })}
                </div>
            </div>}
        </div>

    }

}
import React from 'react';

import config from "../../config";
import generic from "../../model/generic";
import User from '../../model/user';

import Modal from "../global/Modal";
import Button from "../global/Button";
import InputField from "../global/InputField";
import CheckBox from "../global/CheckBox";

import TagSelectPanel from '../media/TagSelectPanel';

const DEFAULT_DATA = { type: "video", name: "", url: "", url_map: {}, preview_url: "", access_type: 'free', price: 1, renting_time: 1 };

const RENTING_TIME_VALUES = [
    { d: 1, t: 'h' }, { d: 2, t: 'h' }, { d: 3, t: 'h' }, { d: 6, t: 'h' }, { d: 12, t: 'h' },
    { d: 1, t: 'd' }, { d: 2, t: 'd' }, { d: 7, t: 'd' }, { d: 14, t: 'd' }, { d: 30, t: 'd' }
];

const VIDEO_FORMATS = config.video_formats;

export default class MediaForm extends React.Component {

    state = Object.assign({ opened: false, openModal: null, closeModal: null, action: null, id: null }, DEFAULT_DATA);

    componentDidMount() {
        window.openMediaForm = this.openModal;
    }

    getOpen = (open) => {
        this.setState({ openModal: open });
    };

    openModal = (options, close_cb = function () { }) => {
        let state = {
            opened: true,
            action: options.action,
            id: null,
            type: "",
            name: "",
            visibility: "public",
            url: "",
            url_map: {},
            filename: "",
            green_screen: false,
            hosting: 'external',
            tags: [],
            undismissible: options.undismissible,
            close_cb: close_cb
        };
        if (options.action === 'edit' && options.target_id) {
            let media = generic.getEntity('media', options.target_id);
            if (media) {
                state.id = media.id;
                state.type = media.type || "";
                state.name = media.name || "";
                state.visibility = media.visibility || "private";
                state.url = media.url || "";
                state.url_map = media.url_map || {};
                state.preview_url = media.preview_url || "";
                state.access_type = (media.access && media.access.type) || 'free';
                state.price = (media.access && media.access.price) || 1;
                state.renting_time = (media.access && media.access.renting_time) || 1;
                state.tags = media.tags || [];
                state.hosting = media.hosting || 'external';
                state.filename = media.filename || "";
                state.green_screen = media.green_screen || false;
            } else {
                state.action = 'add';
                Object.assign(state, DEFAULT_DATA);
            }
        } else {
            state.action = 'add';
            Object.assign(state, DEFAULT_DATA);
        }
        this.setState(state, this.state.openModal);
    };

    getClose = (close) => {
        this.setState({ closeModal: close });
    };

    afterOpen = () => {
        //$(".lean-overlay").last().addClass("alert-popup-overlay");
    };

    afterClose = () => {
        this.setState({ opened: false }, this.state.close_cb);
    };

    onTypeClick = (type) => {
        this.setState({ type: type });
    };

    onNameChange = (value) => {
        this.setState({ name: value });
    };

    onVisibilityClick = (visibility) => {
        this.setState({ visibility: visibility });
    };

    onUrlChange = (value) => {
        this.setState({ url: value });
    };

    onUrlMapChange = (target, value) => {
        let url_map = this.state.url_map;
        url_map[target] = value;
        this.setState({ url_map: url_map });
    };

    onPreviewUrlChange = (value) => {
        this.setState({ preview_url: value });
    };

    onAccessTypeClick = (type) => {
        this.setState({ access_type: type });
    };

    onPriceChange = (value) => {
        this.setState({ price: value });
    };

    onRentingTimeClick = (duration, duration_type) => {
        let renting_time = parseInt(duration);
        if (duration_type === 'd') {
            renting_time *= 24;
        }
        this.setState({ renting_time });
    };

    onTagClick = (tag) => {
        let selected = this.state.tags.slice(), index = selected.indexOf(tag);
        if (index > -1) {
            selected.splice(index, 1);
        } else {
            selected.push(tag);
        }
        this.setState({ tags: selected })
    };

    onGreenScreenClick = () => {
        this.setState({ green_screen: !this.state.green_screen });
    };

    onSubmit = () => {
        let action = this.state.action, type = this.state.type, name = this.state.name, url = this.state.url, url_map = this.state.url_map, preview_url = this.state.preview_url,
            hosting = this.state.hosting, access_type = this.state.access_type, price = this.state.price, renting_time = this.state.renting_time,
            tags = this.state.tags, green_screen = this.state.green_screen;
        let data = {
            type: type,
            name: name,
            tags: tags,
            green_screen: green_screen
        };
        if (hosting === 'external') {
            if (type === 'picture') {
                data.url = url;
            } else {
                data.url_map = url_map;
            }
        }
        let access = { type: access_type };
        if (access_type === 'buy' || access_type === 'rent') {
            access.price = price;
        }
        if (access_type === 'rent') {
            access.renting_time = renting_time;
        }
        // data.thumbnail_url = type === 'video' || type === 'video_360' ? preview_url : null;
        data.access = access;
        if (action === 'add') {
            generic.createEntity('media', data, (err) => {
                if (err) {
                    window.alertPopup('Error', 'An error has occurred while creating new media.')
                } else {
                    this.state.closeModal();
                    window.toast('Media has been created successfully.');
                }
            })
        } else {
            generic.updateEntity('media', this.state.id, data, (err) => {
                if (err) {
                    window.alertPopup('Error', 'An error has occurred while updating media.')
                } else {
                    this.state.closeModal();
                    window.toast('Media has been updated successfully.');
                }
            })
        }
    };

    getRentingTimeButtons = () => {
        let renting_time = this.state.renting_time, buttons = [];
        for (let value of RENTING_TIME_VALUES) {
            let duration = value.d * (value.t === 'd' ? 24 : 1), selected = duration === renting_time;
            buttons.push(<Button className={"t18-btn utils-btn" + (selected ? "" : " grey")} text={value.d + '' + value.t} style={{ margin: '5px' }}
                onClick={this.onRentingTimeClick.bind(null, value.d, value.t)} />)
        }
        return buttons;
    };

    getTags = () => {
        return <TagSelectPanel selected={this.state.tags} onTagClick={this.onTagClick} noMaxHeight={true} />
    };

    getUrlInput = () => {
        let type = this.state.type;
        if (type === 'picture') {
            let url = this.state.url;
            return <div style={{ fontSize: '22px' }}>
                <span className="bold" style={{ display: "inline-block", width: "80px" }}>URL : </span>
                <InputField style={{ display: "inline-block", width: "500px" }} value={url} onChange={this.onUrlChange} />
            </div>
        } else {
            let url_map = this.state.url_map, input_display = [];
            for (let format of VIDEO_FORMATS) {
                let url = url_map[format] || '';
                input_display.push(<div className="url-map-input" style={{ fontSize: '18px', paddingLeft: "20px" }}>
                    <span className="bold" style={{ display: "inline-block", width: "80px" }}>{format} : </span>
                    <InputField style={{ display: "inline-block", width: "500px" }} value={url} onChange={this.onUrlMapChange.bind(null, format)} />
                </div>)
            }
            return <div>
                <div className="bold" style={{ fontSize: '22px' }}>URL list :</div>
                <div>
                    {input_display}
                </div>
            </div>
        }
    };

    renderFormatDisplay = () => {
        let url_map = this.state.url_map || {}, 
            format_display = [<div style={{ fontSize: '22px' }}>
            <span className="bold" style={{ display: "inline-block", width: "120px" }}>Formats : </span>
        </div>];
        if(Object.keys(url_map).length === 0) {
            return undefined;
        }
        for (let format in url_map) {
            format_display.push(<div className="url-map-input" style={{ fontSize: '18px', paddingLeft: "20px" }}>
                <i className="material-icons md-check green-text">check</i>
                <span className="bold" style={{ display: "inline-block", width: "100px", marginLeft: '5px', position: 'relative', bottom: '5px' }}>{format.replace('r', '')}</span>
            </div>);
        }
        return format_display
    }

    render() {
        let content;
        if (this.state.opened) {
            let action = this.state.action, 
                type = this.state.type, 
                name = this.state.name, 
                url = this.state.url, 
                url_map = this.state.url_map, 
                preview_url = this.state.preview_url,
                visibility = this.state.visibility,
                access_type = this.state.access_type, price = this.state.price, disabled = false, hosting = this.state.hosting;
            if (!type || !name || (!url && Object.keys(url_map).length === 0)) {
                disabled = true;
            }
            content = <div id="media-form-content" className="reglisse">
                <h3 style={{ margin: 0 }}>{action === 'add' ? "Add Media" : "Edit Media"}</h3>
                <div style={{ fontSize: '22px', marginTop: '40px' }}>
                    {hosting === 'internal' || action === 'edit' ? <div>
                        <div className="bold" >{"Type : " + (type === 'picture' ? 'Picture' : (type === 'video' ? 'Video' : 'Video 180/360°'))}</div>
                    </div> : <div>
                        <div className="bold" >Type : </div>
                        <div><CheckBox label={'Video'} checked={type === 'video'} onClick={this.onTypeClick.bind(null, 'video')} /></div>
                        <div><CheckBox label={'Video 180/360°'} checked={type === 'video_360'} onClick={this.onTypeClick.bind(null, 'video_360')} /></div>
                        <div><CheckBox label={'Picture'} checked={type === 'picture'} onClick={this.onTypeClick.bind(null, 'picture')} /></div>
                    </div>}
                </div>
                <div style={{ fontSize: '22px' }}>
                    <span className="bold" style={{ display: "inline-block", width: "80px" }}>Name : </span>
                    <InputField style={{ display: "inline-block", width: "200px" }} value={name} onChange={this.onNameChange} />
                </div>
                {/* <div style={{ fontSize: '22px' }}> 
                    <div className="bold" >Visibility : </div>
                    <div><CheckBox label={'Public'} checked={visibility === 'public'} onClick={this.onVisibilityClick.bind(null, 'public')} /></div>
                    <div><CheckBox label={'Private'} checked={visibility === 'private'} onClick={this.onVisibilityClick.bind(null, 'private')} /></div>
                </div> */}
                {action === 'edit' && this.renderFormatDisplay()}
                {hosting === 'external' ? this.getUrlInput() : null}
                {false && hosting === 'external' && (type === 'video' || type === 'video_360') ? <div style={{ fontSize: '22px' }}>
                    <span className="bold" style={{ display: "inline-block", width: "130px" }}>Thumbnail URL : </span>
                    <InputField style={{ display: "inline-block", width: "400px" }} value={preview_url} onChange={this.onPreviewUrlChange} />
                </div> : undefined}
                {hosting === 'internal' ? <div style={{ fontSize: '22px' }}>
                    <span className="bold" style={{ display: "inline-block", marginRight: '15px' }}>Filename : </span>
                    <span>{this.state.filename}</span>
                </div> : null}
                {User.isAdmin() && <div className="tm">
                    <CheckBox label={'Green Screen'} checked={this.state.green_screen} onClick={this.onGreenScreenClick} />
                </div>}
                {false ? <div style={{ fontSize: '22px' }}>
                    <div className="bold" >Access : </div>
                    <div><CheckBox label={'Free'} checked={access_type === 'free'} onClick={this.onAccessTypeClick.bind(null, 'free')} /></div>
                    <div><CheckBox label={'Subscription'} checked={access_type === 'subscription'} onClick={this.onAccessTypeClick.bind(null, 'subscription')} /></div>
                    <div><CheckBox label={'Buy'} checked={access_type === 'buy'} onClick={this.onAccessTypeClick.bind(null, 'buy')} /></div>
                    <div><CheckBox label={'Rent'} checked={access_type === 'rent'} onClick={this.onAccessTypeClick.bind(null, 'rent')} /></div>
                </div> : null}
                {access_type === 'buy' || access_type === 'rent' ? <div style={{ fontSize: '22px' }}>
                    <span className="bold" style={{ display: "inline-block", width: "80px" }}>Price : </span>
                    <InputField style={{ display: "inline-block", width: "50px" }} type='number' min={1} value={price} onChange={this.onPriceChange} />
                    <span className="bold" style={{ fontFamily: 'Roboto' }}>€</span>
                </div> : undefined}
                {access_type === 'rent' ? <div style={{ fontSize: '22px' }}>
                    <div className="bold" >Renting time : </div>
                    <div>{this.getRentingTimeButtons()}</div>
                </div> : undefined}
                <div className="bold tm" style={{ fontSize: '22px' }} >Tags : </div>
                <div>{this.getTags()}</div>
                <div className="modal-footer" style={{ marginTop: "40px" }}>
                    <Button text={'Submit'} className='t18-btn' onClick={this.onSubmit} disabled={disabled} style={{ fontSize: "20px" }} />
                    <Button text={"Cancel"} className='t18-btn lm black' onClick={this.state.closeModal} large={true}
                        style={{ fontSize: "20px", marginLeft: '15px' }} />
                </div>
            </div>;
        }
        return (
            <Modal id="media-form"
                content={content}
                getOpen={this.getOpen}
                getClose={this.getClose}
                afterOpen={this.afterOpen}
                afterClose={this.afterClose}
                undismissible={this.state.undismissible}
            />
        )
    }
};
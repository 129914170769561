import API from "../lib/api";
import User from "./user";
import _ from "lodash";

let model = {};

function formatEntitiesType(entity_name){
    if(entity_name === 'currency'){
        return 'currencies';
    }
    return entity_name + 's';
}

const generic = {

    loadModule: (model_ref) => {
        model = model_ref;
        Object.assign(model_ref, generic);
    },

    // LOADING

    onEntityDataLoad: (entity_type, entity_id, data, trigger_event) => {
        model[entity_type] = model[entity_type] || {};
        model[entity_type][entity_id] = data;
        if(!data.id){
            data.id = entity_id;
        }
        if(trigger_event){
            generic.onEntityDataUpdate(entity_type, entity_id, data);
        }
    },

    refreshEntity: (entity_type, entity_id, cb = () => {}, options = {}) => {
        API.getEntityRequest(entity_type, entity_id, (data) => {
            let entity_data = data[entity_type];
            generic.onEntityDataLoad(entity_type, entity_id, entity_data, true);
            cb(null, entity_data);
        }, (err) => {
            console.log('Error while refreshing ' + entity_type + ' => ' + entity_id + ' data : ' + JSON.stringify(err));
            cb(err);
        }, options);
    },

    loadOwnedEntities: (entity_type, cb = () => {}) => {
        API.getOwnedEntities(entity_type, (data) => {
            let entities_type = formatEntitiesType(entity_type);
            for (let entity of data[entities_type]) {
                generic.onEntityDataLoad(entity_type, entity.id, entity, false);
            }
            cb();
        }, (err) => {
            console.log('Error while loading owned ' + entity_type + 's : ' + JSON.stringify(err));
            cb(err || true);
        });
    },

    loadEntities: (entity_type, cb, options) => {
        API.getEntitiesRequest(entity_type, (data) => {
            let entities_type = formatEntitiesType(entity_type);
            for (let entity of data[entities_type]) {
                generic.onEntityDataLoad(entity_type, entity.id, entity, false);
            }
            cb();
        }, (err) => {
            console.log('Error while loading ' + entity_type + 's : ' + JSON.stringify(err));
            cb(err || true);
        }, options);
    },

    // GETTERS

    getEntity: (entity_type, entity_id) => {
        return _.get(model, [entity_type, entity_id]);
    },

    getEntities: (entity_type, as_array) => {
        return as_array ? Object.values(model[entity_type] || {}) : (model[entity_type] || {});
    },

    getOwnedEntities: (entity_type) => {
        return generic.getEntitiesFromProperty(entity_type, 'owner', User.getUserId());
    },

    getEntityFromProperty: (entity_type, property, value) => {
        return Object.values(model[entity_type] || {}).find(entity => _.isEqual(entity[property], value));
    },

    getEntitiesFromProperty: (entity_type, property, value) => {
        return generic.getEntitiesFromFilter(entity_type, entity => _.isEqual(entity[property], value));
    },

    getEntitiesFromFilter: (entity_type, filter) => {
        return Object.values(model[entity_type] || {}).filter(entity => filter(entity));
    },

    getMatchingEntity: (entity_type, filter) => {
        return _.find(Object.values(model[entity_type] || {}), filter);
    },

    getTargetEntityData: (target_entity_type, target_entity_id, data_entity_type, data_entity_id) => {
        let target = generic.getEntity(target_entity_type, target_entity_id),
            data_targets = target.data[formatEntitiesType(data_entity_type)] || [];
        for(let data_target of data_targets){
            if(data_target.id === data_entity_id){
                return data_target;
            }
        }
    },

    // UPDATES

    createEntity: (entity_type, data, cb = () => {}, refresh_cb = () => {}) => {
        API.createEntityRequest(entity_type, data, (result) => {
            generic.onEntityDataLoad(entity_type, result.id, data, true);
            cb(null, result.id);
            generic.refreshEntity(entity_type, result.id, refresh_cb, {no_loading: true});
        }, (err) => {
            console.log('Error while creating ' + entity_type + ' : ' + JSON.stringify(err));
            cb(err);
        });
    },

    updateEntity: (entity_type, entity_id, data, cb = () => {}) => {
        API.updateEntityRequest(entity_type, entity_id, data, () => {
            for(let prop in data){
                model[entity_type][entity_id][prop] = data[prop];
            }
            generic.onEntityDataUpdate(entity_type, entity_id, data);
            cb();
        }, (err) => {
            console.log('Error while updating ' + entity_type + ' data : ' + JSON.stringify(err));
            cb(err);
        });
    },

    deleteEntity: (entity_type, entity_id, cb = () => {}) => {
        API.deleteEntityRequest(entity_type, entity_id, () => {
            delete model[entity_type][entity_id];
            generic.onEntityDataUpdate(entity_type);
            cb();
        }, (err) => {
            console.log('Error while deleting ' + entity_type + ' : ' + JSON.stringify(err));
            cb(err);
        });
    },

    // EVENTS LISTENERS

    entityListeners: {},

    addEntityListener: (entity_type, listener) => {
        if(!generic.entityListeners[entity_type]){
            generic.entityListeners[entity_type] = [];
        }
        generic.entityListeners[entity_type].push(listener);
    },

    removeEntityListener: (entity_type, listener) => {
        if(generic.entityListeners[entity_type]){
            generic.entityListeners[entity_type] = generic.entityListeners[entity_type].filter(l => l !== listener);
        }
    },

    onEntityDataUpdate: (entity_type, entity_id, data) => {
        let listeners = generic.entityListeners[entity_type];
        if(listeners){
            for(let listener of listeners){
                listener(entity_id, data);
            }
        }
    }

}

User.setGenericRef(generic)

export default generic;

window.generic = generic;

window.generic_model = model;
import React from "react";

import ToolTipped from "./ToolTipped";

/**
 * Props : --> id : id to give to the component
 *         --> text : button text
 *         --> onClick : callback function when button is clicked
 *         --> color : color of the button
 *         --> style : custom style to add to the button
 *         --> className : additional classname to give to the button
 *         --> iconLeft : button icon left
 *         --> disabled : disable the button
 *         --> uppercase : if true, text will be uppercased
 */
export default class Button extends React.Component {
    state = { id: (this.props.id ? this.props.id : window.generateID()) };

    render() {
        let style = {};
        if(this.props.style)
            style = { ...this.props.style };
        if(!this.props.uppercase)
            style["textTransform"] = "none";
        let classname = (this.props.className ? this.props.className : "") + " waves-effect waves-light btn";
        if(this.props.color) {
            if(this.props.color.indexOf("#") > -1)
                style["backgroundColor"] = this.props.color;
            else
                classname += " " + this.props.color;
        }
        if(this.props.disabled)
            classname += " disabled";
        let button = <a id={this.state.id} className={classname} onClick={this.props.disabled ? undefined : this.props.onClick}
               style={style}>
                {this.props.iconLeft ?
                    <i className={`material-icons md-${this.props.iconLeft}${this.props.text ? " left" : ""}`}/>
                    : null}
                {this.props.text}
            </a>;
        if(this.props.tooltip && !this.props.disableTooltip){
            return (
                <ToolTipped tooltip={this.props.tooltip} position={this.props.tooltipPosition} content={button} style={{display: 'inline-block'}} />
            )
        }else{
            return button
        }
    }
}

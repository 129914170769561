import React from "react";

export default class Title extends React.Component {

    render = () => {
        let wrapper_className = "center-align",
            title_classname = "inline-block",
            wrapper_style = {},
            title_style = {},
            size = parseInt(this.props.size || 5) || 5;
        if(this.props.leftAlign) {
            wrapper_className = "";
            title_classname = "";
        }
        if(this.props.fontSize){
            title_style.fontSize = this.props.fontSize;
        }
        if(this.props.themeColor){
            title_classname += " theme-color";
        }
        if(this.props.style){
            title_style = {...title_style, ...this.props.style};
        }
        if(this.props.wrapperStyle){
            wrapper_style = {...wrapper_style, ...this.props.wrapperStyle};
        }
        return <div className={wrapper_className} style={wrapper_style}>
            {size === 1 && <h1 className={title_classname} style={title_style}>{this.props.text}</h1>}
            {size === 2 && <h2 className={title_classname} style={title_style}>{this.props.text}</h2>}
            {size === 3 && <h3 className={title_classname} style={title_style}>{this.props.text}</h3>}
            {size === 4 && <h4 className={title_classname} style={title_style}>{this.props.text}</h4>}
            {size === 5 && <h5 className={title_classname} style={title_style}>{this.props.text}</h5>}
        </div>
    }

}
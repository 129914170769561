import React from "react";

import config from "../../../config";

import ReturnIconButton from "../../common/ReturnIconButton";

import PaymentPanel from "../../payment/PaymentPanel";

export default class extends React.Component {

    onTransactionProcessed = () => {
        this.props.onReturn();
        if(this.props.onTransactionProcessed){
            this.props.onTransactionProcessed(this.props.mediaId);
        }
    }

    render() {
        let media_id = this.props.mediaId,
            mobile_display = config.MOBILE_DISPLAY;
        return <div className="h-100" style={{paddingBottom: mobile_display ? "60px" : undefined,}}>
            <div style={{
                    backgroundColor: 'white', 
                    position: 'relative',
                    padding: mobile_display ? "10px" : '30px',
                    margin: mobile_display ? "10px" : '30px', 
                    borderRadius: '10px', 
                    minHeight: "200px"
                }}>
                <ReturnIconButton onClick={this.props.onReturn} />
                <PaymentPanel media_id={media_id} onReturn={this.props.onReturn} onTransactionProcessed={this.onTransactionProcessed} />
            </div>
        </div>
    }

}
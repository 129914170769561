import React from "react";
import _ from 'lodash';

import config from "../../../../shared/config";
import model from '../../model/model';

import ReturnIconButton from "../../../../shared/components/common/ReturnIconButton";

import MediaMiniDisplay from "../../../../shared/components/media/MediaMiniDisplay";
import GridContentBrowser from "../../../../shared/components/common/GridContentBrowser";
import SelectedMediaView from "../../../../shared/components/media/SelectedMediaView/SelectedMediaView";

export default class extends React.Component {

    state = { id: this.props.id || window.generateID(), selected_media_id: null, show_payment_panel: false };

    componentDidMount() {
        window.explore_media_views = window.explore_media_views || {};
        window.explore_media_views[this.state.id] = this;
    }

    onMediaSelect = (media_id, index, cb = () => {}) => {
        this.setState({ selected_media_id: media_id, show_payment_panel: false }, () => {
            if(this.props.onMediaSelect){
                this.props.onMediaSelect(media_id, index);
            }
            // if(media_id){
            //     history.pushState({}, null);
            // }else{
            //     history.back();
            // }
            cb();
        });
    }

    onBuyClick = () => {
        this.setState({ show_payment_panel: true });
    }

    hidePaymentPanel = () => {
        this.setState({ show_payment_panel: false });
    }

    refresh = () => {
        this.forceUpdate();
    }

    renderMediaCell = (media, index) => {
        return <MediaMiniDisplay
            noColClass={true}
            key={media.id}
            media={media}
            onClick={() => this.onMediaSelect(media.id, index)}
            onHeaderClick={this.props.showUser ? () => {
                if(this.props.onHeaderClick){
                    this.props.onHeaderClick(media);
                }else if (window.showUser && media.owner) {
                    window.showUser(media.owner);
                }
            }: undefined}
        />
    }

    onTransactionProcessed = (media_id) => {
        model.checkUntilPaymentDone(media_id, () => {
            this.onMediaSelect(null, () => {
                this.onMediaSelect(media_id);
            });
        });
    }

    searchMediaFunction = (media, search) => {
        if(media.name && media.name.toLowerCase().indexOf(search.toLowerCase()) > -1){
            return true;
        }
        let user = model.getEntity('user', media.owner);
        if(user && user.username && user.username.toLowerCase().indexOf(search.toLowerCase()) > -1){
            return true;
        }
        return false;
    }

    render() {
        let selected_media_id = this.state.selected_media_id,
            medias = this.props.medias || model.getEntities('media', true),
            className = "explore-view " +  (this.props.className || "") + (this.props.show ? '' : ' hide'),
            style = this.props.style || {};
        return <div id={this.state.id} className={className} style={style}>
            <GridContentBrowser show={!selected_media_id}
                                id={this.state.id + "-grid-browser"} 
                                onScrolled={this.props.onScrolled}
                                contentList={medias}
                                renderContent={this.renderMediaCell}
                                rowHeight={440}
                                enabledFilters= {this.props.enabledFilters}
                                defaultFilters={{visibility: 'public'}}
                                noContentText={"No matching media"}
                                searchFunction={this.searchMediaFunction}
                                topFilterContent={this.props.topFilterContent} />
            {selected_media_id && <SelectedMediaView selectedId={selected_media_id}
                                      showUser={this.props.showUser}
                                      putAbove={this.props.putAbove}
                                      mobileMediaHeader={!this.props.disableMobileMediaHeader}
                                      onTransactionProcessed={this.onTransactionProcessed}
                                      onReturn={this.onMediaSelect.bind(null, null)} />}
            
        </div>

    }

}
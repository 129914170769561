import React from "react";
import moment from "moment";

import config from "../../config";

import generic from "../../model/generic";
import User from "../../model/user";

import Title from "../global/Title";
import Button from "../global/Button";

export default class SubscriptionDisplay extends React.Component {

    onSubscriptionSetup = () => {
        window.openGenericForm({
            title: 'Subscription Price',
            inputs: [{
                name: 'price',
                label: "Price ($ / Month)",
                type: 'number',
                value: 5,
                control: {
                    type: 'number',
                    options: { min: 0, max: 10000, step: 0.01 }
                }
            }],
            on_submit: (data) => {
                generic.createEntity('subscription', {name: "Base", price: data.price, unit_time: 2592000}, (err) => {
                    if (err) {
                        window.alertPopup('Error', 'An error has occurred while creating your subscription.');
                    } else {
                        this.refresh();
                        window.toast('Subscription created successfully.');
                    }
                }, this.refresh);
            }
        });
    }

    onSubscriptionPriceEdit = () => {
        let subscription = User.getUserSubscription();
        window.openGenericForm({
            title: 'Subscription Price',
            inputs: [{
                name: 'price',
                label: "Price ($ / Month)",
                type: 'number',
                value: subscription.price,
                control: {
                    type: 'number',
                    options: { min: 0, max: 10000, step: 0.01 }
                }
            }],
            on_submit: (data) => {
                generic.updateEntity('subscription', subscription.id, {price: data.price}, (err) => {
                    if (err) {
                        window.alertPopup('Error', 'An error has occurred while updating your subscription.');
                    } else {
                        this.refresh();
                        window.toast('Subscription price updated successfully.');
                    }
                });
            }
        });
    }

    refresh = () => {
        this.forceUpdate();
    }

    render() {
        let subscription = User.getUserSubscription(),
            mobile_display = config.MOBILE_DISPLAY,
            content;
        if (subscription) {
            content = <div className="tm">
                <div className="profile-section clickable" onClick={this.onSubscriptionPriceEdit} style={{borderTop: "1px solid #e0e0e0"}}>
                    <h5>
                        <span className="profile-item">
                            <i className="material-icons theme-color profile-icon" >attach_money</i>
                            {"Price: " + subscription.price + '$ / Month'}
                        </span>
                        <i className="material-icons theme-color profile-edit-icon" >chevron_right</i>
                    </h5>
                </div>
                <div className="profile-section">
                    <h5>
                        <span className="profile-item">
                            <i className="material-icons theme-color profile-icon" >photo_camera</i>
                            {"Media count: " + (subscription.media_id_list || []).length}
                        </span>
                    </h5>
                </div>
                <div className="profile-section">
                    <h5>
                        <span className="profile-item">
                            <i className="material-icons theme-color profile-icon" >local_activity</i>
                            {"Paid: " + (subscription.paid_count || 0)}
                        </span>
                    </h5>
                </div>
            </div>;
        } else {
            content = <div className="tm">
                <Title text="Your subscription is not set up yet." />
                <div className="center-align tm bm">
                    <Button text="Setup" className='t18-btn' onClick={this.onSubscriptionSetup} style={{width: mobile_display ? "60%" : "200px"}} />
                </div>
            </div>
        }
        return <div className="subscription-display-panel" style={{
            padding: mobile_display ? "10px" : "40px 20px",
            paddingTop: mobile_display ? undefined : "10px",
            margin: mobile_display ? '10px 5px' : '20px',
            borderRadius: "15px",
            color: 'white',
            backgroundColor: 'rgb(35, 40, 57)'
        }}>
            <div className="center-align">
                <Title text="My Subscription" size="4" />
            </div>
            {content}
        </div>
    }

}

import _ from 'lodash';
import API from '../../../shared/lib/api';
import generic from '../../../shared/model/generic';
import User from '../../../shared/model/user';

const self = {

    initialize: (cb = () => {}) => {
        let set_progress = (progress) => {
            window.setInitialLoadingValue(progress);
        }
        console.log('Model initialize');
        User.loadContentCreators(() => {
            set_progress(15);
            self.loadEntities('currency', () => {
                set_progress(30);
                self.loadMedias(() => {
                    set_progress(45);
                    self.loadEntities('subscription', () => {
                        set_progress(60);
                        if(User.isAuthenticated()){
                            self.loadOwnedEntities('media', () => {
                                set_progress(75);
                                self.loadOwnedEntities('subscription_token', () => {
                                    set_progress(100);
                                    return cb();
                                });
                            });
                        }else{
                            set_progress(100);
                            return cb();
                        }
                    });
                });
            });
        })
    },

    afterSignin: (cb = () => {}) => {
        self.loadMedias(() => {
            self.loadOwnedEntities('media', () => {
                self.loadOwnedEntities('subscription_token', () => {
                    window.refreshApp();
                    return cb();
                });
            });
        });
    },

    loadMedias: (cb = () => {}, options = {}) => {
        API.getEntitiesRequest('media', (data) => {
            for (let media of data.medias) {
                if(generic.getEntity('user', media.owner)){
                    generic.onEntityDataLoad('media', media.id, media, false);
                }
            }
            cb();
        }, (err) => {
            console.log('Error while loading ' + entity_type + 's : ' + JSON.stringify(err));
            cb(err || true);
        }, options);
    },

    checkUntilPaymentDone: (media_id, cb = () => {}) => {
        let interval = setInterval(() => {
            model.refreshEntity('media', media_id, () => {
                let media = model.getEntity('media', media_id);
                if(!media.paid_access_denied){
                    clearInterval(interval);
                    self.loadMedias(() => {
                        window.refreshApp();
                        return cb();
                    });
                }
            }, {no_loading: true});
        }, 500);
    }

}

generic.loadModule(self);

window.model = self;

export default self;
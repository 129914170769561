import React, { forwardRef } from "react";
import _ from 'lodash';
import { VariableSizeGrid as Grid } from 'react-window';

import config from "../../config";

import Title from "../global/Title";

export default class extends React.Component {

    gridRef = React.createRef();

    state = { id: this.props.id || window.generateID(), columnWidth: 200, columnCount: 3 };

    componentDidMount() {
        this.updateColumnWidth();
        window.addEventListener('resize', this.updateColumnWidth);
        window.grid_display = window.grid_display || {};
        window.grid_display[this.state.id] = this;
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateColumnWidth);
    }

    componentDidUpdate(prevProps) {
        this.updateColumnWidth();
        // setTimeout(this.updateColumnWidth, 500);
    }

    onMediaClick = (media_id) => {
        this.props.onMediaSelect(media_id);
    }

    refresh = () => {
        this.forceUpdate();
    }

    updateColumnWidth = () => {
        // Calculate the column width based on the container's width
        const containerWidth = this.containerRef?.clientWidth || 600; // Default width
        // Number of items per row
        const minItemWidth = 400;
        const columnCount = Math.max(1, Math.floor(containerWidth / minItemWidth));
        const columnWidth = Math.floor(containerWidth / columnCount) - 5;
        if (columnWidth !== this.state.columnWidth || columnCount !== this.state.columnCount) {
            this.setState({ columnWidth, columnCount }, () => {
                if (this.gridRef.current) {
                    this.gridRef.current.resetAfterColumnIndex(0, true);
                }
            });
        }
    }

    getRowHeight = () => {
        return this.props.rowHeight || 340;
    }

    scrollToItemFromIndex = (index, yOffset) => {
        let rowIndex = Math.floor(index / this.state.columnCount),
            columnIndex = index % this.state.columnCount;
        this.scrollToItem(rowIndex, columnIndex, yOffset);
    };

    scrollToItem = (rowIndex, columnIndex, yOffset = 0) => {
        // this.gridRef.current.scrollToItem({
        //     align: 'start', // You can use 'start', 'center', 'end', or 'auto'
        //     columnIndex: columnIndex,
        //     rowIndex: rowIndex,
        // });
        const totalRowHeight = this.getRowHeight() * rowIndex;
        // Apply the Y offset
        const adjustedTopPosition = totalRowHeight + yOffset;
        // Calculate the column position if needed. Here's an example assuming a fixed column width.
        // You might need to adjust this if your grid has variable column widths.
        const columnWidth = this.state.columnWidth; // Assuming columnWidth is in your state
        const adjustedLeftPosition = columnWidth * columnIndex;

        // Use the scrollTo method with the adjusted positions
        this.gridRef.current.scrollTo({
            scrollTop: adjustedTopPosition,
            scrollLeft: adjustedLeftPosition,
        });
    };

    lastTouchMoveY = null;

    onScrolled = (deltaY, currentY) => {
        if (this.props.onScrolled) {
            this.props.onScrolled(deltaY, currentY);
        }
    }

    render() {
        let content_list = this.props.contentList,
            no_content_text = this.props.noContentText,
            columnCount = this.state.columnCount;
        let outerElementType = forwardRef((props, ref) => {
            return <div ref={ref} {...props} onWheel={(e) => {
                this.onScrolled(e.deltaY, e.clientY);
            }} onTouchMove={(e) => {
                let currentY = e.touches[0].clientY;
                if (this.lastTouchMoveY === null) {
                    this.lastTouchMoveY = currentY;
                    return;
                }
                let lastY = this.lastTouchMoveY,
                    deltaY = lastY - currentY;
                this.lastTouchMoveY = currentY;
                this.onScrolled(deltaY, currentY);
            }} />;
        });
        return (
            <div id={this.state.id} ref={ref => this.containerRef = ref} className="col s12 m9 h-100" style={{ overflowX: 'hidden', padding: 0, position: content_list.length === 0 ? 'relative' : undefined }}>
                {content_list.length === 0 && no_content_text && <Title text={no_content_text} size={config.MOBILE_DISPLAY ? 4 : 3}
                    wrapperStyle={{ position: 'absolute', color: 'white', width: '100%', marginTop: '50px' }} />}
                <Grid
                    ref={this.gridRef}
                    outerElementType={outerElementType}
                    className="grid-display"
                    onScroll={this.handleScroll}
                    columnCount={columnCount}
                    columnWidth={index => this.state.columnWidth}
                    height={this.containerRef?.clientHeight || 800}
                    rowCount={Math.ceil(content_list.length / columnCount)}
                    rowHeight={this.getRowHeight}
                    width={(this.containerRef?.clientWidth) || 600} // Adjust this based on your container's width
                >
                    {({ columnIndex, rowIndex, style }) => {
                        const content_index = rowIndex * columnCount + columnIndex;
                        if (content_index >= content_list.length) {
                            return null; // Out of range
                        }
                        const cell_content = content_list[content_index];
                        return (
                            <div style={style}>
                                {this.props.renderContent(cell_content, content_index)}
                            </div>
                        );
                    }}
                </Grid>
            </div>
        );
    }
}
import React        from "react";
import ReactTooltip from "react-tooltip";
import _      from "lodash";


/**
 * Props :  --> id : id to give to the component
 *          --> content : content of the component
 *          --> tooltip : content of the tooltip
 *          --> position : (left,right,top,bottom,bottomLeft) position of the tooltip compared to content (default : bottom)
 *          --> width : css value of width
 *          --> className
 *          --> tooltipClassName
 *          --> style
 *          --> tooltipStyle
 */

let tooltip_rebuild = _.debounce(() => {
    ReactTooltip.rebuild();
}, 500);

export default class ToolTipped extends React.Component {

    state = {
        id: (this.props.id ? this.props.id : window.generateID())
    };

    componentDidMount() {
        tooltip_rebuild();
    }

    render() {
        return (
            <div id={this.state.id} className={"tool-tipped " + (this.props.className ? this.props.className : "")}
                 data-for="application-tooltip" data-html="true" data-tip={this.props.tooltip} data-place={this.props.position || "bottom"}
                 style={this.props.style ? this.props.style : {}} onClick={this.props.onClick}>
                {this.props.content}
            </div>
        )
    }
}

import React  from 'react';

import Modal from "../global/Modal";
import Button from "../global/Button";

import MediaSelector from "../media/MediaSelector";
import User from "../../model/user";
import Media from "../../model/media";


export default class ProfilePictureSelectModal extends React.Component {

    state = { opened: false, openModal: null, closeModal: null };

    componentDidMount() {
        window.openProfilePictureSelectModal = this.openModal;
    }

    getOpen = (open) => {
        this.setState({ openModal: open });
    };

    openModal = (options = {}, close_cb = function() {}) => {
        let state = { 
            opened: true, 
            selected: [],
            path: options.path || '/', 
            undismissible: options.undismissible, 
            close_cb: close_cb, 
            on_submit: options.on_submit || function() {}
        };

        this.setState(state, this.state.openModal);
    };

    getClose = (close) => {
        this.setState({ closeModal: close });
    };

    afterOpen = () => {
        //$(".lean-overlay").last().addClass("alert-popup-overlay");
    };

    afterClose = () => {
        this.setState({opened: false}, this.state.close_cb);
    };

    onValueChange = (value) => {
        this.setState({value: value});
    };

    onFolderSelect = (path) => {
        this.setState({path: path});
    }

    onFolderReturn = () => {
        let path = this.state.path;
        if(path !== '/'){
            path = path.substring(0, path.lastIndexOf('/'));
            if(path === '') path = '/';
            this.setState({path: path});
        }
    }

    getMediaSelectActions = () => {
        let actions = [];
        if(this.state.path !== '/'){
            actions.push({icon: 'keyboard_return', tooltip: 'Return', onClick: this.onFolderReturn});
        }
        return actions;
    }
    
    onMediaSelect = (id) => {
        this.setState({selected: [id]});
    };

    onSubmit = () => {
        let picture_id = this.state.selected[0];
        User.updateUser({picture_id: picture_id}, (err, res) => {
            if(err){
                console.log(err);
                window.alertPopup('Error', 'An error has occurred while updating user.');
            }else{
                this.state.on_submit(picture_id);
                if(window.refreshApp){
                    window.refreshApp();
                }
            }
            this.state.closeModal();
        });
    };

    refresh = () => {
        this.forceUpdate();
    }

    render() {
        let disabled = false, content;
        if(this.state.opened){
            let medias = Media.getFilteredMedias({type: 'picture', owner: User.getUserId()}),
                selected = this.state.selected;
            if(selected.length === 0){
                disabled = true;
            }
            content = <div id="profile-picture-select-modal-content" className="reglisse">
                <h3 style={{margin: 0}}>{"Select profile picture"}</h3>
                <h5 className="theme-color bm">You can upload more pictures in Management/Media.</h5>
                <MediaSelector selected={selected} onSelect={this.onMediaSelect} currentPath={this.state.path} 
                               medias={medias} hideEmptyFolders={true} hideFilters={true}
                               actions={this.getMediaSelectActions()} onFolderSelect={this.onFolderSelect} />
                <div className="modal-footer">
                    <Button text={'Submit'} className='t18-btn rm' onClick={this.onSubmit} disabled={disabled} style={{fontSize: "20px"}} />
                    <Button text={"Cancel"} className='t18-btn black lm' onClick={this.state.closeModal} large={true}
                            style={{fontSize: "20px"}}/>
                </div>
            </div>;
        }
        return (
            <Modal id="profile-picture-select-modal"
                   content={content}
                   getOpen={this.getOpen}
                   getClose={this.getClose}
                   afterOpen={this.afterOpen}
                   afterClose={this.afterClose}
                   undismissible={this.state.undismissible}
            />
        )
    }
};

import React from "react";
import moment from "moment";
import _ from "lodash";

import config from "../../config";

import User from "../../model/user";
import generic from "../../model/generic";

import DataTable from "../global/DataTable";
import Title from "../global/Title";
import LoadingAnimation from "../global/LoadingAnimation";

export default class WalletBalanceDisplay extends React.Component {

    state = {loaded: false};

    componentDidMount() {
        if(User.wallet_balances_loaded){
            this.setState({loaded: true});
        }else{
            User.loadWalletBalances((err) => {
                this.setState({loaded: true});
            });
        }
    }

    render() {
        let headers = ['Network', 'Symbol', 'Balance'],
            rows = [],
            loaded = this.state.loaded,
            currencies = _.sortBy(generic.getEntities('currency', true), ['network', 'symbol']),
            mobile_display = config.MOBILE_DISPLAY;
        if(loaded){
            for(let currency of currencies){
                let balance = User.getCurrencyBalance(currency.id);
                rows.push({
                    data: [
                        utils.getNetworkDisplayName(currency.network),
                        currency.symbol,
                        balance
                    ]
                });
            }
        }
        return <div style={{
            padding: mobile_display ? "10px" : "40px 20px",
            paddingTop: mobile_display ? undefined : "10px",
            margin: mobile_display ? '10px 5px' : '20px',
            borderRadius: "15px",
            color: 'white',
            backgroundColor: 'rgb(35, 40, 57)'
        }}>
            <div className="center-align">
                <Title text="My wallet" size="4" />
            </div>
            {!loaded && <div className="center-align">
                    <LoadingAnimation type="circular" />
                </div>}
            {loaded && <DataTable headers={{ headers }} rows={rows} />}
        </div>
    }

}
import React  from 'react';

import User from "../../model/user";

import Modal from "../global/Modal";
import Button from "../global/Button";

import WalletConnectPanel from "../profile/WalletConnectPanel";


export default class WalletAssociateModal extends React.Component {

    state = { opened: false, openModal: null, closeModal: null, name: "" };

    componentDidMount() {
        window.openWalletAssociateModal = this.openModal;
    }

    getOpen = (open) => {
        this.setState({ openModal: open });
    };

    openModal = (options, close_cb = () => {}) => {
        let state = { opened: true, name: options.name || "", title: options.title || "Edit name",
            undismissible: options.undismissible, close_cb: close_cb, on_associate: options.on_associate };

        this.setState(state, this.state.openModal);
    };

    getClose = (close) => {
        this.setState({ closeModal: close });
    };

    afterOpen = () => {
        //$(".lean-overlay").last().addClass("alert-popup-overlay");
    };

    afterClose = () => {
        this.setState({opened: false}, this.state.close_cb);
    };

    onWalletConnected = () => {
        User.associateWallet((err) => {
            if (err) {
                // {message: 'Invalid parameter : address-already-used', code: 400}
                console.log(err);
                let err_msg = 'An error occurred while associating your wallet. Please try again later.';
                if(err.message && err.message.includes('address-already-used')){
                    err_msg = 'This wallet is already associated to another account.';
                };
                window.alertPopup('Wallet association error', err_msg);
            }else if(this.state.on_associate){
                this.state.on_associate();
            }
            this.state.closeModal();
        })
    };

    render() {
        const content = this.state.opened ? <div id="wallet-associate-modal-content" className="reglisse">
            <h3 style={{margin: 0}}>Wallet Association</h3>
            <div >
                <h5>Select a wallet to associate.</h5>
                <h5>You will be required to sign a message to associate it to your account.</h5>
            </div>
            <WalletConnectPanel onWalletConnected={this.onWalletConnected} />
            <div className="modal-footer">
                <Button text={"Cancel"} className='t18-btn lm black' onClick={this.state.closeModal} large={true}
                        style={{fontSize: "20px"}}/>
            </div>
        </div> : undefined;
        return (
            <Modal id="wallet-associate-modal"
                   content={content}
                   getOpen={this.getOpen}
                   getClose={this.getClose}
                   afterOpen={this.afterOpen}
                   afterClose={this.afterClose}
                   undismissible={this.state.undismissible}
            />
        )
    }
};
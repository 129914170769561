
import generic from "./generic";
import aws_s3 from "../lib/aws_s3";
import API from "../lib/api";
import config from "../config";

const Media = {

    uploadMedia: (type, file, metadata, path, complete_cb, update_cb = () => {}) => {
        let filename = file.name, format;
        let upload_data = {type: type, filename: filename, metadata: metadata, path: path};
        if(type === 'picture'){
            format = getPictureFormatFromMetadata(metadata);
            upload_data.format = format;
        }
        API.mediaUploadRequest(upload_data, (data) => {
            let media_id = data.id, key = media_id;
            if(type === 'picture'){
                key += '_' + format.replace('r', '');
            }
            let reader = new FileReader();
            reader.onload = function() {
                let arrayBuffer = this.result;
                aws_s3.uploadFile(key, arrayBuffer, update_cb, (err, data) => {
                    if(err){
                        console.log(err);
                        return complete_cb(err);
                    }else{
                        API.mediaUploadCompleteRequest(media_id, () => {
                            Media.refreshMediaUntilProcessingDone(media_id);
                            return complete_cb(null, media_id);
                        }, (err) => {
                            return complete_cb(err);
                        })
                    }
                });
            }
            reader.readAsArrayBuffer(file);
        }, (err) => {
            return complete_cb(err);
        })
    },

    refreshMediaUntilProcessingDone: (media_id, cb = () => {}) => {
        generic.refreshEntity('media', media_id, (err, media) => {
            if(err){
                return cb(err);
            }
            if(media.processing){
                setTimeout(() => {
                    Media.refreshMediaUntilProcessingDone(media_id, cb);
                }, 10000);
            }else{
                return cb(null, media);
            }
        }, {no_loading: true})
    },

    getMediaDisplayUrl: (media_id) => {
        let media = generic.getEntity('media', media_id);
        if(!media) return;
        let url;
        if(media.type === 'picture'){
            url = media.url;
        }else{
            if(media.preview_url){
                url = media.preview_url;
            }else{
                let url_map = media.url_map;
                if(url_map){
                    for(let format of config.video_formats){
                        if(url_map[format]){
                            url = url_map[format];
                            break;
                        }
                    }
                }
            }
            if(!url && media.url){
                url = media.url;
            }
        }
        return url;
    },

    getFilteredMedias: (filters = {}) => {
        let medias = generic.getEntities('media', true),
            type = filters.type || 'all',
            access = filters.access || 'all',
            tags = filters.tags || [],
            search = filters.search || '',
            visibility = filters.visibility || 'all',
            owner = filters.owner || null;
        return _.filter(medias, (media) => {
            if (!media.owner) {
                return false;
            } else if (type !== 'all' && media.type !== type) {
                return false;
            } else if (visibility !== 'all' && media.visibility !== visibility) {
                return false;
            } else if (owner && media.owner !== owner) {
                return false;
            } else if (access !== 'all' && ((access === 'free' && media.paid_access) || (access === 'paid' && !media.paid_access))) {
                return false;
            } else if (search !== '' && media.name.toLowerCase().indexOf(search.toLowerCase()) === -1) {
                return false;
            } else if(tags.length > 0) {
                let media_tags = media.tags || [], 
                    match = false;
                for(let tag of tags) {
                    if(media_tags.indexOf(tag) !== -1) {
                        match = true;
                        break;
                    }
                }
                if(!match){
                    return false;
                }
            }
            return true;
        });
    },

};

function getPictureFormatFromMetadata(metadata){
    let width = metadata.width || 0,
        height = metadata.height || 0,
        max_size = Math.max(width, height),
        result;
    for(let format of config.picture_formats){
        if(max_size <= config.picture_format_sizes[format]){
            result = format;
            break;
        }
    }
    return result;
}

window.Media = Media;

export default Media;
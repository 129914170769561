import React from "react";

export default class extends React.Component {

    render() {
        let menu_items = this.props.items,
            className = ["mobile-menu", this.props.className || ""].join(" "),
            display = [];
        for(let item of menu_items){
            display.push(<div className="menu-item" onClick={item.onClick}>
                <i className="material-icons" style={{position: "relative", top: "5px"}}>{item.icon}</i>
                <span>{item.name}</span>
                <i className="material-icons" style={{position: "absolute", right: "10px", top: "15px"}} >chevron_right</i>
            </div>)
        }
        return <div className={className}>
            {display}
        </div>
    }

}
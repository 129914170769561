import React from "react";

export default class MediaBadges extends React.Component {

    renderBadge = (badge) => {
        if(badge.text){
            return <span className="media-badge text-badge">{badge.text}</span>
        }
        return <span className="media-badge">
            <i className="material-icons">{badge.icon}</i>
        </span>
    }

    render() {
        let media = this.props.media,
            badges = [];
        if(media.type === 'picture'){
            badges.push({icon: 'image'});
        }else if(media.type === 'video'){
            badges.push({icon: 'videocam'});
        }else{
            badges.push({text: "VR"});
        }
        if(media.paid_access){
            badges.push({icon: 'attach_money'});
        }
        let className = "media-badges";
        if(badges.length === 1 && badges[0].text){
            className += " shift-top";
        }
        return <div className={className}>
            {badges.map(this.renderBadge)}
        </div>
    }

}
import React from "react";
import _ from 'lodash';

import config from "../../../../shared/config";
import generic from '../../../../shared/model/generic';

import IconButton from "../../../../shared/components/global/IconButton";
import Button from "../../../../shared/components/global/Button";

import UserReturnTitle from "../../../../shared/components/common/UserReturnTitle";

import ProfileMiniDisplay from "../../../../shared/components/profile/ProfileMiniDisplay";
import ProfilePicture from "../../../../shared/components/profile/ProfilePicture";
import GridContentBrowser from "../../../../shared/components/common/GridContentBrowser";

import ExploreMediaView from "../ExploreMediaView/ExploreMediaView";
import MobileMediaHeader from "../../../../shared/components/media/SelectedMediaView/MobileMediaHeader";

export default class extends React.Component {

    state = { selected_media_id: null };

    lastGridY = 0;

    onMediaScroll = (deltaY, lastY) => {
        if (deltaY > 0) {
            let creatorPage = document.querySelector('.creator-page');
            creatorPage.scrollBy(0, deltaY);
        }
        this.lastGridY = lastY;
    }

    onMediaSelect = (media_id, index) => {
        let previous_media_index = this.state.selected_media_index;
        this.setState({ selected_media_id: media_id, selected_media_index: index }, () => {
            if (!media_id) {
                this.scrollPageToBottom();
                let grid = window.grid_display['creator-explore-grid-browser-grid'];
                grid.scrollToItemFromIndex(previous_media_index, config.MOBILE_DISPLAY ? -120 : 0);
            }
        });
    }

    scrollPageToBottom = () => {
        let creatorPage = document.querySelector('.creator-page');
        creatorPage.scrollTo(0, creatorPage.scrollHeight);
    }

    onMediaHeaderClick = () => {

    }

    render() {
        let mobile_display = config.MOBILE_DISPLAY,
            user = generic.getEntity('user', this.props.userId),
            selected_media_id = this.state.selected_media_id;
        if (mobile_display) {
            return <div className="creator-page">
                {!selected_media_id && <div>
                    {/* <div className="creator-header fixed">
                        <IconButton icon="west" onClick={this.props.onReturn} className="return-btn" />
                        <span className="creator-name">{(user.username) || "Unnamed"}</span>
                    </div> */}
                    <UserReturnTitle userId={user.id} onReturn={this.props.onReturn} fixed={true} />
                    <div className="creator-picture" style={{ marginTop: "80px" }}>
                        <ProfilePicture user_id={user.id} />
                    </div>
                    {user.description && <div className="creator-description">{user.description}</div>}
                </div>}
                <div className="creator-medias">
                    <ExploreMediaView id="creator-explore"
                        show={true} style={{ width: '100%' }}
                        onScrolled={this.onMediaScroll}
                        onMediaSelect={this.onMediaSelect}
                        disableMobileMediaHeader={false}
                        putAbove={true}
                        enabledFilters={[]}
                        // onHeaderClick={this.onMediaHeaderClick}
                        medias={generic.getEntitiesFromProperty('media', 'owner', user.id)} />
                </div>
            </div>
        } else {
            return <div className="creator-page" style={{height: 'calc(100% - 86px)'}}>
                <ExploreMediaView id="creator-explore"
                    show={true} style={{ width: '100%', height: '100%' }}
                    topFilterContent={
                        <div>
                            <UserReturnTitle userId={user.id} onReturn={this.props.onReturn} />
                            <div style={{ padding: '30px' }} >
                                <div className="center-align">
                                    <ProfilePicture user_id={user.id} />
                                </div>
                                {user.description && <div className="creator-description">{user.description}</div>}
                            </div>
                        </div>}
                    onScrolled={this.onMediaScroll}
                    onMediaSelect={this.onMediaSelect}
                    putAbove={true}
                    enabledFilters={['search', 'type', 'access', 'tags']}
                    medias={generic.getEntitiesFromProperty('media', 'owner', user.id)} />
            </div>
        }
    }

}
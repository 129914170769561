import M        from 'materialize-css';
import React    from "react";
import ReactDOM from 'react-dom';

/**
 * Props : --> id : id to give to the component
 *         --> content : modal content
 *         --> footer : modal footer
 *         --> undismissible : modal can't be dismissed by clicking outside it
 *         --> getOpen : when modal is mounted, it will send a function to open it to getOpen()
 *         --> getClose : when modal is mounted, it will send a function to close it to getClose()
 *         --> style
 *         --> className
 *         --> afterOpen : callback function called after opening
 *         --> afterClose : callback after closing
 */
export default class Modal extends React.Component {
    state = { id: (this.props.id ? this.props.id : window.generateID()) };
    instance;

    componentDidMount() {
        const nodeRef = ReactDOM.findDOMNode(this),
              options = {};
        if(this.props.undismissible)
            options.dismissible = false;
        if(this.props.afterOpen)
            options.onOpenEnd = this.props.afterOpen;
        if(this.props.afterClose)
            options.onCloseEnd = this.props.afterClose;
        this.instance = M.Modal.init(nodeRef, options);

        // legacy behavior to drop
        if(this.props.getOpen) {
            this.props.getOpen(this.open)
        }
        if(this.props.getClose) {
            this.props.getClose(this.close)
        }
    }

    open = () => {
        this.instance.open();
    }

    close = () => {
        this.instance.close();
    }

    render() {
        return (
            <div id={this.state.id} className={"modal " + (this.props.className ? this.props.className : "")}
                 style={this.props.style ? this.props.style : {}}>
                <div className="modal-content">
                    {this.props.content || this.props.children}
                </div>
                {this.props.footer &&
                    <div className={"modal-footer" + (this.props.fixedFooter ? " fixed-modal-footer" : "")}>{this.props.footer}</div>}
            </div>
        )
    }
}

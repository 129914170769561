import React from "react";
import M from "materialize-css";

import config from "../../config";
import utils from "../../lib/utils";

import MediaCard from './MediaCard';
import FolderCard from './FolderCard';
import IconButton from "../global/IconButton";
import ToolTipped from "../global/ToolTipped";
import TagSelectPanel from "./TagSelectPanel";
import _ from "lodash";

import User from "../../model/user";

const type_filters = [
    { key: 'all', display: 'All', tooltip: 'Show all types of media' },
    { key: 'picture', display: 'Picture', tooltip: 'Show only pictures' },
    { key: 'video', display: 'Video', tooltip: 'Show only videos' },
    { key: 'video_360', display: 'VR', tooltip: 'Show only VR videos' }
];

export default class MediaSelector extends React.Component {

    state = { path: '/', type_filter: null, tag_filters: [] }

    onTypeFilterClick = (type) => {
        this.setState({ type_filter: type })
    }

    onTagClick = (tag) => {
        let selected = this.state.tag_filters.slice(), index = selected.indexOf(tag);
        if (index > -1) {
            selected.splice(index, 1);
        } else {
            selected.push(tag);
        }
        this.setState({ tag_filters: selected })
    }

    onFolderSelect = (path) => {
        this.setState({ path: path })
    }

    onFolderReturn = () => {
        let path = this.state.path;
        if (path !== '/') {
            path = path.substring(0, path.lastIndexOf('/'));
            if (path === '') path = '/';
            this.setState({ path: path });
        }
    }

    getActions = () => {
        if (this.props.actions) {
            return this.props.actions;
        } else if (this.state.path !== '/') {
            return [
                { icon: 'keyboard_return', tooltip: 'Return', onClick: this.onFolderReturn }
            ]
        } else {
            return [];
        }
    }

    renderActions = () => {
        let floating_actions = this.props.floatingActions,
            actions = this.getActions(), 
            display = [], 
            icon_style = floating_actions ? {display: 'block'} : { margin: 'auto' };
        if (actions.length === 0) {
            return undefined;
        }
        for (let action of actions) {
            let className = "media-selector-action-btn utils-btn theme-color large";
            if(floating_actions){
                className += " z-depth-2";
            }
            display.push(<IconButton icon={action.icon} onClick={action.onClick} style={icon_style}
                tooltip={!floating_actions && action.tooltip} className={className} />)
        }
        if(floating_actions){
            let style = {bottom: "10px"};
            if(this.props.floatingActionsStyle){
                Object.assign(style, this.props.floatingActionsStyle);
            }
            return <div className="mobile-media-selector-action-toolbox" style={style}>
                {display}
            </div>
        }else{
            let style = { position: "relative", height: '310px' };
            if(this.props.miniFolderFormat){
                style.height = '86px';
            }
            return <div className={"col " + (this.props.largeWrapper ? "xl2 l3 m6 s12" : "l3 m6 s12")} style={{ padding: "10px" }}>
                <div className="t18-card z-depth-1" style={style}>
                    <div className={window.innerWidth < 1300 && false ? "" : "valign-wrapper"}
                        style={window.innerWidth < 1300 && false ? { height: '100%', paddingTop: '40px' } : { height: '100%' }}>
                        {display}
                    </div>
                </div>
            </div>;
        }
    }

    renderFilters = () => {
        let mobile_display = config.MOBILE_DISPLAY,
            type_filter = this.state.type_filter;
        let style = {
            width: mobile_display ? "100%" : '25%',
            borderRight: mobile_display ? undefined : '1px solid white',
            padding: '10px',
            verticalAlign: 'top'
        }
        if(mobile_display && false){
            return <div className="inline-block" style={style}>
            <div>
                <div className={'z-depth-1 clickable tag-badge' + (!type_filter ? " selected" : "")} style={{ width: "calc(50% - 10px)" }}
                    onClick={this.onTypeFilterClick.bind(null, null)}>All
                </div>
                <div
                    className={'z-depth-1 clickable tag-badge' + (type_filter === 'picture' ? " selected" : "")} style={{ width: "calc(50% - 10px)" }}
                    onClick={this.onTypeFilterClick.bind(null, 'picture')}>Picture
                </div>
            </div>
            <div>
                <div
                    className={'z-depth-1 clickable tag-badge' + (type_filter === 'video' ? " selected" : "")} style={{ width: "calc(50% - 10px)" }}
                    onClick={this.onTypeFilterClick.bind(null, 'video')}>Video
                </div>
                <div
                    className={'z-depth-1 clickable tag-badge' + (type_filter === 'video_360' ? " selected" : "")} style={{ width: "calc(50% - 10px)" }}
                    onClick={this.onTypeFilterClick.bind(null, 'video_360')}>VR
                </div>
            </div>
        </div>
        }
        return <div className="inline-block" style={style}>
            <div>
                <ToolTipped className={'z-depth-1 clickable tag-badge' + (!type_filter ? " selected" : "")} style={{ width: "calc(50% - 10px)" }}
                    content={<div onClick={this.onTypeFilterClick.bind(null, null)}>All
                </div>} tooltip="Show all types of media" />
                <ToolTipped className={'z-depth-1 clickable tag-badge' + (type_filter === 'picture' ? " selected" : "")} style={{ width: "calc(50% - 10px)" }}
                    content={<div onClick={this.onTypeFilterClick.bind(null, 'picture')}>Picture
                </div>} tooltip="Show only pictures" />
            </div>
            <div>
                <ToolTipped className={'z-depth-1 clickable tag-badge' + (type_filter === 'video' ? " selected" : "")} style={{ width: "calc(50% - 10px)" }}
                    content={<div onClick={this.onTypeFilterClick.bind(null, 'video')}>Video
                </div>} tooltip="Show only videos" />
                <ToolTipped className={'z-depth-1 clickable tag-badge' + (type_filter === 'video_360' ? " selected" : "")} style={{ width: "calc(50% - 10px)" }}
                    content={<div onClick={this.onTypeFilterClick.bind(null, 'video_360')}>VR
                </div>} tooltip="Show only VR videos" />
            </div>
        </div>
    }

    renderTagSelect = () => {
        let tag_filters = this.state.tag_filters;
        return <TagSelectPanel selected={tag_filters} tagSelectColor={this.props.tagSelectColor} onTagClick={this.onTagClick} />
    }

    render() {
        let path = this.props.currentPath || this.state.path,
            selected = this.props.selected,
            medias = _.filter(this.props.medias, (media) => { return media.path === path }),
            folders = User.getFoldersFromPath(path),
            display = [];
        let actions = this.renderActions();
        if (actions) {
            display.push(actions);
        }
        for (let folder of folders) {
            display.push(<FolderCard name={folder.name}
                path={folder.path}
                largeWrapper={this.props.largeWrapper}
                medias={this.props.medias}
                height={!this.props.media_actions ? '280px' : undefined}
                onMediaDrop={this.props.onMediaDrop}
                hideEmptyFolders={this.props.hideEmptyFolders}
                miniFormat={this.props.miniFolderFormat}
                onClick={this.props.onFolderSelect ? this.props.onFolderSelect.bind(null, folder.path) : this.onFolderSelect.bind(null, folder.path)}
            />)
        }
        let type_filter = this.state.type_filter, tag_filters = this.state.tag_filters;
        if (!this.props.onlyFolders) {
            let filtered = [];
            for (let media of medias) {
                let filter_out = false;
                if (type_filter && media.type !== type_filter) {
                    filter_out = true;
                } else if (tag_filters.length > 0) {
                    let match = false;
                    if (media.tags && media.tags.length > 0) {
                        for (let tag of tag_filters) {
                            if (media.tags.indexOf(tag) > -1) {
                                match = true;
                                break;
                            }
                        }
                    }
                    filter_out = !match;
                }
                if (!filter_out) {
                    filtered.push(media);
                }
            }
            utils.sortByCreationDate(filtered);
            for (let media of filtered) {
                let is_selected = false;
                if (selected != null) {
                    if (selected.constructor === Array && selected.indexOf(media.id) > -1) {
                        is_selected = true;
                    } else if (typeof selected === 'string' && selected === media.id) {
                        is_selected = true;
                    }
                }
                display.push(<MediaCard media_id={media.id} media_actions={this.props.media_actions} largeWrapper={this.props.largeWrapper}
                    selectCheckBox={this.props.selectCheckBox} onSelectCheckBoxClick={this.props.onSelectCheckBoxClick}
                    hideActionsIfNotSelected={this.props.hideActionsIfNotSelected}
                    onClick={this.props.onSelect} isSelected={is_selected} />)
            }
        }
        return <div>
            {!this.props.onlyFolders && !this.props.hideFilters && <div className="reglisse" style={{ position: 'relative' }}>
                {this.renderFilters()}
                {this.renderTagSelect()}
            </div>}
            <div style={{ color: this.props.textColor || 'black' }}>
                <h4>{"Current folder : " + User.getFolderHierarchyDisplay(path)}</h4>
            </div>
            <div className="row" style={{ marginTop: '0' }}>
                {display}
            </div>
        </div>
    }

}

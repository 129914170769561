import React from "react";
import _ from "lodash";

export default class extends React.Component {

    renderMenu = (menu) => {
        let selected = this.props.selectedMenu === menu.key;
        return <span key={menu.key}>
                <i className={"navbar-icon-button material-icons white-text clickable" + (selected ? " selected" : "")} 
                onClick={menu.onClick}>{menu.icon}</i>
            </span>
    }

    render() {
        return <div className={"mobile-navbar-wrapper" + (this.props.show ? "" : " hide")}>
            <div className="mobile-navbar" >
                {_.map(this.props.menus || [], (menu) => {
                    return this.renderMenu(menu);
                })}
            </div>
        </div>
    }

}
